import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SecurityQuestionsResponse } from '@app/models/SecurityQuestionsResponse'
import { SecurityAnswersPayload } from '@app/models/SecurityAnswersPayload'
import { SecurityAnswersResponse } from '@app/models/SecurityAnswersResponse'

@Injectable()
export class SecurityQNAService {
  constructor(private httpClient: HttpClient) {}

  getSecurityQuestions(url: string): Observable<SecurityQuestionsResponse> {
    return this.httpClient.get<SecurityQuestionsResponse>(url, {
      withCredentials: true,
    })
  }

  setSecurityAnswers(
    url: string,
    body: SecurityAnswersPayload,
  ): Observable<SecurityAnswersResponse> {
    return this.httpClient.post<SecurityAnswersResponse>(url, body)
  }
}
