<!-- <div class="scroll-bar">
    <h2 id="oobCodeHeader" class="dialog-title">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.headerText}}</h2>
</div> -->

<div class="text-align-left">
    <div class="vui-dialog-body-content tips-dialiog-box" tabindex="0">
        <div>
            <p id="oobFistAnswer"class="dialog-body  margin-bottom-16 c11n-label-font-regular">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.firstAnswer}}</p><br>
        </div>
        <div>
            <h3 class="dialog-body-bold margin-bottom-4 c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.secondQuestion}}</h3>  
            <p class="dialog-body  margin-bottom-16 c11n-label-font-regular">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.secondAnswer}}</p><br>
        </div>
        <div>
            <h3 class="dialog-body-bold margin-bottom-4 c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.thirdQuestion}}</h3>  
            <p class="dialog-body margin-bottom-16 c11n-label-font-regular">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.thirdAnswer}}</p><br>
        </div>
        <div>
            <h3 class="dialog-body-bold margin-bottom-4 c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.forthQueston}}</h3>  
            <p class="dialog-body  margin-bottom-16 c11n-label-font-regular">
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.forthAnswerSec.forthAnswerSec.forthAnswer1}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.forthAnswerSec.forthAnswerSec.forthAnswer2Bold}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.forthAnswerSec.forthAnswerSec.forthAnswer4Bold}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.forthAnswerSec.forthAnswerSec.forthAnswer6Bold}}&nbsp;</span>
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.forthAnswerSec.forthAnswerSec.forthAnswer7}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.forthAnswerSec.forthAnswerSec.forthAnswer8Bold}}&nbsp;</span>
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.forthAnswerSec.forthAnswerSec.forthAnswer9}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.forthAnswerSec.forthAnswerSec.forthAnswer9Bold}}</span>
            </p><br>
        </div>
        <div>
            <h3 class="dialog-body-bold margin-bottom-4 c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.fifthQuestion}}</h3>  
            <p class="dialog-body margin-bottom-16 c11n-label-font-regular">
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.fifthAnswerSec.fifthAnswerSec.fifthAnswer1}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.fifthAnswerSec.fifthAnswerSec.fifthAnswer2Bold}}&nbsp;</span>
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.fifthAnswerSec.fifthAnswerSec.fifthAnswer3}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.fifthAnswerSec.fifthAnswerSec.fifthAnswer4Bold}}&nbsp;</span>
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.fifthAnswerSec.fifthAnswerSec.fifthAnswer5}}</span>
            </p><br>
        </div>
        <div>
            <h3 class="dialog-body-bold margin-bottom-4 c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.sixthQuestion}}</h3>  
            <p class="dialog-body margin-bottom-16 c11n-label-font-regular">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.sixthAnswer}}</p><br>
        </div>
        <div>
            <h3 class="dialog-body-bold margin-bottom-4 c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.seventhQuestion}}</h3>  
            <p class="dialog-body margin-bottom-16 c11n-label-font-regular">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.seventhAnswer}}</p><br>
        </div>
        <div>
            <h3 class="dialog-body-bold margin-bottom-4 c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.eigthQuestion}}</h3>  
            <p class="dialog-body margin-bottom-16 c11n-label-font-regular">
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.eigthAnswerSec.eigthAnswerSec.eigthAnswer1}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.eigthAnswerSec.eigthAnswerSec.eigthAnser2Bold}}</span>
            </p><br>
        </div>
        
        <div>
            <h3 class="dialog-body-bold margin-bottom-4 c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.ninethQuestion}}</h3>  
            <p class="dialog-body margin-bottom-16 c11n-label-font-regular">
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.ninethAnswerSec.ninethAnswerSec.ninethAnswer1}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.ninethAnswerSec.ninethAnswerSec.ninethAnswer2Bold}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.ninethAnswerSec.ninethAnswerSec.ninethAnswer4Bold}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.ninethAnswerSec.ninethAnswerSec.ninethAnswer6Bold}}&nbsp;</span>
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.ninethAnswerSec.ninethAnswerSec.ninethAnswer7}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.ninethAnswerSec.ninethAnswerSec.ninethAnswer8Bold}}&nbsp;</span>
                <span>{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.ninethAnswerSec.ninethAnswerSec.ninethAnswer9}}&nbsp;</span>
                <span class="c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.ninethAnswerSec.ninethAnswerSec.ninethAnswer9Bold}}</span>
            </p><br>
        </div> 
        <div>
            <h3 class="dialog-body-bold margin-bottom-4 c11n-label-font-bold">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.tenthQuestion}}</h3>  
            <p class="dialog-body margin-bottom-8 margin-bottom-responsive c11n-label-font-regular">{{content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.tenthAnswer}}</p><br>
        </div>
    </div>
</div>