import { Injectable } from '@angular/core'
import { environment } from '@env'
import { RegionService } from '@app/shared/services/regionService'

@Injectable()
export class VieFormsRedirectService {
  constructor(private readonly regionService: RegionService) {}

  vieFormUrl(formUrl: string): string {
    const formUrlStrings: string[] = formUrl.split('account-maintenance%2F')
    const formUrlPath: string[] = formUrlStrings[1].split('%2F')

    const formRedirectUrlBase =
      environment[this.regionService.envType][this.regionService.userType]
        .VIE_BASE_URL + environment.VIE_FORM_BASE_PATH
    const formRedirectUrlTarget = '/' + formUrlPath.join('/')

    return formRedirectUrlBase + formRedirectUrlTarget
  }
}
