<spinner [active]="loading"></spinner>
<div class="view-container">
        <div [hidden]="stepContainerView">
            <div [hidden]="!stepOneView" class="padding-top-8">
                    <form [formGroup]="registrationForm" class="registration-form-step-one" id="registration" name="registration">
                          <table class="margin-left-0">
                              <tr class=" row display-flex col-lg-12 col-xs-6 margin-bottom-40">
                                  <th class=" text-align-left">

                                      <div  for="tempUsername"  id="tempUsernameLabel" class="bold-17-700-24 margin-right-15 width-175">                                {{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.tempUsernameLabel}}
                                      </div>
                                  </th>
                                  <td class="text-align-left">
                                      <div id="tempUsername" class="normal-17-400-24 width-250">{{userName}}</div>
                                  </td>
                              </tr>

                              <tr>
                                <td class="pw-input-wrapper">
                                    <label
                                        c11n-label [for]="'tempPassword'"
                                        id="tempPasswordLabel"
                                        class="registration-c11n-label">
                                        {{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.tempPasswordLabel}}
                                    </label>
                                    <c11n-input [hasError]="registrationForm?.get('tempPassword')?.touched && registrationForm.get('tempPassword')?.errors">
                                        <input
                                            c11nInput
                                            [attr.type]="inputTypePw"
                                            id="tempPasswordInput"
                                            name="tempPassword"
                                            aria-describedby="tempPasswordError"
                                            formControlName="tempPassword"
                                            placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.tempPasswordLabel}}
                                            tabindex=0
                                            (blur)="allFieldValidator()"
                                            (input)="allFieldValidator()"
                                            (keyup.enter)="register()"
                                        />
                                    </c11n-input>
                                    <div class="c11n-text-sm pw-show-hide">
                                        <button
                                            c11n-link
                                            class="showHideBtn showHideBtnHoverAnimation"
                                            size="small"
                                            type="button"
                                            aria-controls="tempPasswordInputBox"
                                            (click)="handleShowHidePwBtn()"
                                        >
                                            {{ hidePw ? 'Show' : 'Hide' }}
                                        </button>
                                    </div>
                                    <div class="padding-bottom-16">
                                        <c11n-hint-error
                                            [hasError]="(registrationForm?.get('tempPassword')?.touched && (registrationForm?.get('tempPassword')?.hasError('required')
                                                                || registrationForm?.get('tempPassword')?.hasError('minlength')
                                                                || registrationForm?.get('tempPassword')?.hasError('maxlength')
                                                                || registrationForm?.get('tempPassword')?.hasError('invalidTwoAlphaTwoNum')))"
                                            [errorText]="tempPasswordErrorMsg"
                                            [hintErrorId]="'tempPasswordError'">
                                        </c11n-hint-error>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="pw-input-wrapper">
                                    <label
                                        c11n-label [for]="'newUsername'"
                                        id="newUsernameLabel"
                                        class="registration-c11n-label">
                                        {{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.newUsernameLabel}}
                                    </label>
                                    <c11n-input [hasError]="registrationForm?.get('newUsername')?.touched && registrationForm.get('newUsername')?.errors">
                                        <input
                                            c11nInput
                                            id="newUsernameInput"
                                            name="newUsername"
                                            aria-describedby="newUsernameError"
                                            formControlName="newUsername"
                                            placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.newUsernameLabel}}
                                            (blur)="allFieldValidator()"
                                            (input)="allFieldValidator()"
                                            (keyup.enter)="register()"
                                        />
                                    </c11n-input>
                                    <c11n-hint-error class="margin-top-8 padding-bottom-16"
                                        [hasError]="(registrationForm?.get('newUsername')?.touched && (registrationForm?.get('newUsername')?.hasError('required')
                                                                || registrationForm?.get('newUsername')?.hasError('pattern')
                                                                || registrationForm?.get('newUsername')?.hasError('minlength')
                                                                || registrationForm?.get('newUsername')?.hasError('maxlength')
                                                                || registrationForm?.get('newUsername')?.hasError('sameUsername')
                                                                || registrationForm?.get('newUsername')?.hasError('identicalControl')))"
                                        [errorText]="newUsernameErrorMsg"
                                        [hintErrorId]="'newUsernameError'">
                                    </c11n-hint-error>
                                </td>
                                <td class="normal-17-400-24 vertical-alignment" id="newUsernameText">
                                    {{newUsernameInstruction}}
                                </td>
                            </tr>

                            <tr>
                                <td class="pw-input-wrapper">
                                    <label
                                        c11n-label [for]="'newPassword'"
                                        id="newPasswordLabel"
                                        class="registration-c11n-label">
                                        {{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.newPasswordLabel}}
                                    </label>
                                    <c11n-input [hasError]="registrationForm?.get('newPassword')?.touched && registrationForm.get('newPassword')?.errors">
                                        <input
                                        c11nInput
                                        [attr.type]="inputTypeNpw"
                                        id="newPasswordInput"
                                        name="newPassword"
                                        aria-describedby="newPasswordError"
                                        formControlName="newPassword"
                                        placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.newPasswordLabel}}
                                        (blur)="allFieldValidator()"
                                        (input)="allFieldValidator()"
                                        (keyup.enter)="register()"
                                        />
                                    </c11n-input>
                                    <div class="c11n-text-sm pw-show-hide">
                                        <button
                                            c11n-link
                                            class="showHideBtn showHideBtnHoverAnimation"
                                            size="small"
                                            type="button"
                                            aria-controls="newPasswordInputBox"
                                            (click)="handleShowHideNpwBtn()"
                                        >
                                            {{ hideNpw ? 'Show' : 'Hide' }}
                                        </button>
                                    </div>
                                    <c11n-hint-error class="margin-top-8 padding-bottom-16"
                                        [hasError]="(registrationForm?.get('newPassword')?.touched && (registrationForm?.get('newPassword')?.hasError('required')
                                                                || registrationForm?.get('newPassword')?.hasError('minlength')
                                                                || registrationForm?.get('newPassword')?.hasError('maxlength')
                                                                || registrationForm?.get('newPassword')?.hasError('invalidTwoAlphaTwoNum')
                                                                || registrationForm?.get('newPassword')?.hasError('usernamePwdMatch')
                                                                || registrationForm?.get('newPassword')?.hasError('sameUsername')
                                                                || registrationForm?.get('newPassword')?.hasError('identicalToUsernameOrPasswordStr')
                                                                || registrationForm?.get('newPassword')?.hasError('identicalControl')))"
                                        [errorText]="newPasswordErrorMsg"
                                        [hintErrorId]="'newPasswordError'">
                                    </c11n-hint-error>
                                </td>
                                <td class="normal-17-400-24 vertical-alignment padding-bottom-16" id="newPasswordText">
                                    {{newPasswordInstruction}}

                                </td>
                            </tr>

                            <tr>
                                <td class="pw-input-wrapper">
                                    <label
                                        c11n-label [for]="'reenterNewPassword'"
                                        id="reenterNewPasswordLabel"
                                        class="registration-c11n-label">
                                        {{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.reenterNewPasswordLabel}}
                                    </label>
                                    <c11n-input [hasError]="registrationForm?.get('reenterNewPassword')?.touched && registrationForm.get('reenterNewPassword')?.errors">
                                        <input
                                        c11nInput
                                        [attr.type]="inputTypeRnpw"
                                        id="reenterNewPasswordInput"
                                        name="reenterNewPassword"
                                        aria-describedby="reenterNewPasswordError"
                                        formControlName="reenterNewPassword"
                                        placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.reenterNewPasswordLabel}}
                                        (blur)="allFieldValidator()"
                                        (input)="allFieldValidator()"
                                        (keyup.enter)="register()"
                                        />
                                    </c11n-input>
                                    <div class="c11n-text-sm pw-show-hide">
                                        <button
                                            c11n-link
                                            class="showHideBtn showHideBtnHoverAnimation"
                                            size="small"
                                            type="button"
                                            aria-controls="reenterNewPasswordInputBox"
                                            (click)="handleShowHideRnpwBtn()"
                                        >
                                            {{ hideRnpw ? 'Show' : 'Hide' }}
                                        </button>
                                    </div>
                                    <c11n-hint-error class="margin-top-8 padding-bottom-16"
                                        [hasError]="(registrationForm?.get('reenterNewPassword')?.touched && (registrationForm?.get('reenterNewPassword')?.hasError('required')
                                                                || registrationForm?.get('reenterNewPassword')?.hasError('minlength')
                                                                || registrationForm?.get('reenterNewPassword')?.hasError('maxlength')
                                                                || registrationForm?.get('reenterNewPassword')?.hasError('notMatch')))"
                                        [errorText]="reenterNewPasswordErrorMsg"
                                        [hintErrorId]="'reenterNewPassword'">
                                    </c11n-hint-error>
                                </td>
                            </tr>
                          </table>
                    </form>
            </div>

            <div [hidden]="!stepTwoView">
              <div class="row margin-top-16 margin-bottom-34">
                <span id="tipsContentInstruction" class="c11n-info-text">
                  {{content.Data.registrationPage.content.tipsContentInstruction}}
                  <a id="tipsContentLink" role="link" target="_blank" (click)="qaTipsDialog.openModalDialog()"
                     (keydown.enter)="qaTipsDialog.openModalDialog()" class="c11n-link-text" tabindex=0>
                     {{content.Data.registrationPage.content.tipsContent.tipsContent.header}}
                  </a>
                </span>
                <c11n-modal-dialog [headingText]="content.Data.registrationPage.content.tipsContent.tipsContent.header"
                  [maxWidth]="660" [primaryButtonLabel]="content.Data.registrationPage.content.tipsContent.tipsContent.okButton"
                  (primaryClick)="qaTipsDialog.closeDialogModal($event)" (closeEvent)="qaTipsDialog.closeDialogModal($event)"
                  #qaTipsDialog>
                  <div c11n-modal-dialog-body>
                    <ng-container *ngTemplateOutlet="tipsContentDialog"></ng-container>
                  </div>
                </c11n-modal-dialog>
              </div>

              <!-- Security Questions -->
                    <form [formGroup]="securityQNAForm" class="inline-block width-100 margin-left-minus-9" id="securityQA" name="securityQA">
                      <!-- 1st dropdown -->
                      <div class="inline-block width-100 margin-bottom-46 container-fluid">
                        <div class="row margin-bottom-28 securityQuestions security-questions-dropdown-width">
                          <c11n-select
                            labelText="{{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secQuestionLabel}} {{1}}"
                            size="medium"
                            hintText=""
                            errorText="content.Data.oobSecurityPage.content.selectionError"
                            (stateChange)="setSelected($event, 1)"
                            aria-describedby="securityQuestionsFormError"
                          >
                            <select c11nSelectInput id="secQuestionDropdown1" formControlName="securityQuestionsForm1" required [(ngModel)]="securityQuestionsForm1">
                              <option *ngFor="let item of securityQuestions1" [value]="item">{{item.questionText}}</option>
                            </select>
                          </c11n-select>
                        </div>
                        <div class="row width-85 margin-left-0 security-answers-div">
                          <c11n-input [hasError]="securityQNAForm?.get('secAnswerInput1')?.touched &&
                          securityQNAForm.get('secAnswerInput1')?.errors">
                            <input c11nInput
                                   inputmode="text"
                                   id="secAnswerInput1"
                                   name="secAnswerInput1"
                                   formControlName="secAnswerInput1"
                                   aria-describedby="secAnswerInputBoxError1"
                                   placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secAnswerLabel}}
                                   type="securityQnA"
                                   (keyup.enter)="register()"
                                   (blur)="allFieldValidatorsecurityQNA()"
                                   (input)="allFieldValidatorsecurityQNA()"
                                   trim
                            />
                          </c11n-input>
                          <c11n-hint-error
                            [hasError]="(securityQNAForm?.get('secAnswerInput1')?.touched && (
                            securityQNAForm.get('secAnswerInput1')?.hasError('required') ||
                            securityQNAForm.get('secAnswerInput1')?.hasError('maxlength') ||
                            securityQNAForm.get('secAnswerInput1')?.hasError('pattern') ||
                            securityQNAForm?.get('secAnswerInput1')?.hasError('sameUsername') ||
                            securityQNAForm?.get('secAnswerInput1')?.hasError('sameAnswer')
                            ))"
                            [errorText]="secAnswer1ErrorMsg"
                            hintErrorId="secAnswerInputBoxError1">
                          </c11n-hint-error>
                        </div>
                        <div class="row width-85 margin-left-0 security-answers-div">
                          <c11n-input [hasError]="securityQNAForm?.get('secReEnterInput1')?.touched && securityQNAForm.get('secReEnterInput1')?.errors">
                            <input c11nInput
                                   inputmode="text"
                                   id="secReEnterInput1"
                                   name="secReEnterInput1"
                                   formControlName="secReEnterInput1"
                                   aria-describedby="secReEnterInputBoxError1"
                                   placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secReEnterLabel}}
                                   type="securityQnA"
                                   (keyup.enter)="register()"
                                   (blur)="allFieldValidatorsecurityQNA()"
                                   (input)="allFieldValidatorsecurityQNA()"
                                   trim
                            />
                          </c11n-input>
                          <c11n-hint-error
                            [hasError]="(securityQNAForm?.get('secReEnterInput1')?.touched && (
                            securityQNAForm.get('secReEnterInput1')?.hasError('required') ||
                            securityQNAForm?.get('secReEnterInput1')?.hasError('notMatch')
                            ))"
                            [errorText]="secReEnter1ErrorMsg"
                            hintErrorId="secReEnterInputBoxError1">
                          </c11n-hint-error>
                        </div>
                      </div>

                      <!-- 2nd dropdown -->
                      <div class="inline-block width-100 margin-bottom-46 container-fluid">
                        <div class="row margin-bottom-28 securityQuestions security-questions-dropdown-width">
                          <c11n-select
                            labelText="{{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secQuestionLabel}} {{2}}"
                            size="medium"
                            hintText=""
                            errorText="content.Data.oobSecurityPage.content.selectionError"
                            (stateChange)="setSelected($event, 2)"
                            aria-describedby="securityQuestionsFormError"
                          >
                            <select c11nSelectInput id="secQuestionDropdown2" formControlName="securityQuestionsForm2"
                                    required [(ngModel)]="securityQuestionsForm2">
                              <option *ngFor="let item of securityQuestions2" [value]="item">{{item.questionText}}</option>
                            </select>
                          </c11n-select>
                        </div>
                        <div class="row width-85 margin-left-0 security-answers-div">
                          <c11n-input [hasError]="securityQNAForm?.get('secAnswerInput2')?.touched &&
                          securityQNAForm.get('secAnswerInput2')?.errors">
                            <input c11nInput
                                   inputmode="text"
                                   id="secAnswerInput2"
                                   name="secAnswerInput2"
                                   formControlName="secAnswerInput2"
                                   aria-describedby="secAnswerInputBoxError2"
                                   placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secAnswerLabel}}
                                   type="securityQnA"
                                   (keyup.enter)="register()"
                                   (blur)="allFieldValidatorsecurityQNA()"
                                   (input)="allFieldValidatorsecurityQNA()"
                                   trim
                            />
                          </c11n-input>
                          <c11n-hint-error
                            [hasError]="(securityQNAForm?.get('secAnswerInput2')?.touched && (
                            securityQNAForm.get('secAnswerInput2')?.hasError('required') ||
                            securityQNAForm.get('secAnswerInput2')?.hasError('maxlength') ||
                            securityQNAForm.get('secAnswerInput2')?.hasError('pattern') ||
                            securityQNAForm?.get('secAnswerInput2')?.hasError('sameUsername') ||
                            securityQNAForm?.get('secAnswerInput2')?.hasError('sameAnswer')
                            ))"
                            [errorText]="secAnswer2ErrorMsg"
                            hintErrorId="secAnswerInputBoxError2">
                          </c11n-hint-error>
                        </div>
                        <div class="row width-85 margin-left-0 security-answers-div">
                          <c11n-input [hasError]="securityQNAForm?.get('secReEnterInput2')?.touched &&
                          securityQNAForm.get('secReEnterInput2')?.errors">
                            <input c11nInput
                                   inputmode="text"
                                   id="secReEnterInput2"
                                   name="secReEnterInput2"
                                   formControlName="secReEnterInput2"
                                   aria-describedby="secReEnterInputBoxError2"
                                   placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secReEnterLabel}}
                                   type="securityQnA"
                                   (keyup.enter)="register()"
                                   (blur)="allFieldValidatorsecurityQNA()"
                                   (input)="allFieldValidatorsecurityQNA()"
                                   trim
                            />
                          </c11n-input>
                          <c11n-hint-error
                            [hasError]="(securityQNAForm?.get('secReEnterInput2')?.touched && (
                            securityQNAForm.get('secReEnterInput2')?.hasError('required') ||
                            securityQNAForm?.get('secReEnterInput2')?.hasError('notMatch')
                            ))"
                            [errorText]="secReEnter2ErrorMsg"
                            hintErrorId="secReEnterInputBoxError2">
                          </c11n-hint-error>
                        </div>
                      </div>

                      <!-- 3rd dropdown -->
                      <div class="inline-block width-100 margin-bottom-46 container-fluid">
                        <div class="row margin-bottom-28 securityQuestions security-questions-dropdown-width">
                          <c11n-select
                            labelText="{{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secQuestionLabel}} {{3}}"
                            size="medium"
                            hintText=""
                            errorText="content.Data.oobSecurityPage.content.selectionError"
                            (stateChange)="setSelected($event, 3)"
                            aria-describedby="securityQuestionsFormError"
                          >
                            <select c11nSelectInput id="secQuestionDropdown3" formControlName="securityQuestionsForm3"
                                    required [(ngModel)]="securityQuestionsForm3">
                              <option *ngFor="let item of securityQuestions3" [value]="item">{{item.questionText}}</option>
                            </select>
                          </c11n-select>
                        </div>
                        <div class="row width-85 margin-left-0 security-answers-div">
                          <c11n-input [hasError]="securityQNAForm?.get('secAnswerInput3')?.touched &&
                          securityQNAForm.get('secAnswerInput3')?.errors">
                            <input c11nInput
                                   inputmode="text"
                                   id="secAnswerInput3"
                                   name="secAnswerInput3"
                                   formControlName="secAnswerInput3"
                                   aria-describedby="secAnswerInputBoxError3"
                                   placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secAnswerLabel}}
                                   type="securityQnA"
                                   (keyup.enter)="register()"
                                   (blur)="allFieldValidatorsecurityQNA()"
                                   (input)="allFieldValidatorsecurityQNA()"
                                   trim
                            />
                          </c11n-input>
                          <c11n-hint-error
                            [hasError]="(securityQNAForm?.get('secAnswerInput3')?.touched && (
                            securityQNAForm.get('secAnswerInput3')?.hasError('required') ||
                            securityQNAForm.get('secAnswerInput3')?.hasError('maxlength') ||
                            securityQNAForm.get('secAnswerInput3')?.hasError('pattern') ||
                            securityQNAForm?.get('secAnswerInput3')?.hasError('sameUsername') ||
                            securityQNAForm?.get('secAnswerInput3')?.hasError('sameAnswer')
                            ))"
                            [errorText]="secAnswer3ErrorMsg"
                            hintErrorId="secAnswerInputBoxError3">
                          </c11n-hint-error>
                        </div>
                        <div class="row width-85 margin-left-0 security-answers-div">
                          <c11n-input [hasError]="securityQNAForm?.get('secReEnterInput3')?.touched &&
                          securityQNAForm.get('secReEnterInput3')?.errors">
                            <input c11nInput
                                   inputmode="text"
                                   id="secReEnterInput3"
                                   name="secReEnterInput3"
                                   formControlName="secReEnterInput3"
                                   aria-describedby="secReEnterInputBoxError3"
                                   placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secReEnterLabel}}
                                   type="securityQnA"
                                   (keyup.enter)="register()"
                                   (blur)="allFieldValidatorsecurityQNA()"
                                   (input)="allFieldValidatorsecurityQNA()"
                                   trim
                            />
                          </c11n-input>
                          <c11n-hint-error
                            [hasError]="(securityQNAForm?.get('secReEnterInput3')?.touched && (
                            securityQNAForm.get('secReEnterInput3')?.hasError('required') ||
                            securityQNAForm?.get('secReEnterInput3')?.hasError('notMatch')
                            ))"
                            [errorText]="secReEnter3ErrorMsg"
                            hintErrorId="secReEnterInputBoxError3">
                          </c11n-hint-error>
                        </div>
                      </div>
                    </form>
            </div>
            <div [class]="!stepTwoView&&stepOneView? ' d-flex margin-top-48': 'd-flex margin-top-24'" class="margin-bottom-80">

                <div>
                    <c11n-button
                        id="cancelBtn"
                        buttonType="button"
                        labelText={{content.Data.registrationPage.content.cancelBtn}}
                        variant="secondary"
                        (clickEvent)="cancelModalDialog.openModalDialog($event)" (keydown.enter)="cancelModalDialog.openModalDialog($event)"
                        >
                    </c11n-button>

                    <div id="cancelDialogBoxIdQnA">

                        <c11n-modal-dialog
                            modalid="cancelModalDialog" #cancelModalDialog
                            [headingText]="content.Data.cancelLayer.content.cancelLayerQuestion"
                            [primaryButtonLabel]="content.Data.cancelLayer.content.confirmCanceNo"
                            [secondaryButtonLabel]="content.Data.cancelLayer.content.confirmCanceYes"
                            variant="warning"
                            (primaryClick)="cancelModalDialog.closeDialogModal($event)"
                            (secondaryClick)="clear()"
                            [maxWidth]="660"
                        >
                            <div c11n-modal-dialog-body>
                                <ng-container *ngTemplateOutlet="cancelDialog"></ng-container>
                            </div>
                        </c11n-modal-dialog>

                    </div>
                    <ng-template #cancelDialog>
                        <p class="c11n-cancel-subtext">{{content.Data.cancelLayer.content.cancelLayerSubtext}}</p>
                    </ng-template>
                </div>

                <c11n-button class="button-padding"
                    id="continueBtn"
                    name="continueBtn"
                    buttonType="submit"
                    labelText={{content.Data.registrationPage.content.continueBtn}}
                    variant="primary"
                    (clickEvent)="register()"
            ></c11n-button>
            </div>
        </div>
        <div [hidden]="!tcView">
            <div class="row">
                <div class="col-12 margin-top-4">
                    <div id="informationalContent" class="c11n-info-text text-align-left">
                        {{content.Data.registrationPage.content.informationalText}}</div>

                </div>
            </div>
            <div class="row">
                <div id="instructionalContent" class="col-12 padding-top-40">
                    <div class="text-align-left margin-bottom-20 margin-top-24">
                        <span class="c11n-instructionalContent-header-text">
                            {{content.Data.registrationPage.content.instructionalContent.instructionalContent.headerText}}</span>
                    </div>
                </div>
            </div>

            <ol role="list" class="c11n-list c11n-list--circles c11n-list--red">
                <li>{{content.Data.registrationPage.content.instructionalContent.instructionalContent.firstStepText}}</li>
                <li>{{content.Data.registrationPage.content.instructionalContent.instructionalContent.secondStepText}}</li>
                <li>{{content.Data.registrationPage.content.instructionalContent.instructionalContent.thirdStepText}}</li>
                <li>{{content.Data.registrationPage.content.instructionalContent.instructionalContent.fourthStepText}}</li>
            </ol>

            <div class="margin-bottom-38">
                    <!-- BEGIN Terms and Condition -->
                    <div class="margin-top-35 margin-bottom-24 padding-right-16">
                      <div class="inline-block">

                      <c11n-checkbox class="c11n-checkbox-label" [isIndeterminate]="false" [labelText]="content.Data.registrationPage.content.acceptTermsAndCondition.acceptTermsAndCondition.termsConditionText" [isDisabled]="false"
                         [variant]="'default'" [labelWeight]="'normal'">
                        <input c11nCheckboxInput
                          class="c11n-checkbox__input"
                          aria-label="termsCheckbox"
                          aria-describedby="termsCheckBoxError"
                          id="termsCheckbox"
                          type="checkbox"
                          name="termsCheckbox"
                          [(ngModel)]="checkedTC"/>
                      </c11n-checkbox>
                      </div>
                        <div class="inline-block">
                            <tc-view></tc-view>
                        </div>
                        <c11n-hint-error class="margin-left-22 margin-top-4"
                            [hasError]="reviewTC && !checkedTC === true"
                            [errorText]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.termsCondReviewTitle"
                            [hintErrorId]="'termsCheckBoxError'">
                        </c11n-hint-error>
                    </div>
                    <div class="margin-bottom-96 padding-top-36">
                        <c11n-button
                        id="cancelButton"
                        buttonType="button"
                        labelText={{content.Data.registrationPage.content.cancelBtn}}
                        variant="secondary"
                        (clickEvent)="onCancel()"
                        ></c11n-button>
                        <c11n-button class="button-padding"
                        id="getStartedBtn"
                        name="continueBtn"
                        buttonType="submit"
                        labelText={{content.Data.registrationPage.content.continueBtn}}
                        variant="primary"
                        (clickEvent)="stepOne()"
                    ></c11n-button>
                </div>
            </div>
        </div>
</div>

<ng-template #tipsContentDialog>
  <qa-tips-dialog></qa-tips-dialog>
</ng-template>
