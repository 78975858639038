import {
  APP_ID,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  NgModule,
  PLATFORM_ID,
} from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule, isPlatformBrowser } from '@angular/common'
import {
  CompositePropagatorModule,
  OTEL_INSTRUMENTATION_PLUGINS,
  OtelColExporterModule,
} from '@jufab/opentelemetry-angular-interceptor'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

import { VuiBannerModule } from '@vanguard/vui/banner'
import { VuiButtonModule } from '@vanguard/vui/button'
import { VuiCheckboxModule } from '@vanguard/vui/checkbox'
import { VuiDialogModule } from '@vanguard/vui/dialog'
import { VuiLoadingIndicatorModule } from '@vanguard/vui/loading-indicator'
import { VuiPipesModule } from '@vanguard/vui/pipes'
import { VuiProgressIndicatorModule } from '@vanguard/vui/progress-indicator'
import { VuiIconModule } from '@vanguard/vui/icon'
import { VuiInputModule } from '@vanguard/vui/input'
import { VuiRadioModule } from '@vanguard/vui/radio'
import { VuiSelectModule } from '@vanguard/vui/select'
import { VuiTooltipModule } from '@vanguard/vui/tooltip'
import { SvgModule } from '@vanguard/vui/svg'

import { VgConstellationModule } from './shared/modules/vg-constellation/vg-constellation.module'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatRadioModule } from '@angular/material/radio'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatTabsModule } from '@angular/material/tabs'
import { MatMenuModule } from '@angular/material/menu'

// Angular Universal requires HttpClientModule to be declared at app level
// https://www.thecodecampus.de/blog/angular-universal-xmlhttprequest-not-defined-httpclient/
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module'

//Import Services
import { HttpXsrfInterceptor } from '@app/interceptors/xsrf-interceptor/xsrf.interceptor'
import { HttpWithCredentialsInterceptor } from '@app/interceptors/httpWithCredentials/http-with-credentials.interceptor'
import { XForwardedProtoInterceptor } from '@app/interceptors/xForwardedProtoInterceptor/x-forwarded-proto.interceptor'
import { CorrelationIdInterceptor } from '@app/interceptors/correlationIdInterceptor/correlation-id.interceptor'
import { ContentService } from '@app/shared/services/contentService'
import { RegionService } from '@app/shared/services/regionService'
import { GatekeeperService } from '@app/shared/services/gatekeeperService'
import { CaaS } from '@app/shared/services/caasService'
import { SessionService } from '@app/shared/services/sessionService'
import { WindowRefService } from '@app/shared/services/window-ref'
import { WindowRefModule } from '@app/shared/services/window-ref/window-ref.module'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { ViewService } from '@app/shared/services/viewService'
import { LoggerService } from './shared/services/loggerService'
import { Logon } from '@app/shared/services/logonService'
import { OobChallengeService } from '@app/shared/services/oobChallengeService'
import { ErrorMsgService } from './shared/services/errorMsgService'
import { ProcessResponseService } from '@app/shared/services/processResponseService'
import { SelfService } from '@app/shared/services/selfService/selfService.service'
import { AdobeAnalyticsService } from '@app/shared/services/adobe-analytics/adobe-analytics.service'
import { SecurityQNAService } from './shared/services/securityQNAService/securityQNA.service'
import { PasswordChangeService } from './shared/services/passwordChangeService'
import { RegisterService } from '@app/shared/services/registerService'
import { ValidatorService } from '@app/shared/services/validatorService/validator.service'
import { TransmitEmailService } from './shared/services/transmitEmailService/transmitEmail.service'
import { VieFormsRedirectService } from './shared/services/vieFormsRedirect/vieFormsRedirect.service'

//Import Components
import { AppComponent } from './app.component'
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component'
import { PhoneFormatterDirective } from '@app/shared/directives/phoneFormatter/phoneFormatter.directive'
import { GlobalHeaderComponent, GlobalFooterComponent } from './globalNav'
import { WarningBannerComponent } from '@app/shared/components/warningBanner/warningBanner.component'
import { HeaderTextComponent } from '@app/headertext/header-text.component'
import { ViewComponent } from '@app/view/view.component'
import { LogonComponent } from '@app/logon/logon.component'
import { LogOffComponent } from '@app/logOff/logOff.component'
import { SelfServiceComponent } from '@app/selfService/selfService.component'
import { QaTipsDialogCustomComponent } from '@app/shared/components/qaTipsDialog/qaTipsDialog.component'
import { CustomInputComponent } from '@app/shared/components/customInput/customInput.component'
import { TrimDirective } from '@app/shared/directives/trim/trim.directive'
import { ChallengeComponent } from './challenge/challenge.component'
import { RegistrationComponent } from './registration/registration.component'
import { SecurityQAComponent } from './securityQA/securityQA.component'
import { ForcedPWComponent } from './forcedPW/forcedPW.component'
import { SecurityCodeComponent } from './securityCode/securityCode.component'
import { CancelLayerComponent } from '@app/cancelLayer/cancel-layer.component'
import { TermsAndConditionsComponent } from './shared/components/termsAndConditions/termsAndConditions.component'
import { OobCodeTipsComponent } from './shared/components/oobCodeTips/oobCodeTips.component'
import { ForcedOobEnrollmentComponent } from './forcedOobEnrollment/forcedOobEnrollment.component'
import { OobTermsComponent } from './shared/components/oobTerms/oobTerms.component'
import { SelfServiceQAComponent } from './selfserviceQA/selfServiceQA.component'
import { ToggleMessageComponent } from './toggle-message/toggle-message.component'
import { DomesticOobEnrollmentComponent } from './domestic-oob-enrollment/domestic-oob-enrollment.component'
import { InternationalOobTermsComponent } from '@app/shared/components/international-oob-terms/international-oob-terms.component'
import { ValidateFormDirective } from './shared/directives/validateFormDirective/validateForm.directive'
import { TransmitTicketHelperService } from './shared/services/transmitCommonService/transmitTicketHelper.service'
import { ModalDialogModule } from '@vg-constellation/angular-15/modal-dialog'

@NgModule({
  declarations: [
    AppComponent,
    CancelLayerComponent,
    ChallengeComponent,
    CustomInputComponent,
    DomesticOobEnrollmentComponent,
    ForcedOobEnrollmentComponent,
    ForcedPWComponent,
    GlobalFooterComponent,
    GlobalHeaderComponent,
    HeaderTextComponent,
    InternationalOobTermsComponent,
    LogOffComponent,
    LogonComponent,
    OobCodeTipsComponent,
    OobTermsComponent,
    PhoneFormatterDirective,
    QaTipsDialogCustomComponent,
    RegistrationComponent,
    SecurityCodeComponent,
    SecurityQAComponent,
    SelfServiceComponent,
    SelfServiceQAComponent,
    SpinnerComponent,
    TermsAndConditionsComponent,
    ToggleMessageComponent,
    TrimDirective,
    ValidateFormDirective,
    ViewComponent,
    WarningBannerComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'institutional-logon-portal-angular',
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    CompositePropagatorModule,
    FormsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    OtelColExporterModule,
    ReactiveFormsModule,
    SvgModule,
    VuiBannerModule,
    VuiButtonModule,
    VuiCheckboxModule,
    VuiDialogModule,
    VuiIconModule,
    VuiInputModule,
    VuiLoadingIndicatorModule,
    VuiPipesModule,
    VuiProgressIndicatorModule,
    VuiRadioModule,
    VuiSelectModule,
    VuiTooltipModule,
    WindowRefModule,
    VgConstellationModule,
    ModalDialogModule.forRoot(),
  ],
  providers: [
    {
      provide: OTEL_INSTRUMENTATION_PLUGINS,
      useValue: [getWebAutoInstrumentations()],
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpWithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XForwardedProtoInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationIdInterceptor,
      multi: true,
    },
    AdobeAnalyticsService,
    CaaS,
    ContentService,
    ErrorMsgService,
    GatekeeperService,
    HeaderTextService,
    LoggerService,
    Logon,
    OobChallengeService,
    PasswordChangeService,
    ProcessResponseService,
    RegionService,
    RegisterService,
    SecurityQNAService,
    SelfService,
    SessionService,
    TransmitEmailService,
    TransmitTicketHelperService,
    ValidatorService,
    ViewService,
    VieFormsRedirectService,
    WarningBannerService,
    WindowRefService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(APP_ID) appId: string,
  ) {
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server'
    console.log(`Running ${platform} with appId=${appId}`)
  }
}
