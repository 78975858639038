import { AfterViewChecked, Component, OnInit } from '@angular/core'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { ContentService } from '@app/shared/services/contentService'
import { AdobeAnalyticsService } from '@app/shared/services/adobe-analytics/adobe-analytics.service'
import { environment } from '@env'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { confirmationValidator } from '@app/shared/validators/confirmationValidator'
import { twoAlphaTwoNumValidator } from '@app/shared/validators/twoAlphaTwoNumValidator'
import { identicalControlValidator } from '@app/shared/validators/identicalControlValidator'
import { usernameOrPasswordStrValidator } from '@app/shared/validators/usernameOrPasswordStrValidator'
import { sameUsernameValidator } from '@app/shared/validators/sameUsernameValidator'
import { RegionService } from '@app/shared/services/regionService'
import { PasswordChangePayload } from '@app/models/PasswordChangePayload'
import { PasswordChangeService } from '@app/shared/services/passwordChangeService'
import { PasswordChangeResponse } from '@app/models/PasswordChangeResponse'
import { SessionService } from '@app/shared/services/sessionService'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { Subscription } from 'rxjs'
import { ProcessResponseService } from '@app/shared/services/processResponseService/processResponse.service'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { VieFormsRedirectService } from '@app/shared/services/vieFormsRedirect/vieFormsRedirect.service'
import { CancelResponse } from '@app/models/CancelResponse'
import { Logon } from '@app/shared/services/logonService'
import { ViewService } from '@app/shared/services/viewService'
import { ValidatorService } from '@app/shared/services/validatorService'
import { LoggerService } from '@app/shared/services/loggerService'

@Component({
  selector: 'forced-pw-view',
  templateUrl: './forcedPW.component.html',
  styleUrls: ['./forcedPW.component.scss'],
})
export class ForcedPWComponent implements OnInit, AfterViewChecked {
  content: any
  forcedPWForm: FormGroup
  disableForm = false
  submitBtnDisabled = false
  passwordChangePayload: PasswordChangePayload
  passwordChangeUrl: string
  newPasswordInstruction: string[]
  newUsernameInstruction: string[]
  username: string
  cancelRestEndPoint: string
  invalidMessage: string
  loading: boolean
  newPasswordErrorMsg = ''
  reenterNewPasswordErrorMsg = ''
  tempPasswordErrorMsg = ''
  formUrl: string
  hideNpw = true
  inputTypeNpw = this.hideNpw ? 'password' : 'text'
  hidePw = true
  inputTypePw = this.hidePw ? 'password' : 'text'
  hideRnpw = true
  inputTypeRnpw = this.hideRnpw ? 'password' : 'text'

  constructor(
    private readonly fb: FormBuilder,
    private readonly headerText: HeaderTextService,
    private readonly warningBanner: WarningBannerService,
    private readonly contentService: ContentService,
    private readonly adobeAnalytics: AdobeAnalyticsService,
    private readonly regionService: RegionService,
    private readonly pwChangeService: PasswordChangeService,
    private readonly session: SessionService,
    private errorMsgService: ErrorMsgService,
    private readonly processResponseService: ProcessResponseService,
    private readonly vieFormsRedirectService: VieFormsRedirectService,
    private readonly logonService: Logon,
    private viewService: ViewService,
    private validatorService: ValidatorService,
    private readonly loggerService: LoggerService,
  ) {}

  ngOnInit() {
    const data = {
      section: environment.LOGON_ADOBE_SECTION,
      subSection: environment.LOGON_ADOBE_SUBSECTION,
      pageId: environment.FORCED_PW_ADOBE_PAGEID,
    }
    this.content = this.contentService.caasContent
    this.headerText.setComponent(
      this.content.Data.registrationPage.content.titleIdForcedPW,
    )
    this.headerText.setHeader(
      this.content.Data.registrationPage.content.forcedPWHeader,
    )
    this.headerText.setShowHeadText(false)
    this.passwordChangeUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.PW_CHANGE_REST_POSTFIX
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.adobeAnalytics.updateData(data)
    this.adobeAnalytics.track()
    this.username = this.session.userName
    this.newPasswordInstruction = [
      this.content.Data.registrationPage.content.registerAccess.registerAccess
        .registrationForm.registrationForm.newPasswordText.newPasswordText
        .characterText +
        ' ' +
        this.content.Data.registrationPage.content.registerAccess.registerAccess
          .registrationForm.registrationForm.newPasswordText.newPasswordText
          .caseText,
    ]
    this.newUsernameInstruction = [
      this.content.Data.registrationPage.content.registerAccess.registerAccess
        .registrationForm.registrationForm.newUserNameText,
    ]

    this.forcedPWForm = this.fb.group({
      tempPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        twoAlphaTwoNumValidator(),
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        identicalControlValidator('tempPassword'),
        sameUsernameValidator(this.username),
        usernameOrPasswordStrValidator(),
        twoAlphaTwoNumValidator(),
      ]),
      reenterNewPassword: new FormControl('', [
        Validators.required,
        confirmationValidator('newPassword'),
      ]),
    })
    window.setTimeout(
      () => document.getElementById('tempPasswordInput').focus(),
      0,
    )

    this.formUrl = window.location.href
  }

  ngAfterViewChecked() {
    /* istanbul ignore else*/
    if (
      document.getElementById('Input') &&
      document.getElementById('Input').getAttribute('aria-invalid') === 'true'
    ) {
      document
        .getElementById('Input')
        .setAttribute('aria-describedby', ' warningBannerError')
    }
  }

  submit() {
    this.clearMsgs()
    this.forcedPWForm.markAllAsTouched()
    this.allFieldValidator()
    if (!this.forcedPWForm.invalid) {
      this.passwordChangePayload = {
        password: this.forcedPWForm.value.tempPassword,
        newPassword: this.forcedPWForm.value.newPassword,
      }
      this.loading = true
      this.pwChangeService
        .changePassword(this.passwordChangeUrl, this.passwordChangePayload)
        .subscribe(
          (data: PasswordChangeResponse) => {
            //VIE forms redirection
            if (
              this.formUrl.includes('account-maintenance%2F') &&
              data.isVieUser
            ) {
              data.redirectUrl = this.vieFormsRedirectService.vieFormUrl(
                this.formUrl,
              )
            }

            if (
              this.processResponseService.isResetQA &&
              data.state !== environment.UNSUCCESSFULL
            ) {
              data.authAction = environment.AA_RESET
              data.state = environment.SUCCESSFULL
            }

            this.processResponseService.proccess(data)
            this.clear(this.errorMsgService.clearTempPasswordField)
            this.clearForm(this.errorMsgService.clearForm)
            this.checkMsgs()
          },
          (error) => {
            this.loggerService.error(
              'Error occured while sending new permanent password from forcedPW component: Status: ' +
                error.status +
                ', error: ' +
                error.message,
            )
            this.forcedPWForm.reset()
            this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
            this.invalidMessage = 'serviceFailureLogon'
            this.loading = false
          },
        )
    } else {
      this.forcedPWForm.markAllAsTouched()
      this.forcedPWForm.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      })
    }
  }

  clearMsgs(): void {
    this.errorMsgService.clearAll()
    this.warningBanner.setWarningBannerMessage('')
    this.checkMsgs()
  }

  checkMsgs(): void {
    this.invalidMessage = this.errorMsgService.invalidMessage
    this.warningBanner.setWarningBannerMessage(
      this.errorMsgService.invalidMessage,
    )
    this.loading = this.errorMsgService.loading
  }

  clear(clear: boolean): void {
    if (clear) {
      this.forcedPWForm.controls['tempPassword'].reset()
      this.errorMsgService.clearTempPasswordField = false
    }
  }

  clearForm(clearForm: boolean): void {
    if (clearForm) {
      this.forcedPWForm.reset()
      this.errorMsgService.clearForm = false
    }
  }
  confirmCancel() {
    this.session.clear()
    this.clearMsgs()
    this.logonService.cancel(this.cancelRestEndPoint).subscribe({
      next: (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
      error: (error) => {
        this.loggerService.error(
          'Error occured while canceling from Forced Password component: Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    })
  }

  allFieldValidator() {
    Object.keys(this.forcedPWForm.controls).forEach((ctrlName) => {
      this.fieldValidator(ctrlName)
    })
  }

  fieldValidator(ctrlName: string) {
    const controller = this.forcedPWForm.get(ctrlName)
    controller.updateValueAndValidity()
    if (controller.errors) {
      if (!controller.touched && !controller.errors['required']) {
        controller.markAsTouched()
      }
    }

    switch (ctrlName.toLowerCase()) {
      case environment.F_PW_NEW_PASSWORD:
        if (
          this.forcedPWForm.controls.newPassword.touched &&
          this.forcedPWForm.controls.newPassword.errors !== null
        ) {
          this.newPasswordErrorMsg = this.validatorService.getValidationMsg(
            controller,
            ctrlName,
            0,
          )
        } else {
          this.newPasswordErrorMsg = ''
        }
        break
      case environment.F_PW_REENTER_PASSWORD:
        if (
          this.forcedPWForm.controls.reenterNewPassword.touched &&
          this.forcedPWForm.controls.reenterNewPassword.errors !== null
        ) {
          this.reenterNewPasswordErrorMsg =
            this.validatorService.getValidationMsg(controller, ctrlName, 0)
        } else {
          this.reenterNewPasswordErrorMsg = ''
        }
        break
      case environment.F_PW_TEMP_PASSWORD:
        if (
          this.forcedPWForm.controls.tempPassword.touched &&
          this.forcedPWForm.controls.tempPassword.errors !== null
        ) {
          this.tempPasswordErrorMsg = this.validatorService.getValidationMsg(
            controller,
            ctrlName,
            0,
          )
        } else {
          this.tempPasswordErrorMsg = ''
        }
        break
      default:
        break
    }
  }
  handleShowHideNpwBtn(): void {
    this.hideNpw = !this.hideNpw
    this.inputTypeNpw = this.hideNpw ? 'password' : 'text'
  }

  handleShowHidePwBtn(): void {
    this.hidePw = !this.hidePw
    this.inputTypePw = this.hidePw ? 'password' : 'text'
  }

  handleShowHideRnpwBtn(): void {
    this.hideRnpw = !this.hideRnpw
    this.inputTypeRnpw = this.hideRnpw ? 'password' : 'text'
  }
}
