<div [ngSwitch]="message">
    <div class="view-container" id="warningBannerError">     
        <div *ngSwitchCase="'resendSuccess'" class="errorMessage invalidAttemptErrorMessageMaxWidth" id="invalidAttempt">
            <c11n-banner
                [headingText]="content.Data.oobSecurityPage.content.successSendCode"
                [variant]="'success'" [size]="'large'" id="sendCodeSuccess" [hasCloseControl]="false" [hasDetails]="false" [isDetailsExpanded]="false">
            </c11n-banner>
        </div>   
        <div *ngSwitchCase="'invalidAttemptLogon'" class="errorMessage invalidAttemptErrorMessageMaxWidth" id="invalidAttempt">
            <c11n-banner
                [headingText]="content.Data.logonPage.content.invalidLogonMsg.invalidLogonMsg.invalidTitle"
                [variant]="'error'" [size]="'large'" id="invalidLogonMsg" [hasCloseControl]="false">
                {{content.Data.logonPage.content.invalidLogonMsg.invalidLogonMsg.invalidContent}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'incorrectSecurityAnswer'" class="errorMessage errorMessageMaxWidth-850" id="securityAnswerErrorMsg">
            <c11n-banner
                [headingText]="content.Data.challengePage.content.invalidMsg.invalidMsg.invalidSubmissionTitle"
                [variant]="'error'" [size]="'large'" [hasCloseControl]="false">
                {{content.Data.challengePage.content.invalidMsg.invalidMsg.invalidSubmissionContent}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'invalidCred'" class="errorMessage errorMessageMaxWidth">
            <c11n-banner
                [headingText]="content.Data.registrationPage.content.invalidMsg.invalidMsg.invalidTitle"
                [variant]="'error'" [size]="'large'" id="invalidCredMsg" [hasCloseControl]="false">
                {{content.Data.registrationPage.content.invalidMsg.invalidMsg.invalidContent}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'duplicateUsername'" class="errorMessage errorMessageMaxWidth">
            <c11n-banner
                [headingText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernameNATitle"
                [variant]="'error'" [size]="'large'" id="duplicateUsernameMsg" [hasCloseControl]="false">
                {{content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernameNADesc}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'locked'" class="errorMessage errorMessageMaxWidth" id="lockedUserMsg">
            <c11n-banner
                [headingText]="content.Data.logonPage.content.lockedMsg.lockedMsg.lockedTitle"
                [variant]="'error'" [size]="'large'" [hasCloseControl]="false">
                {{content.Data.logonPage.content.lockedMsg.lockedMsg.lockedContent}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'maxSessions'" class="errorMessage">
            <c11n-banner
                [headingText]="content.Data.oobSecurityPage.content.errorMsg.errorMsg.errorTitle"
                [variant]="'error'" [size]="'large'" id="maxSessionsMsg" [hasCloseControl]="false">
                {{content.Data.oobSecurityPage.content.errorMsg.errorMsg.maxSessionMsg}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'serviceFailureLogon'" class="errorMessage errorMessageMaxWidth" id="serviceFailureLogon">
            <c11n-banner
                [headingText]="content.Data.logonPage.content.failedMsg.failedMsg.failedTitle"
                [variant]="'error'" [size]="'large'" id="serviceFailureMsg" [hasCloseControl]="false">
                {{content.Data.logonPage.content.failedMsg.failedMsg.failedContent}}
            </c11n-banner>
        </div>
        <!-- PER Review Comments:  REMOVE THIS/CLEAN UP DUPLICATE MESSAGE LATER!!!! -->
        <div *ngSwitchCase="'serviceFailureSelfService'" class="errorMessage errorMessageMaxWidth" id="sendCodeFailure">
            <c11n-banner
                [headingText]="content.Data.selfServicePage.content.resetCredentialsErrorMsg.resetCredentialsErrorMsg.requestFailedMsg.failedMsg.failedTitle"
                [variant]="'error'" [size]="'large'" [hasCloseControl]="false">
                {{content.Data.selfServicePage.content.resetCredentialsErrorMsg.resetCredentialsErrorMsg.requestFailedMsg.failedMsg.failedContent}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'invalidCode'" class="errorMessage errorMessageMaxWidth-750" id="invalidCodeMsg">
            <c11n-banner
                [headingText]="content.Data.oobSecurityPage.content.expiredCodeMsg.expiredCodeMsg.expiredCodeMsgTitle"
                [variant]="'error'" [size]="'large'" [hasCloseControl]="false">
                <div>
                    <p class="margin-bottom-8">{{content.Data.oobSecurityPage.content.expiredCodeMsg.expiredCodeMsg.expiredCodeMsgFirst}}</p>
                    <p class="margin-bottom-8">{{content.Data.oobSecurityPage.content.expiredCodeMsg.expiredCodeMsg.expiredCodeMsgSecond}}</p>
                </div>
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'callIn'" class="errorMessage errorMessageMaxWidth-850" id="callInFailure">
            <c11n-banner
                [headingText]="content.Data.logonPage.content.callInErrorMsg.callInErrorMsg.callInTitle"
                [variant]="'error'" [size]="'large'" [hasCloseControl]="false">
                    {{content.Data.logonPage.content.callInErrorMsg.callInErrorMsg.callInContent}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'mismatch'" class="errorMessage errorMessageMaxWidth-850" id="mismatchFailure">
            <c11n-banner
                [headingText]="content.Data.selfServicePage.content.mismatchMsgTitle"
                [variant]="'error'" [size]="'large'" [hasCloseControl]="false">
                {{content.Data.selfServicePage.content.mismatchMsg}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'answerExpired'" class="errorMessage errorMessageMaxWidth" id="answerExpiredMsg">
            <c11n-banner
                [headingText]="content.Data.logonPage.content.sessionexpired"
                [variant]="'error'" [size]="'large'" [hasCloseControl]="false">
                {{content.Data.logonPage.content.invalidLogonMsg.invalidLogonMsg.invalidContent}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'tempPwdSuccess'" class="errorMessage errorMessageMaxWidth">
            <c11n-banner
                [headingText]="content.Data.logonPage.content.tempPwdSuccessMsg.tempPwdSuccessMsg.tempPwdSuccessTitle"
                [variant]="'success'" [size]="'large'" id="tempPwdSuccessMsg" [hasCloseControl]="false">
                {{content.Data.logonPage.content.tempPwdSuccessMsg.tempPwdSuccessMsg.tempPwdSuccessContent}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'userNameSuccess'" class="errorMessage errorMessageMaxWidth">
            <c11n-banner
                [headingText]="content.Data.logonPage.content.usernameSentMsg.usernameSentMsg.usernameSentTitle"
                [variant]="'success'" [size]="'large'" id="userNameSuccessMsg" [hasCloseControl]="false">
                {{content.Data.logonPage.content.usernameSentMsg.usernameSentMsg.usernameSentContent}}
            </c11n-banner>
        </div>
        <div *ngSwitchCase="'transmitErrors'" class="errorMessage errorMessageMaxWidth">
            <c11n-banner
                [headingText]="content.Data.selfServicePage.content.mismatchMsgTitle"
                [variant]="'error'" [size]="'large'" id="transmitErrorsMsg" [hasCloseControl]="false">
                {{content.Data.registrationPage.content.errorMsg.errorMsg.errormsg3}}
            </c11n-banner>
        </div>
    </div>
</div>