import {
  Directive,
  HostListener,
  Renderer2,
  Input,
  AfterViewInit,
  SimpleChanges,
  OnChanges,
  ElementRef,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ValidatorService } from '@app/shared/services/validatorService/validator.service'

const matFormFieldFlex = ' .mat-form-field-flex'

@Directive({
  selector: '[validateForm]',
})
export class ValidateFormDirective implements AfterViewInit, OnChanges {
  public loopCounter = 0

  constructor(
    private renderer: Renderer2,
    private validatorService: ValidatorService,
    private _el: ElementRef,
  ) {}

  @Input('validateForm') form: FormGroup
  @Input() typeOfInput: string
  @Input() ctrlName: string
  @Input() countryPhoneCode: number

  labelElement: any
  errorElement: any
  inputElement: any
  borderElement: any
  textElement: any

  ngAfterViewInit() {
    if (this.form) {
      this.form.valueChanges.subscribe((changes) => {
        for (const controlName of Object.keys(changes)) {
          if (this.loopCounter > Object.keys(changes).length) {
            this.loopCounter = 0
            break
          }
          this.loopCounter++
          const control = this.form.get(controlName)
          control.patchValue(changes[controlName], { emitEvent: false })

          if (
            controlName.toLowerCase().includes('radio') ||
            controlName.toLowerCase().includes('countrycode')
          ) {
            continue
          }
          /* istanbul ignore else*/
          if (document.getElementById(controlName + 'Input')) {
            this.inputElement = this.renderer.selectRootElement(
              '#' + controlName + 'Input',
              true,
            )
          }
          /* istanbul ignore else*/
          if (document.getElementById(controlName + 'Error')) {
            this.errorElement = this.renderer.selectRootElement(
              '#' + controlName + 'Error',
              true,
            )
          }
          /* istanbul ignore else*/
          if (document.getElementById(controlName + 'Label')) {
            this.labelElement = this.renderer.selectRootElement(
              '#' + controlName + 'Label',
              true,
            )
          }
          /* istanbul ignore else*/
          if (document.getElementById(controlName + 'TextContent')) {
            this.textElement = this.renderer.selectRootElement(
              '#' + controlName + 'TextContent',
              true,
            )
          }
          /* istanbul ignore else*/
          if (document.getElementById(this.ctrlName + 'FormField')) {
            this.borderElement = this.renderer.selectRootElement(
              '#' + this.ctrlName + 'FormField' + matFormFieldFlex,
              true,
            )
          }
          if (this.ctrlName !== 'phoneNumber') {
            if (!control.errors) {
              this.hideError()
            } else if (control.invalid && control.touched) {
              this.checkErrorMsg(
                this.validatorService.getValidationMsg(
                  control,
                  controlName,
                  this.countryPhoneCode,
                ),
              )
            } else if (control.dirty) {
              this.checkErrorMsg(
                this.validatorService.getValidationMsgOnDirty(
                  control,
                  controlName,
                  this.countryPhoneCode,
                ),
              )
            }
          }
        }
      })
    }
  }

  @HostListener('blur') onblur() {
    const control = this.form.get(this.ctrlName)
    this.inputElement = this.renderer.selectRootElement(
      '#' + this.ctrlName + 'Input',
      true,
    )
    this.errorElement = this.renderer.selectRootElement(
      '#' + this.ctrlName + 'Error',
      true,
    )
    this.labelElement = this.renderer.selectRootElement(
      '#' + this.ctrlName + 'Label',
      true,
    )
    if (document.getElementById(this.ctrlName + 'TextContent')) {
      this.textElement = this.renderer.selectRootElement(
        '#' + this.ctrlName + 'TextContent',
        true,
      )
    }
    if (document.getElementById(this.ctrlName + 'FormField')) {
      this.borderElement = this.renderer.selectRootElement(
        '#' + this.ctrlName + 'FormField' + matFormFieldFlex,
        true,
      )
    }
    if (control.invalid) {
      this.checkErrorMsg(
        this.validatorService.getValidationMsg(
          control,
          this.ctrlName,
          this.countryPhoneCode,
        ),
      )
    } else {
      this.hideError()
    }
  }

  @HostListener('click') onclick() {
    if (this._el.nativeElement.id === 'signUpBtn') {
      if (this.form) {
        if (this.form.invalid) {
          Object.keys(this.form.controls).forEach((controlName) => {
            const control = this.form.controls[controlName]
            if (document.getElementById(controlName + 'Input') !== null) {
              this.inputElement = this.renderer.selectRootElement(
                '#' + controlName + 'Input',
                true,
              )
              if (
                this.inputElement.tagName === 'VUI-SELECT' &&
                control.invalid
              ) {
                const firstToValidate = this.inputElement.querySelector(
                  'button.vui-select__button',
                )
                firstToValidate.click()
                this.renderer.selectRootElement(
                  '#countryCodeInput-listbox',
                ).style.visibility = 'hidden'
                firstToValidate.click()
                firstToValidate.focus()
              } else if (this.inputElement.tagName !== 'VUI-SELECT') {
                this.errorElement = this.renderer.selectRootElement(
                  '#' + controlName + 'Error',
                  true,
                )
                this.labelElement = this.renderer.selectRootElement(
                  '#' + controlName + 'Label',
                  true,
                )
                if (document.getElementById(controlName + 'TextContent')) {
                  this.textElement = this.renderer.selectRootElement(
                    '#' + controlName + 'TextContent',
                    true,
                  )
                }
                if (document.getElementById(controlName + 'FormField')) {
                  this.borderElement = this.renderer.selectRootElement(
                    '#' + controlName + 'FormField' + matFormFieldFlex,
                    true,
                  )
                }
                if (control.invalid) {
                  this.checkErrorMsg(
                    this.validatorService.getValidationMsg(
                      control,
                      controlName,
                      this.countryPhoneCode,
                    ),
                  )
                } else {
                  this.hideError()
                }
              }
            }
          })
          this._el.nativeElement.querySelector('button.vui-button').focus()
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.ctrlName === 'phoneNumber') {
      const control = this.form.get(this.ctrlName)
      /* istanbul ignore else*/
      if (document.getElementById(this.ctrlName + 'Input')) {
        this.inputElement = this.renderer.selectRootElement(
          '#' + this.ctrlName + 'Input',
          true,
        )
      }
      this.errorElement = this.renderer.selectRootElement(
        '#' + this.ctrlName + 'Error',
        true,
      )
      /* istanbul ignore else*/
      if (document.getElementById(this.ctrlName + 'Label')) {
        this.labelElement = this.renderer.selectRootElement(
          '#' + this.ctrlName + 'Label',
          true,
        )
      }
      /* istanbul ignore else*/
      if (document.getElementById(this.ctrlName + 'TextContent')) {
        this.textElement = this.renderer.selectRootElement(
          '#' + this.ctrlName + 'TextContent',
          true,
        )
      }
      /* istanbul ignore else*/
      if (document.getElementById(this.ctrlName + 'FormField')) {
        this.borderElement = this.renderer.selectRootElement(
          '#' + this.ctrlName + 'FormField' + matFormFieldFlex,
          true,
        )
      }
      /* istanbul ignore else*/
      if (control.invalid) {
        this.checkErrorMsg(
          this.validatorService.getValidationMsg(
            control,
            this.ctrlName,
            this.countryPhoneCode,
          ),
        )
      } else {
        this.hideError()
      }
    }
  }

  checkErrorMsg(errorMsg: string) {
    if (!errorMsg) {
      this.hideError()
    } else {
      this.displayError(errorMsg)
    }
  }

  displayError(errorMsg: string) {
    /* istanbul ignore else*/
    if (this.inputElement) {
      this.renderer.addClass(this.inputElement, 'error-red-border')
      if (document.getElementById(this.ctrlName + 'FormField')) {
        this.renderer.setStyle(
          this.borderElement,
          'border',
          '2px solid #E00000',
        )
      }
      this.renderer.addClass(this.inputElement, 'margin-bottom-0-important')
      this.renderer.setStyle(this.labelElement, 'color', '#E00000')
      this.renderer.setProperty(
        this.errorElement,
        'innerHTML',
        '<strong>Error: </strong>' + errorMsg,
      )
      this.renderer.setProperty(this.errorElement, 'hidden', false)
    }
  }

  hideError() {
    this.renderer.removeClass(this.inputElement, 'error-red-border')
    this.renderer.setStyle(this.inputElement, 'border', '1px solid black')
    if (document.getElementById(this.ctrlName + 'FormField')) {
      this.renderer.setStyle(this.borderElement, 'border', '2px solid black')
    }
    this.renderer.removeClass(this.inputElement, 'margin-bottom-0-important')
    this.renderer.setStyle(this.labelElement, 'color', '#666')
    this.renderer.setProperty(this.errorElement, 'innerHTML', '')
    this.renderer.setProperty(this.errorElement, 'hidden', true)
  }
}
