<div class="text-align-left">
    <div class="vui-dialog-body-content tips-dialiog-box padding-right-8-imp margin-bottom-10">
        <p class="c11n-label-font-regular margin-bottom-0">{{content.Data.registrationPage.content.tipsContent.tipsContent.subHeader}}</p><br>

        <p class="c11n-label-font-regular margin-bottom-0">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1'].tip}}<br>
            <span class="c11n-label-font-regular">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1'].details}}</span><br><br></p>

        <p class="c11n-label-font-regular margin-bottom-0">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1-1'].tip}}<br>
            <span class="c11n-label-font-regular">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1-1'].details}}</span><br><br></p>

        <p class="c11n-label-font-regular margin-bottom-0">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1-2'].tip}}<br>
            <span class="c11n-label-font-regular">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1-2'].details}}</span><br><br></p>
                
        <p class="c11n-label-font-regular margin-bottom-0">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1-3'].tip}}<br>
            <span class="c11n-label-font-regular">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1-3'].details}}</span><br><br></p>

        <p class="c11n-label-font-regular margin-bottom-0">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1-4'].tip}}<br>
            <span class="c11n-label-font-regular">{{content.Data.registrationPage.content.tipsContent.tipsContent.content.content.tips['tips-1-4'].details}}</span><br><br></p>                
    </div>
</div>
