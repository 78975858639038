<spinner [active]="unloading"></spinner>
<c11n-modal-dialog-entrypoint></c11n-modal-dialog-entrypoint>
<div *ngIf="contentReady && tcContentReady">
  <div id="global-header"><global-header></global-header></div>
  <div [ngSwitch]="underMaintOrAppDown">
    <div *ngSwitchCase="'UnderMaintenance'">
      <toggle-message [messageHeader]="underMaintOrAppDown"></toggle-message>
    </div>
    <div *ngSwitchCase="'AppDown'">
      <toggle-message [messageHeader]="underMaintOrAppDown"></toggle-message>
    </div>
    <div *ngSwitchDefault>
      <warning-banner></warning-banner>
      <div id="header-text"><header-text></header-text></div>
      <div *ngIf="loadView">
        <view-root></view-root>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <div id="global-footer"><global-footer></global-footer></div>
</div>
