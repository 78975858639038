import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core'
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { SessionService } from '@app/shared/services/sessionService'
import { environment } from '@env'
import { AdobeAnalyticsService } from '../shared/services/adobe-analytics/adobe-analytics.service'
import { Logon } from '@app/shared/services/logonService'
import { WindowRefService } from '@app/shared/services/window-ref'
import { ViewService } from '@app/shared/services/viewService'
import { ChallengeResponse } from '../models/ChallengeResponse'
import { RegionService } from '@app/shared/services/regionService'
import { ContentService } from '@app/shared/services/contentService'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { ChallengePayload } from '../models/ChallengePayload'
import { CancelResponse } from '../models/CancelResponse'
import { ProcessResponseService } from '@app/shared/services/processResponseService/processResponse.service'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { TransmitQAService } from '@app/shared/services/transmitCommonService/transmitQA.service'
import { TransmitLogonQAUiHandler } from '@app/shared/services/transmitLogonQA/transmitLogonQAUiHandler'
import { TransmitEmailService } from '@app/shared/services/transmitEmailService/transmitEmail.service'
import {
  Transmit,
  TransmitAuthenticateResult,
  TransmitValidateUserStatus,
} from '@app/models/TransmitConstants'
import { ServiceResponse } from '@app/models/ServiceResponse'
import { EmailTemplate } from '@app/models/EmailTemplate'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { LoggerService } from '@app/shared/services/loggerService'

const sdk = XmSdk()

@Component({
  selector: 'challenge-view',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.scss'],
})
export class ChallengeComponent implements OnInit, AfterViewChecked {
  @ViewChild('focus', { static: true }) focus: ElementRef
  cancelResponse: CancelResponse
  content: any
  userName: string
  question: string
  challengePayload: ChallengePayload
  qnaUrl: string
  cancelRestEndPoint: string
  incorrectSecurityAnswer = false
  continueBtnDisabled = false
  disableForm = false
  invalidMessage = ''
  loading: boolean
  challengeForm: FormGroup
  device: FormControl = new FormControl(false)
  transmitEnabledUser = false
  transmitSubmitAnswer = false
  transmitEmailNotificationUrl: string
  transmitLocked = false
  transmit_retry_counter = 0
  deviceId: string

  constructor(
    private readonly logonService: Logon,
    private readonly headerText: HeaderTextService,
    private readonly warningBanner: WarningBannerService,
    private readonly formBuilder: FormBuilder,
    private readonly session: SessionService,
    private readonly adobeAnalytics: AdobeAnalyticsService,
    private readonly windowRef: WindowRefService,
    private readonly viewService: ViewService,
    private readonly regionService: RegionService,
    private readonly contentService: ContentService,
    private readonly errorMsgService: ErrorMsgService,
    private readonly processResponseService: ProcessResponseService,
    readonly transmitQaService: TransmitQAService,
    private readonly transmitQAUiHandler: TransmitLogonQAUiHandler,
    private transmitEmailService: TransmitEmailService,
    private loggerService: LoggerService,
  ) {}

  ngOnInit() {
    this.challengeForm = this.challengeFormInit(this.formBuilder)
    this.transmitEnabledUser = this.session.transmitEnabled
    this.loading = true
    document.getElementById('displayQA').style.display = 'none'
    document.getElementById('global-header').style.display = 'none'
    document.getElementById('global-footer').style.display = 'none'
    document.getElementById('header-text').style.display = 'none'
    this.initiateTransmit()
    const data = {
      section: environment.LOGON_ADOBE_SECTION,
      subSection: environment.LOGON_ADOBE_SUBSECTION,
      pageId: environment.CHALLENGE_ADOBE_PAGEID,
    }
    this.content = this.contentService.caasContent
    this.userName = this.session.userName
    this.question = this.session.question
    this.qnaUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_REST_BASE +
      environment.LOGON_REST_PATH +
      environment.QNA_REST_POSTFIX
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.transmitEmailNotificationUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE +
      environment.TRANSMIT_EMAIL_NOTIFICATION_ENDPOINT
    this.headerText.setComponent(
      this.content.Data.challengePage.content.titleId,
    )
    this.headerText.setHeader(
      this.content.Data.challengePage.content.headerText,
    )
    this.headerText.setShowHeadText(false)
    this.adobeAnalytics.updateData(data)
    this.adobeAnalytics.track()
    window.setTimeout(
      () => document.getElementById('answerInputBox').focus(),
      0,
    )
  }

  ngAfterViewChecked() {
    if (document.getElementById('continueBtn')) {
      this.setAriaAttributeToElement(
        document
          .getElementById('continueBtn')
          .getElementsByTagName('button')
          .item(0),
        'warningBannerError',
      )
    }
  }
  setAriaAttributeToElement(element, id) {
    if (element != null) {
      return element.setAttribute('aria-describedby', id)
    }
  }

  challengeFormInit(formBuilder: FormBuilder) {
    return formBuilder.group({
      securityAnswer: new FormControl('', Validators.required),
      device: this.device,
    })
  }

  resetForm() {
    this.challengeForm.controls['device'].setValue(false)
    this.challengeForm.controls['securityAnswer'].setValue('')
  }

  authUserSecurityQuestion() {
    this.clearMsgs()
    if (this.challengeForm.invalid) {
      this.resetForm()
      this.challengeForm.controls['securityAnswer'].markAsTouched()
      this.challengeForm.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      })
    } else {
      this.transmitSubmitAnswer = true
      //Set the "remember me" choice here so that the UI handler has access to it
      TransmitLogonQAUiHandler.prototype.bound = this.challengeForm.value.device
      document.getElementById('continueBtn').click()
    }
  }

  clearMsgs(): void {
    this.errorMsgService.clearAll()
    this.warningBanner.setWarningBannerMessage('')
    this.checkMsgs()
  }

  checkMsgs(): void {
    this.incorrectSecurityAnswer = this.errorMsgService.incorrectSecurityAnswer
    this.invalidMessage = this.errorMsgService.invalidMessage
    this.warningBanner.setWarningBannerMessage(
      this.errorMsgService.invalidMessage,
    )
    this.loading = this.errorMsgService.loading
    if (this.incorrectSecurityAnswer) {
      this.warningBanner.setWarningBannerMessage('incorrectSecurityAnswer')
    }
  }

  cancel() {
    this.session.clear()
    this.clearMsgs()
    //Need to cancel the current journey, get a new ticket, and start a new journey if the user wants to sign up again
    sdk.cancelCurrentRunningControlFlow()
    this.logonService.cancel(this.cancelRestEndPoint).subscribe({
      next: (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
      error: (error) => {
        this.loggerService.error(
          'Error occured while canceling Q&A from challenge component for Poid: ' +
            this.session.poid +
            ', Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    })
  }
  initiateTransmit() {
    this.transmit_retry_counter = 0
    const transmitModel = {
      ticket: this.session.transmitTicket,
      poId: this.session.poid,
      journeyName: Transmit.QA_JOURNEY_NAME,
    }

    const encryptedRetailParams = false

    const transmitParams = {
      auth1: this.session.auth1,
      auth2: this.session.auth2,
      devicePrint: this.session.devicePrint,
      retailParams: encryptedRetailParams,
      isOOBBypassClient: this.session.oobBypass,
    }

    this.transmitQAUiHandler.setUpQaUiHandler(this, this.transmitQaService)
    this.invokeTransmitQaJourney(transmitModel, transmitParams)
  }

  invokeTransmitQaJourney(transmitModel: any, transmitParams: any) {
    this.transmitQaService
      .invokeQAJourney(transmitModel, transmitParams)
      .then((result: TransmitAuthenticateResult) => {
        this.loggerService.info(
          'Transmit Journey ' +
            transmitModel.journeyName +
            ' for Transmit Ticket # ' +
            transmitModel.ticket +
            'is complete and logged out for poid: ' +
            transmitModel.poId,
        )
        this.loading = false
        let serviceResponse: ServiceResponse
        if (result === TransmitAuthenticateResult.RETRY) {
          if (this.transmit_retry_counter < 2) {
            this.transmit_retry_counter++
            this.invokeTransmitQaJourney(transmitModel, transmitParams)
          } else {
            this.loggerService.info(
              'Transmit Journey ' +
                transmitModel.journeyName +
                ' for Transmit Ticket # ' +
                transmitModel.ticket +
                ' retry twice completed and cailed in all for Poid :' +
                transmitModel.poId,
            )
            this.errorHandling()
          }
        }
        if (result === TransmitAuthenticateResult.SUCCESSFUL) {
          this.loading = false
          this.loggerService.info(
            'Transmit Journey ' +
              transmitModel.journeyName +
              ' for Transmit Ticket # ' +
              transmitModel.ticket +
              ' Successfull for Poid : ' +
              transmitModel.poId,
          )

          if (this.session.oobBypass) {
            serviceResponse = {
              state: environment.SUCCESSFULL,
              transmitRedirectUrl: this.session.redirectUrl,
            }
          } else {
            serviceResponse = {
              authAction: environment.OOB_ENROLL,
              state: environment.OUT_OF_BAND_TRANSMIT,
              transmitTicket: '',
              poid: Number(this.session.poid),
            }
          }
          switch (this.transmitQAUiHandler.userStatus) {
            case TransmitValidateUserStatus.UNVERIFIED:
              serviceResponse.state = environment.SUCCESSFULL
              serviceResponse.authAction = environment.AA_RESET
              break
            case TransmitValidateUserStatus.USER_STATUS_F:
              serviceResponse.state = environment.SUCCESSFULL
              serviceResponse.authAction = environment.USER_STATUS_F
              if (this.transmitQAUiHandler.isResetQa) {
                this.processResponseService.isResetQA = true
              }
              break
            case TransmitValidateUserStatus.USER_STATUS_C:
              serviceResponse.state = environment.SUCCESSFULL
              serviceResponse.authAction = environment.USER_STATUS_C
              break
            case TransmitValidateUserStatus.DELETED: // this is where status = D and userStatus = NA, indicating incomplete registration
              serviceResponse.state = environment.SUCCESSFULL
              serviceResponse.authAction = environment.AA_ENROLL
              this.processResponseService.isNewUser = true
              break
          }

          this.processResponseService.proccess(serviceResponse)
        } else if (result === TransmitAuthenticateResult.LOCKOUT) {
          this.loggerService.info(
            'User Poid ' +
              transmitModel.poId +
              ' is in LOCKED State for Transmit ' +
              transmitModel.journeyName +
              ' Transmit # ' +
              transmitModel.ticket,
          )
          serviceResponse = {
            authAction: environment.AA_QA_LOCKEDOUT,
            state: environment.USERNAME_AND_PASSWORD,
            transmitTicket: '',
          }
          if (this.transmitSubmitAnswer) {
            this.transmitEmailService
              .sendEmail(
                this.transmitEmailNotificationUrl,
                EmailTemplate.QA_LOCKED_TEMPLATE,
                parseInt(this.session.poid),
              )
              .subscribe({
                next: (data) => {
                  this.loggerService.info(
                    'Email Successfully Sent for User Poid ' +
                      transmitModel.poId,
                  )
                  this.processResponseService.proccess(serviceResponse)
                },
                error: (error) => {
                  this.loggerService.error(
                    'Error occured while sending email User Poid ' +
                      transmitModel.poId +
                      ' with Error Status :' +
                      error.status,
                  )
                  this.errorHandling()
                },
              })
          }
          this.processResponseService.proccess(serviceResponse)
        } else if (result === TransmitAuthenticateResult.EXPIRED) {
          this.loggerService.info(
            'Transmit Session Expired, Please try again for Poid: ' +
              transmitModel.poId +
              ' tranmit ticket: ' +
              transmitModel.ticket,
          )
          serviceResponse = {
            authAction: environment.AA_CHALLENGE_EXPIRED,
            state: environment.USERNAME_AND_PASSWORD,
            transmitTicket: '',
          }
          this.processResponseService.proccess(serviceResponse)
        } else {
          this.loggerService.info(
            'Failed to complete QA Journey for Poid: ' +
              transmitModel.poId +
              ', tranmit ticket: ' +
              transmitModel.ticket,
          )
          serviceResponse = {
            authAction: environment.GENERIC_ERROR,
            state: environment.USERNAME_AND_PASSWORD,
            transmitTicket: '',
          }
          this.processResponseService.proccess(serviceResponse)
        }
      })
      .catch((err) => {
        this.loggerService.error(
          'Error in invoking transmit QA journey for Poid: ' +
            this.session.poid +
            ', Status: ' +
            err.status +
            ', error: ' +
            err.message,
        )
        this.errorHandling()
      })
  }

  errorHandling() {
    const serviceResponse: ServiceResponse = {
      authAction: environment.GENERIC_ERROR,
      state: environment.UNSUCCESSFULL,
      transmitTicket: '',
    }

    this.processResponseService.proccess(serviceResponse)
    this.loading = false
  }

  hidePw = true
  inputType = this.hidePw ? 'password' : 'text'
  handleShowHideBtn(): void {
    this.hidePw = !this.hidePw
    this.inputType = this.hidePw ? 'password' : 'text'
  }
}
