<spinner [active]="loading"></spinner>
<div class="view-container">
  <div id="firstParagraph" class="margin-top-42 font-family-UBL font-size-16 font-weight-normal margin-bottom-24 max-width-926">{{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.firstParagraph}}</div>
  <div class="font-family-UBL font-size-16 font-weight-normal margin-bottom-24 max-width-926" id="secondParagrah">
    {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.secondParagraph}}
    <a id="getMoreInfoLink" class="link-without-href font-family-UBM font-size-16 font-weight-normal nowrap-white-space underline-border"
      role="link" tabindex="0" target="_blank" (click)="openTipsLayer()" (keydown.enter)="openTipsLayer()">
      <span class="vui-text-color-link vui-link">
        {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.secondParagraphLink}}
      </span>
    </a>
    <ng-template #tipsContentDialog>
      <oob-tips-dialog></oob-tips-dialog>
    </ng-template>
  </div>
  <div id="thirdParagraph" class="font-family-UBL font-size-16 font-weight-normal margin-bottom-24 max-width-926">
      {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.thirdParagraph}}</div>
  <div>
    <div class="inline-block">
    <vui-checkbox id="termsCheckbox" class="vui-checkbox margin-right-5" [(ngModel)]="checkedTC">
      {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobTermsCheckboxContent}}
      <span [hidden]="true" aria-hidden="false">{{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobTermsLink}}</span>
    </vui-checkbox>
    </div>
    <div class="inline-block">
      <oob-terms-view></oob-terms-view>
    </div>
  </div>

  <div *ngIf="reviewTC && !checkedTC" id="tcErrorBox" class="rectangle"></div>
  <div *ngIf="reviewTC && !checkedTC" class="custom-error error-please-acknow">
      <span class="font-family-UBB">{{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.termsCondReviewError}}&nbsp;</span>
      <span>{{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.termsCondReviewTitle}}</span>
  </div>

  <div id="horizontalLine2" class="gray-color margin-bottom-32 margin-top-40 horizontal-line max-width-926"></div>
  <div [hidden]="!checkedTC" id="oobBottomSection" class="font-family-UBL font-size-16 font-weight-normal margin-bottom-24">
    <h2 id="contactInfoHeader" class="font-family-UBB font-size-24 contactInfoHeader">{{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.headerText1}}</h2>
    <form [formGroup]="oobOtpChallengeForm">
      <custom-input (keyup.enter)="otpChallenge()" formControlName="domesticPhoneNumber" id="domesticPhoneNumber" type="domesticPhoneNumber" maxWidth="380px" widthPercent="306px"
        [form]="oobOtpChallengeForm" [countryPhoneCode]=1 [label]="content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.phoneNumber">
      </custom-input>
      <table id="contactInfo" class="margin-bottom-36 margin-top-32 mobile-margin-top-40">
        <h2 id="contactMethodLabel" class="font-family-UBB font-size-16 font-weight-normal padding-right-14 width-150 margin-bottom-24">
            {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.contactMethod}}
        </h2>
        <tr>
          <td>
            <vui-radio-group [formControl]="contactMethodRadio" name="contactMethodOptions">
              <vui-radio id="textOptionDomestic" class="display-block font-family-UBL font-size-14 vui-radio-grey padding-right-16 margin-bottom-24" [value]="'SMS_TEXT'">
                <span class="padding-left-6">
                  {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.text}}
                </span>
              </vui-radio>
              <vui-radio  id="callOption" class="font-family-UBL font-size-14 vui-radio-grey padding-right-24" [value]="'AUTOMATED_CALL'">
                <span class="padding-left-6">
                  {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.call}}
                </span>
              </vui-radio>
            </vui-radio-group>
          </td>
        </tr>
      </table>
        <ul id="additionalInfo">
        <li class="font-family-UBL font-size-16 font-weight-normal margin-bottom-24">
          {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.info1}} </li>
        <li class="font-family-UBL font-size-16 font-weight-normal margin-bottom-24">
          {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.info2}} </li>
        <li class="font-family-UBL font-size-16 font-weight-normal margin-bottom-24">
          {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.info3}} </li>
        </ul>
      <p id="frequencyHeader" class="font-family-UBB font-size-16 font-weight-normal margin-bottom-24 margin-top-40">
        {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.freqQuestion}}
      </p>
      <vui-radio-group [formControl]="frequencyRadio" name="frequencyInput">
        <vui-radio id="freqAlways" class="display-block  font-family-UBL font-size-14 vui-radio-grey margin-bottom-24" [value]="'ALWY'">
          <span>
            {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.freqAlways}}
          </span>
        </vui-radio>
        <vui-radio  id="freqUnbound" class="display-block font-family-UBL font-size-14 vui-radio-grey margin-bottom-24" [value]="'UBND'">
          <span>
            {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.freqUnbound}}
          </span>
        </vui-radio>
      </vui-radio-group>
    </form>
    <!-- OTP PopUp window -->
    <ng-template #otpPopUp>
      <h2 id="otpPopUp" class="otp-vui-dialog-title margin-left-14">
          {{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.title}}
      </h2>
      <div class="vui-dialog-body-content">
        <div id="popupErrorBanner" role="alert">
          <div *ngIf="showPopUpMsg&&popUpMsg==='serviceFailure'" class="resend-status-div margin-left-14">
            <vui-banner [vui-closeable]="false" id="sendCodeFailureMsg" vui-type="error" class="sendCodeFailure">
              <div header id="invalidBannerHeader" class="sendCodeFailureHeader">{{content.Data.logonPage.content.failedMsg.failedMsg.failedTitle}}
                    {{content.Data.logonPage.content.failedMsg.failedMsg.failedContent}}</div>
            </vui-banner>
          </div>
          <div *ngIf="showPopUpMsg&&popUpMsg==='resendSuccess'" class="resend-status-div margin-left-14">
                <div class="boxed sendCodeSuccessMsg">
                  <vui-icon id="successIcon" [icon]="checkCircleMd" class="confirmation"></vui-icon>
                  <div id="invalidBannerHeader" class="sendCodeSuccessHeader">{{content.Data.oobSecurityPage.content.successSendCode}}</div>
                </div>
          </div>
          <div *ngIf="showPopUpMsg&&popUpMsg==='invalidCode'" class="resend-status-div margin-left-14">
            <vui-banner vui-banner-id="invalidCodeMsg" vui-type="error" [vui-closeable]="false" class="invalidCodeMsg">
                <div header id="invalidCodeHeader" class="invalidCodeHeader">{{content.Data.oobSecurityPage.content.expiredCodeMsg.expiredCodeMsg.expiredCodeMsgTitle}}</div>
                <div body>
                    <ol class="padding-left-1-2">
                        <li class="font-family-UBR font-size-16 font-weight-normal">{{content.Data.oobSecurityPage.content.expiredCodeMsg.expiredCodeMsg.expiredCodeMsgFirst}}</li>
                        <li class="font-family-UBR font-size-16 font-weight-normal">{{content.Data.oobSecurityPage.content.expiredCodeMsg.expiredCodeMsg.expiredCodeMsgSecond}}</li>
                    </ol>
                </div>
            </vui-banner>
          </div>
        </div>
      <p class="pre-line-white-space otp-sub-text margin-left-14">
          {{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.subtext}}</p>
      <form [formGroup]="oobEnrollmentForm">
          <div class="margin-bottom-24 margin-top-42 margin-left-14">
              <span id="phoneNumberLabel" for="phoneNumber"
                  class="font-family-UBB font-size-16 margin-right-80 contact-label">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.phoneNumberLabel}}</span>
              <span id="phoneNumber"
                  class="font-family-UBR font-size-16 mobile">
                  {{phoneNumberFormater(oobOtpChallengeForm.controls['domesticPhoneNumber'].value)}}
              </span>
          </div>
          <div class="margin-left-14">
              <span id="contactMethodLabel" for="contactMethod"
                  class="font-family-UBB font-size-16 margin-right-80 contact-label ">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.contactMethodLabel}}</span>
              <span *ngIf="oobOtpChallengeForm.controls['contactMethodRadio'].value === 'SMS_TEXT'"
                  id="contactMethod"
                  class="font-family-UBR font-size-16 mobile">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.contactMethodText}}</span>
              <span *ngIf="oobOtpChallengeForm.controls['contactMethodRadio'].value === 'AUTOMATED_CALL'"
                  id="contactMethod"
                  class="font-family-UBR font-size-16 mobile">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.contactMethodCall}}</span>
          </div>
          <div class="margin-bottom-0 margin-left-14 margin-top-13 input-box-height-56px width-306">
            <custom-input (keyup.enter)="oobEnroll()" formControlName="oobCode" id="oobCode"
                class="row margin-right-8"
                otpPopUp="true" type="oobCode" [form]="oobEnrollmentForm"
                [inputContent]="validationCodeMsgText"
                [label]="content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.validationCodeLabel"
                (sendCode)="sendNewOtp()" [disableLink]="disableLink" ariaDescribedBy="oobCodeError">
            </custom-input>
          </div>
      </form>
      <div class="float-right popup-btn-div margin-left-14">
              <vui-button type="button" id="closePopUpOtp" (click)="closeDialog()" [style]="'secondary'"
                  class="cancel-btn-padding-right inline-block">
                  {{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.cancelBtn}}
              </vui-button>
              <vui-button type="button" id="submitPopUpOtp" [style]="'primary'" (click)="oobEnroll()"
                  [disabled]="oobEnrollmentForm.invalid" class="inline-block">
                  {{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.submitBtn}}
              </vui-button>
      </div>
    </div>
  </ng-template>
  </div>
  <div class="d-flex margin-bottom-86 margin-top-47">
    <cancel-layer></cancel-layer>
    <div class="padding-left-16 padding-bottom-12">
      <vui-button type="button" id="signUpBtn" [style]="'primary'"
        (click)="otpChallenge()" class="margin-left-40">
        {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.signUpBtn}}
      </vui-button>
    </div>
  </div>
</div>
