import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class HttpWithCredentialsInterceptor implements HttpInterceptor {
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    console.log('HttpWithCredentialsInterceptor: ' + httpRequest.url)
    if (!httpRequest.withCredentials) {
      httpRequest = httpRequest.clone({ withCredentials: true })
    }
    return next.handle(httpRequest)
  }
}
