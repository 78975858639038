import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { SessionService } from '@app/shared/services/sessionService'

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {
  private readonly CORRELATION_ID_HEADER_NAME: string = 'Correlation-Id'

  constructor(private sessionService: SessionService) {}

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    console.log('CorrelationIDInterceptor: ' + httpRequest.url)
    if (this.sessionService.correlationId) {
      httpRequest = httpRequest.clone({
        headers: httpRequest.headers.set(
          this.CORRELATION_ID_HEADER_NAME,
          this.sessionService.correlationId,
        ),
      })
    }
    return next.handle(httpRequest)
  }
}
