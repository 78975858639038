import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable()
export class HeaderTextService {
  private header: BehaviorSubject<string> = new BehaviorSubject<string>('')
  private header$: Observable<string> = this.header.asObservable()
  private headComponent: BehaviorSubject<string> = new BehaviorSubject<string>(
    '',
  )
  private headComponent$: Observable<string> = this.headComponent.asObservable()
  private showHeadText: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true,
  )
  private showHeadText$: Observable<boolean> = this.showHeadText.asObservable()
  private headText: BehaviorSubject<string> = new BehaviorSubject<string>('')
  private headText$: Observable<string> = this.headText.asObservable()
  constructor() {}

  setHeader(header: string) {
    this.header.next(header)
  }

  getHeader(): Observable<string> {
    return this.header$
  }

  setComponent(headComponent: string) {
    this.headComponent.next(headComponent)
  }

  getComponent(): Observable<string> {
    return this.headComponent$
  }

  setShowHeadText(showHeadText: boolean) {
    this.showHeadText.next(showHeadText)
  }

  getShowHeadText(): Observable<boolean> {
    return this.showHeadText$
  }

  setHeadText(headText: string) {
    this.headText.next(headText)
  }

  getHeadText(): Observable<string> {
    return this.headText$
  }
}
