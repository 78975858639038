import { AbstractControl, ValidatorFn, FormControl } from '@angular/forms'

export function sameAnswerValidator(secondControl, thirdControl): ValidatorFn {
  return (first: AbstractControl): { [key: string]: any } | null => {
    if (!first.parent) {
      return null
    }
    const second = first.parent.get(secondControl) as FormControl
    const third = first.parent.get(thirdControl) as FormControl
    return first.value === second.value || first.value === third.value
      ? { sameAnswer: true }
      : null
  }
}
