import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  Input,
  AfterViewInit,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core'
import { CancelResponse } from '@app/models/CancelResponse'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { OtpChallengePayload } from '@app/models/OtpChallengePayload'
import { OtpEnrollmentPayload } from '@app/models/OtpEnrollmentPayload'
import { ServiceResponse } from '@app/models/ServiceResponse'
import { ResendCodePayload } from '@app/models/ResendCodePayload'
import { StatusResponse } from '@app/models/StatusResponse'
import { Logon } from '@app/shared/services/logonService'
import { ContentService } from '@app/shared/services/contentService'
import { AdobeAnalyticsService } from '@app/shared/services/adobe-analytics/adobe-analytics.service'
import { RegionService } from '@app/shared/services/regionService'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { ProcessResponseService } from '@app/shared/services/processResponseService'
import { environment } from '@env'
import { numOfDigitsValidator } from '@app/shared/validators/numOfDigitsValidator'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { VuiDialogConfig, DialogComponent } from '@vanguard/vui/dialog'
import { checkCircleMd } from '@vanguard/vui/icons'
import { AdobeLaunchConfigurationService } from '@app/shared/services/adobe-launch-configuration/adobeLaunchConfiguration.service'
import { VieFormsRedirectService } from '@app/shared/services/vieFormsRedirect/vieFormsRedirect.service'

@Component({
  selector: 'domestic-oob-enrollment',
  templateUrl: './domestic-oob-enrollment.component.html',
  styleUrls: ['./domestic-oob-enrollment.component.scss'],
  providers: [DialogComponent],
  encapsulation: ViewEncapsulation.None,
})
export class DomesticOobEnrollmentComponent implements OnInit, AfterViewInit {
  @ViewChild('otpPopUp', { static: false }) public otpPopUp: TemplateRef<any>
  @ViewChild('tipsContentDialog', { static: false })
  private readonly tipsContentDialog: TemplateRef<any>
  vuiDialogConfig: VuiDialogConfig = {
    dialogType: 'simple',
    backdropDismiss: true,
  }
  checkedTC = false
  reviewTC = false
  cancelResponse: CancelResponse
  content: any
  oobEnrollmentForm: FormGroup
  oobOtpChallengeForm: FormGroup
  continueBtnDisabled = true
  cancelRestEndPoint: string
  disableForm = false
  validationCodeMsgText: string[]
  EM_DASH: string
  adobeData: { section: string; subSection: string; pageId: string }
  hideRememberMe = true
  otpChallengeUrl: string
  otpEnrollmentUrl: string
  otpChallengePayload: OtpChallengePayload
  otpEnrollmentPayload: OtpEnrollmentPayload
  serviceResponse: ServiceResponse
  loading: boolean
  popUpOtpContent: string
  invalidMessage = ''
  invalidOTPMessage = ''
  resendCodePayload: ResendCodePayload
  showPopUpMsg: boolean
  popUpMsg: string
  otpResponse: StatusResponse
  isInternationalOOB = false
  @Input() isInternational: string
  borderElement: any
  inputElement: any
  contactMethodRadio: FormControl = new FormControl(environment.TEXT_OPTION)
  frequencyRadio: FormControl = new FormControl(environment.FREQ_ALWAYS)
  public readonly checkCircleMd = checkCircleMd
  formUrl: string

  constructor(
    private readonly logonService: Logon,
    private readonly warningBanner: WarningBannerService,
    private readonly fb: FormBuilder,
    private readonly contentService: ContentService,
    private readonly adobeAnalytics: AdobeAnalyticsService,
    private readonly regionService: RegionService,
    private readonly headerText: HeaderTextService,
    private readonly errorMsgService: ErrorMsgService,
    private readonly viewContainer: ViewContainerRef,
    public vuiDialog: DialogComponent,
    private readonly processResponseService: ProcessResponseService,
    private readonly renderer: Renderer2,
    private readonly adobeLaunchConfigurationService: AdobeLaunchConfigurationService,
    private readonly vieFormsRedirectService: VieFormsRedirectService,
  ) {}

  ngOnInit() {
    this.adobeData = {
      section: environment.LOGON_ADOBE_SECTION,
      subSection: environment.LOGON_ADOBE_SUBSECTION,
      pageId: environment.OOB_CHALLENGE_ADOBE_PAGEID,
    }
    this.content = this.contentService.caasContent
    this.headerText.setComponent(
      this.content.Data.registrationPage.content.forcedOobEnrollmentLayer
        .forcedOobEnrollmentLayer.titleId,
    )
    this.headerText.setHeader(
      this.content.Data.registrationPage.content.forcedOobEnrollmentLayer
        .forcedOobEnrollmentLayer.headerText,
    )
    this.headerText.setShowHeadText(true)
    this.headerText.setHeadText(
      this.content.Data.registrationPage.content.forcedOobEnrollmentLayer
        .forcedOobEnrollmentLayer.subHeaderText,
    )
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.otpChallengeUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + ''
    this.otpEnrollmentUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.SECURIY_CODE_OTP_ENROLLMENT
    this.validationCodeMsgText = [
      this.content.Data.oobSecurityPage.content.securityCodeForm
        .securityCodeForm.validationCodeMsgText.validationCodeMsgText.text,
      this.content.Data.oobSecurityPage.content.securityCodeForm
        .securityCodeForm.validationCodeMsgText.validationCodeMsgText.link,
    ]
    this.EM_DASH = environment.EM_DASH
    this.adobeAnalytics.updateData(this.adobeData)
    this.adobeAnalytics.track()

    this.oobEnrollmentForm = this.fb.group({
      oobCode: new FormControl('', [
        Validators.required,
        Validators.pattern(environment.ALPHA_NUM_SPECIAL_CHAR_REG_EX),
        Validators.maxLength(20),
      ]),
    })
    this.oobOtpChallengeForm = this.fb.group({
      domesticPhoneNumber: new FormControl('', [
        Validators.required,
        numOfDigitsValidator(10, this.isInternational, null),
        Validators.pattern(environment.PHONE_NUM_REG_EX),
      ]),
      contactMethodRadio: this.contactMethodRadio,
      frequencyRadio: this.frequencyRadio,
    })
    this.adobeLaunchConfigurationService.trackPageLoad(environment.OUT_OF_BAND)

    this.formUrl = window.location.href
  }
  ngAfterViewInit() {
    this.inputElement = this.renderer.selectRootElement(
      '.phoneNumber-input-dom',
      true,
    )

    this.borderElement = this.renderer.selectRootElement(
      '.mat-form-field-flex',
      true,
    )

    this.renderer.listen(this.borderElement, 'click', () => {
      const formattedValue = this.oobOtpChallengeForm.controls[
        'domesticPhoneNumber'
      ].value
        .replace(/ /g, '')
        .replace('+1', '')

      this.renderer.setProperty(this.inputElement, 'value', formattedValue)
    })
  }
  resetForm() {
    this.oobOtpChallengeForm.controls['contactMethodRadio'].setValue(
      environment.TEXT_OPTION,
    )
    this.oobOtpChallengeForm.controls['frequencyRadio'].setValue(
      environment.FREQ_ALWAYS,
    )
    this.oobOtpChallengeForm.controls['domesticPhoneNumber'].setValue('')
  }

  phoneNumberFormater(phoneNumber): string {
    return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})+$/, '+1 $1 $2 $3')
  }

  clearMsgs(): void {
    this.errorMsgService.clearAll()
    this.loading = false
    this.invalidMessage = ''
    this.warningBanner.setWarningBannerMessage('')
    this.popUpMsg = ''
    this.invalidOTPMessage = ''
  }

  checkMsgs(oobEnroll: boolean): void {
    this.loading = this.errorMsgService.loading
    if (oobEnroll) {
      if (this.errorMsgService.invalidMessage === 'serviceFailure') {
        this.warningBanner.setWarningBannerMessage('serviceFailure')
        this.invalidMessage = 'serviceFailure'
      }
    } else {
      this.warningBanner.setWarningBannerMessage(
        this.errorMsgService.invalidMessage,
      )
      this.invalidMessage = this.errorMsgService.invalidMessage
      this.popUpMsg = this.errorMsgService.popUpMsg
    }
    this.invalidOTPMessage = this.errorMsgService.invalidOTPMessage
    if (this.errorMsgService.invalidOTPMessage !== '') {
      this.warningBanner.setWarningBannerMessage(
        this.errorMsgService.invalidOTPMessage,
      )
    }
  }

  otpChallenge() {
    if (!this.checkedTC) {
      this.reviewTC = true
    } else {
      this.clearMsgs()
      this.reviewTC = false
      this.oobEnrollmentForm.reset()
      if (!this.oobOtpChallengeForm.invalid) {
        this.loading = true
        const phoneNumber = this.oobOtpChallengeForm.controls[
          'domesticPhoneNumber'
        ].value.replace(/\s/g, '')
        this.otpChallengePayload = {
          otpPhone: {
            areaCode: phoneNumber.substring(0, 3),
            phoneNumber: phoneNumber.substring(3, 10),
            primary: true,
            deliveryMethod:
              this.oobOtpChallengeForm.controls['contactMethodRadio'].value,
          },
          enrollRequest: true,
        }
        this.logonService
          .otpChallenge(this.otpChallengeUrl, this.otpChallengePayload)
          .subscribe({
            next: (data: StatusResponse) => {
              this.adobeLaunchConfigurationService.trackOobSignUp()
              this.processResponseService.proccessForcedOobEnrollment(data)
              this.otpResponse = data
              this.checkMsgs(false)
              this.openOtpPopUp(this.errorMsgService.openOtpPopUp)
            },
            error: (error) => {
              console.log(
                'Error occured while sending user OTP challenge from forced OOB enrollment component: Status: ' +
                  error.status +
                  ', error: ' +
                  error.message,
              )
              this.loading = false
              this.warningBanner.setWarningBannerMessage('serviceFailure')
              this.invalidMessage = 'serviceFailure'
            },
          })
      } else {
        this.oobOtpChallengeForm.controls['domesticPhoneNumber'].markAsTouched()
      }
    }
  }

  sendNewOtp() {
    this.clearMsgs()
    this.oobEnrollmentForm.reset()
    this.loading = true
    this.otpChallengePayload = {
      otpPhone: {
        areaCode: this.oobOtpChallengeForm.controls[
          'domesticPhoneNumber'
        ].value.substring(0, 3),
        phoneNumber: this.oobOtpChallengeForm.controls[
          'domesticPhoneNumber'
        ].value.substring(3, 10),
        primary: true,
        deliveryMethod:
          this.oobOtpChallengeForm.controls['contactMethodRadio'].value,
      },
      enrollRequest: true,
    }
    this.logonService
      .otpChallenge(this.otpChallengeUrl, this.otpChallengePayload)
      .subscribe({
        next: (data) => {
          this.otpResponse = data
          this.processResponseService.proccessForcedOobEnrollment(data)
          this.checkMsgs(false)
        },
        error: (error) => {
          console.log(
            'Error occured while sending user a new OPT Challenge code from forced OOB enrollment component: Status: ' +
              error.status +
              ', error: ' +
              error.message,
          )
          this.loading = false
          this.popUpMsg = 'serviceFailure'
        },
      })
    this.showPopUpMsg = true
  }

  openTipsLayer() {
    this.vuiDialog.openDialog(
      this.viewContainer,
      this.tipsContentDialog,
      this.vuiDialogConfig,
    )
  }

  closeDialog() {
    this.vuiDialog.closeDialog()
  }

  openOtpPopUp(open: boolean) {
    if (open) {
      this.vuiDialog.openDialog(
        this.viewContainer,
        this.otpPopUp,
        this.vuiDialogConfig,
      )
      this.vuiDialog.dialogClosed.subscribe(() => {
        this.showPopUpMsg = false
      })
    }
  }

  createOobEnrollPayload(statusResponse: StatusResponse): OtpEnrollmentPayload {
    const consentVersion =
      this.content.Data.oobSecurityPage.content.consentVersion
    const otpEnrollmentPayload: OtpEnrollmentPayload = {
      aaSessionAttributes: {
        authChallengeType: statusResponse.aaSessionAttributes.authChallengeType,
        authSessionId: statusResponse.aaSessionAttributes.authSessionId,
        authTransactionId: statusResponse.aaSessionAttributes.authTransactionId,
      },
      enrollmentDetails: {
        consentVersion: Number(consentVersion) || 1,
        challengeFrequencyCode:
          this.oobOtpChallengeForm.controls['frequencyRadio'].value,
        registeredPhones: [
          {
            international: false,
            areaCode: this.oobOtpChallengeForm.controls[
              'domesticPhoneNumber'
            ].value.substring(0, 3),
            deliveryMethod:
              this.oobOtpChallengeForm.controls['contactMethodRadio'].value,
            phoneNumber: this.oobOtpChallengeForm.controls[
              'domesticPhoneNumber'
            ].value.substring(3, 10),
            primary: true,
          },
        ],
      },
      oneTimePasscode: '',
    }
    return otpEnrollmentPayload
  }

  oobEnroll() {
    this.clearMsgs()
    if (!this.oobEnrollmentForm.invalid) {
      this.loading = true
      this.oobEnrollmentForm.disable()
      this.otpEnrollmentPayload = this.createOobEnrollPayload(this.otpResponse)
      this.otpEnrollmentPayload.oneTimePasscode =
        this.oobEnrollmentForm.controls['oobCode'].value
      this.logonService
        .otpEnrollment(this.otpEnrollmentUrl, this.otpEnrollmentPayload)
        .subscribe({
          next: (data: StatusResponse) => {
            //VIE forms redirection
            if (
              this.formUrl.includes('account-maintenance%2F') &&
              this.processResponseService.isVieUser
            ) {
              data.redirectUrl = this.vieFormsRedirectService.vieFormUrl(
                this.formUrl,
              )
            }

            this.processResponseService.proccess(data)
            this.checkMsgs(true)
            this.oobEnrollmentForm.controls['oobCode'].reset()
            this.oobEnrollmentForm.enable()
          },
          error: (error) => {
            console.log(
              'Error occured while sending user enroll form forced OOB enrollment component: Status: ' +
                error.status +
                ', error: ' +
                error.message,
            )
            this.loading = false
            this.oobEnrollmentForm.enable()
            if (error.status === 400) {
              this.popUpMsg = 'invalidCode'
              this.showPopUpMsg = true
              this.oobEnrollmentForm.controls['oobCode'].setValue('')
            } else {
              this.popUpMsg = 'serviceFailure'
              this.showPopUpMsg = true
            }
          },
        })
    } else {
      this.oobOtpChallengeForm.controls['domesticPhoneNumber'].markAsTouched()
    }
  }
}
