import { OobChallenge } from '../../oobChallenge/oobChallenge'
import { ResendCodePayload } from '@app/models/ResendCodePayload'
import { ResendCodeResponse } from '@app/models/ResendCodeResponse'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { ServiceResponse } from '@app/models/ServiceResponse'

@Injectable()
export class OobChallengeService {
  private oobChallenge: OobChallenge = {}

  constructor(private httpClient: HttpClient) {}

  get oobPhone(): string {
    return this.oobChallenge.oobPhone
  }

  set oobPhone(oobPhone: string) {
    this.oobChallenge.oobPhone = oobPhone
  }

  get oobBound() {
    return this.oobChallenge.oobBound
  }

  set oobBound(bound: boolean) {
    this.oobChallenge.oobBound = bound
  }

  get logOnResponse(): ServiceResponse {
    return this.oobChallenge.logOnResponse
  }

  set logOnResponse(logOnResponse: ServiceResponse) {
    this.oobChallenge.logOnResponse = logOnResponse
  }

  get transmitEnabledUser() {
    return this.oobChallenge.transmitEnabledUser
  }

  set transmitEnabledUser(transmitEnabledUser: boolean) {
    this.oobChallenge.transmitEnabledUser = transmitEnabledUser
  }

  sendNewCode(
    url: string,
    body: ResendCodePayload,
  ): Observable<ResendCodeResponse> {
    return this.httpClient.post<ResendCodeResponse>(url, body)
  }
}
