import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { PasswordChangePayload } from '@app/models/PasswordChangePayload'
import { PasswordChangeResponse } from '@app/models/PasswordChangeResponse'

@Injectable()
export class PasswordChangeService {
  constructor(private httpClient: HttpClient) {}

  changePassword(
    url: string,
    body: PasswordChangePayload,
  ): Observable<PasswordChangeResponse> {
    return this.httpClient.post<PasswordChangeResponse>(url, body)
  }
}
