<spinner [active]="loading"></spinner>
<div id ="displayQA">
    <div class="view-container">
        <p class="margin-top-16 margin-bottom-40">
            <span id="infoText" class="infoTextStyle">{{content.Data.selfServicePage.content.selfServiceQA.selfServiceQA.infoText}}</span>
        </p>
        <div id="selfServiceQAForm" class="row">
            <form [formGroup]="selfServiceQAForm" id="selfServiceQA" name="selfServiceQA">
                <div class="col-12">
                    <table  class="responsive-left-padding">
                        <tbody class="margin-top-24 self-service-input-fields-div">
                            <tr>
                                <td class="text-align-left security-question">
                                    <div id="securityQuestion">{{question}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="pw-input-wrapper">
                                    <c11n-input [hasError]="selfServiceQAForm?.get('securityAnswer')?.touched && selfServiceQAForm.get('securityAnswer')?.errors">
                                    <input 
                                      c11nInput
                                      [attr.type]="inputType"
                                      id="securityAnswer" 
                                      name="securityAnswer"
                                      aria-describedby="securityAnswerError"
                                      formControlName="securityAnswer"
                                      placeholder={{content.Data.selfServicePage.content.selfServiceQA.selfServiceQA.answerLabel}}
                                      (keyup.enter)="authUserSecurityQuestion()"
                                    />
                                  </c11n-input>
                                  <div class="c11n-text-sm pw-show-hide">
                                    <button
                                        c11n-link
                                        class="showHideBtn showHideBtnHoverAnimation"
                                        size="small"
                                        type="button"
                                        aria-controls="answerInputBox"
                                        (click)="handleShowHideBtn()"
                                    >
                                        {{ hidePw ? 'Show' : 'Hide' }}
                                    </button>
                                  </div>

                                  <c11n-hint-error class="margin-top-4"
                                  [hasError]="selfServiceQAForm?.get('securityAnswer')?.touched && selfServiceQAForm?.get('securityAnswer')?.hasError('required')"
                                  [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.requiredField"
                                  [hintErrorId]="'securityAnswerError'">
                                </c11n-hint-error>
                                <c11n-hint-error class="margin-top-4"
                                    [hasError]="selfServiceQAForm?.get('securityAnswer')?.touched && selfServiceQAForm?.get('securityAnswer')?.hasError('pattern')"
                                    [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.secAnswerPatternError"
                                    [hintErrorId]="'securityAnswerError'">
                                </c11n-hint-error>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                <div class="d-flex margin-bottom-96 width-306 margin-top-64">
                    <c11n-button 
                        id="cancelBtn" 
                        buttonType="button"
                        labelText={{content.Data.challengePage.content.cancelBtn}}
                        variant="secondary"
                        (clickEvent)="cancel()"
                    >
                    </c11n-button>
                    <c11n-button class="margin-left-40 continue-btn" 
                        id="continueBtn"
                        name="continueBtn"
                        buttonType="submit"
                        labelText={{content.Data.challengePage.content.continueBtn}}
                        variant="primary"
                        (clickEvent)="authUserSecurityQuestion()"
                        >
                    </c11n-button>
                </div>
                </div>
            </form>
        </div>
    </div>
</div>
