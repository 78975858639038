import { AbstractControl, ValidatorFn, FormControl } from '@angular/forms'
import { environment } from '@env'
import { GatekeeperService } from '@app/shared/services/gatekeeperService'

export function numOfDigitsValidator(
  length: number,
  isInternational: string,
  domesticCountryCode: boolean,
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.parent) {
      return null
    }

    const num = control.value.replace(/ /g, '').replace('+1', '')
    let validation = null
    if (num.length > length) {
      validation = { numOfDigitsMax: true }
    } else if (
      isInternational !== environment.INTERNATIONAL_OOB &&
      num.length < length &&
      !domesticCountryCode
    ) {
      validation = { numOfDigitsMin: true }
    } else if (
      isInternational === environment.INTERNATIONAL_OOB &&
      num.length < length &&
      !domesticCountryCode
    ) {
      validation = { numOfDigitsMin: true }
    } else if (
      isInternational === environment.INTERNATIONAL_OOB &&
      num.length < 5 &&
      domesticCountryCode
    ) {
      validation = { numOfDigitsMin: true }
    }
    return validation
  }
}
