import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { environment } from '@env'
import { AdobeAnalyticsService } from '../shared/services/adobe-analytics/adobe-analytics.service'
import { ContentService } from '@app/shared/services/contentService'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { SelfServicePayload } from '@app/models/SelfServicePayload'
import { ServiceResponse } from '@app/models/ServiceResponse'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { RegionService } from '@app/shared/services/regionService'
import { SessionService } from '@app/shared/services/sessionService'
import { Logon } from '@app/shared/services/logonService'
import { CancelResponse } from '@app/models/CancelResponse'
import { ViewService } from '@app/shared/services/viewService'
import { SelfServiceChallengePayload } from '@app/models/SelfServiceChallengePayload'
import { SelfService } from '@app/shared/services/selfService/selfService.service'
import { ProcessResponseService } from '@app/shared/services/processResponseService'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { TransmitSelfServiceQAUiHandler } from '@app/shared/services/transmitSelfServiceQA/transmitSelfServiceQAUiHandler'
import { TransmitQAService } from '@app/shared/services/transmitCommonService/transmitQA.service'
import {
  Transmit,
  TransmitAuthenticateResult,
} from '@app/models/TransmitConstants'
import { TransmitEmailService } from '@app/shared/services/transmitEmailService/transmitEmail.service'
import { EmailTemplate } from '@app/models/EmailTemplate'
import { OobChallengeService } from '@app/shared/services/oobChallengeService'
import { TransmitSdkService } from '@app/shared/services/transmitCommonService/transmitSdk.service'
import { LoggerService } from '@app/shared/services/loggerService'

declare const com: any

@Component({
  selector: 'self-service-qa-view',
  templateUrl: './selfServiceQA.component.html',
  styleUrls: ['./selfServiceQA.component.scss'],
})
export class SelfServiceQAComponent implements OnInit, AfterViewChecked {
  content: any
  loading: boolean
  selfServiceForm: FormGroup
  continueBtnDisabled = false
  private selfServiceChallengePayload: SelfServiceChallengePayload
  private serviceResponse: ServiceResponse
  private validPattern = /^[\w\s]*$/
  invalidMessage = ''
  selfServiceQAForm: FormGroup
  cancelRestEndPoint: string
  question: string
  incorrectSecurityAnswer = false
  authQuestionRestEndPoint: string
  securityAnswerError = 'securityAnswerError'
  deviceId: string
  transmitSubmitAnswer = false
  transmitEmailNotificationUrl: string
  oobChallengeService: any
  transmitEnabledUser = false
  transmitLocked = false
  transmit_retry_counter = 0
  securityAnswer: string

  constructor(
    private headerText: HeaderTextService,
    private adobeAnalytics: AdobeAnalyticsService,
    private contentService: ContentService,
    private warningBanner: WarningBannerService,
    private formBuilder: FormBuilder,
    private errorMsgService: ErrorMsgService,
    private regionService: RegionService,
    private session: SessionService,
    private viewService: ViewService,
    private logonService: Logon,
    private selfService: SelfService,
    private processResponseService: ProcessResponseService,
    readonly transmitQaService: TransmitQAService,
    private readonly transmitQAUiHandler: TransmitSelfServiceQAUiHandler,
    private transmitEmailService: TransmitEmailService,
    private transmitSdkService: TransmitSdkService,
    private readonly loggerService: LoggerService,
  ) {}

  ngOnInit(): void {
    this.loading = true
    document.getElementById('displayQA').style.display = 'none'
    document.getElementById('global-header').style.display = 'none'
    document.getElementById('global-footer').style.display = 'none'
    document.getElementById('header-text').style.display = 'none'
    const data = {
      pageId: environment.FORGOT_PWD_ADOBE_PAGEID,
    }
    this.transmitEnabledUser = this.session.transmitEnabled
    this.content = this.contentService.caasContent
    this.initiateTransmit()

    this.headerText.setComponent(
      this.content.Data.selfServicePage.content.selfServiceQA.selfServiceQA
        .titleId,
    )
    this.headerText.setHeader(
      this.content.Data.selfServicePage.content.selfServiceQA.selfServiceQA
        .headerText,
    )
    this.headerText.setShowHeadText(false)
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.authQuestionRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.SELF_SERVICE_CHALLENGE_ENDPOINT
    this.transmitEmailNotificationUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE +
      environment.TRANSMIT_EMAIL_NOTIFICATION_ENDPOINT

    this.adobeAnalytics.updateData(data)
    this.adobeAnalytics.track()
    this.selfServiceQAForm = this.formBuilder.group({
      securityAnswer: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validPattern),
      ]),
    })
    window.setTimeout(
      () => document.getElementById('securityAnswer').focus(),
      0,
    )
  }

  initiateTransmit() {
    this.transmit_retry_counter = 0
    const transmitModel = {
      ticket: this.session.transmitTicket,
      poId: this.session.poid,
      journeyName: Transmit.QA_JOURNEY_NAME,
    }

    const transmitParams = {
      auth1: 0,
      auth2: 0,
      devicePrint: this.session.devicePrint,
    }

    this.transmitSdkService.selfServiceFlowFlag = true

    this.transmitQAUiHandler.setUpQaUiHandler(this, this.transmitQaService)
    this.invokeTransmitQaJourney(transmitModel, transmitParams)
  }

  invokeTransmitQaJourney(transmitModel: any, transmitParams: any) {
    this.transmitQaService
      .invokeQAJourney(transmitModel, transmitParams)
      .then((result: TransmitAuthenticateResult) => {
        this.loggerService.info(
          `Journey complete and logged out: ${result}` +
            ' for Poid: ' +
            this.session.poid,
        )
        this.loading = false
        if (result === TransmitAuthenticateResult.RETRY) {
          if (this.transmit_retry_counter < 2) {
            this.transmit_retry_counter++
            this.invokeTransmitQaJourney(transmitModel, transmitParams)
          } else {
            this.loggerService.error(
              'Transmit Journey Retry Twice Completed and Failed in All for Poid: ' +
                this.session.poid,
            )
            this.errorHandling()
          }
        }
        if (result === TransmitAuthenticateResult.SUCCESSFUL) {
          this.loading = false
          const serviceResponse: ServiceResponse = {
            authAction: environment.SELF_SERVICE_OOB_TRANSMIT,
            state: environment.OUT_OF_BAND_TRANSMIT,
            transmitTicket: '',
          }
          this.processResponseService.proccess(serviceResponse)
        } else if (result === TransmitAuthenticateResult.LOCKOUT) {
          this.loggerService.error(
            'Failed to Invoke QA Journey due to Authenticator Locked state' +
              ' for Poid: ' +
              this.session.poid,
          )
          const serviceResponse: ServiceResponse = {
            authAction: environment.AA_QA_LOCKEDOUT,
            state: environment.USERNAME_AND_PASSWORD,
            transmitTicket: '',
          }
          if (this.transmitSubmitAnswer) {
            this.transmitEmailService
              .sendEmail(
                this.transmitEmailNotificationUrl,
                EmailTemplate.QA_LOCKED_TEMPLATE,
                Number(this.session.poid),
              )
              .subscribe({
                next: (data) => {
                  this.processResponseService.proccess(serviceResponse)
                },
                error: (error) => {
                  this.loggerService.error(
                    'Error occured while sending email to user: Status: ' +
                      error.status +
                      ' for Poid: ' +
                      this.session.poid,
                  )
                  this.errorHandling()
                },
              })
          }
          this.processResponseService.proccess(serviceResponse)
        } else {
          this.loggerService.error(
            'Failed to complete QA Journey' + ' for Poid: ' + this.session.poid,
          )
          this.errorHandling()
        }
      })
      .catch((err) => {
        this.loggerService.error(err + ' for Poid: ' + this.session.poid)
        this.errorHandling()
      })
  }

  errorHandling() {
    let serviceResponse: ServiceResponse

    if (this.errorMsgService.invalidMessage === 'answerExpired') {
      serviceResponse = {
        authAction: environment.AA_CHALLENGE_EXPIRED,
        state: environment.UNSUCCESSFULL,
      }
    } else {
      serviceResponse = {
        authAction: environment.GENERIC_ERROR,
        state: environment.UNSUCCESSFULL,
        transmitTicket: '',
      }
    }

    this.processResponseService.proccess(serviceResponse)
    this.loading = false
  }

  authUserSecurityQuestion() {
    this.clearMsgs()
    if (this.selfServiceQAForm.invalid) {
      this.selfServiceQAForm.controls.securityAnswer.reset()
      this.selfServiceQAForm.controls['securityAnswer'].markAsTouched()
      this.selfServiceQAForm.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      })
    } else {
      this.transmitSubmitAnswer = true
      //Trigger the click of the button here, so it will go the transmit auth session to validate the answer
      document.getElementById('continueBtn').click()
    }
  }

  cancel() {
    this.clearMsgs()
    this.logonService.cancel(this.cancelRestEndPoint).subscribe(
      (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
      (error) => {
        console.log(
          'Error occured while canceling from self service Q&A component: Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    )
  }

  clearMsgs(): void {
    this.errorMsgService.clearAll()
    this.invalidMessage = this.errorMsgService.invalidMessage
    this.warningBanner.setWarningBannerMessage(
      this.errorMsgService.invalidMessage,
    )
  }

  ngAfterViewChecked() {
    if (document.getElementById('continueBtn')) {
      this.setAriaAttributeToElement(
        document
          .getElementById('continueBtn')
          .getElementsByTagName('button')
          .item(0),
        'warningBannerError',
      )
    }
  }

  setAriaAttributeToElement(element, id) {
    if (element != null) {
      return element.setAttribute('aria-describedby', id)
    }
  }

  recoveryForError(error, validRecoveries, defaultRecovery, resolve) {
    if (
      defaultRecovery ===
      com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator
    ) {
      //this.showMyContainer = true;
      resolve(defaultRecovery)
    } else {
      alert('Action failure ' + error)
      resolve(defaultRecovery)
    }
  }

  hidePw = true
  inputType = this.hidePw ? 'password' : 'text'
  handleShowHideBtn(): void {
    this.hidePw = !this.hidePw
    this.inputType = this.hidePw ? 'password' : 'text'
  }
}
