import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from '@env'

@Injectable()
export class ViewService {
  private view: BehaviorSubject<string> = new BehaviorSubject<string>(
    environment.USERNAME_AND_PASSWORD,
  )
  private view$: Observable<string> = this.view.asObservable()
  private unloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  )
  private unloading$: Observable<boolean> = this.unloading.asObservable()
  private isRefreshFlag = true
  constructor() {}

  setView(view: string) {
    this.view.next(view)
  }

  getView(): Observable<string> {
    return this.view$
  }

  set isRefresh(flag: boolean) {
    this.isRefreshFlag = flag
  }

  get isRefresh(): boolean {
    return this.isRefreshFlag
  }

  setUnloading(flag: boolean) {
    this.unloading.next(flag)
  }

  isUnloading(): Observable<boolean> {
    return this.unloading$
  }
}
