import { AbstractControl, ValidatorFn, Validators } from '@angular/forms'

export function usernameOrPasswordStrValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.parent) {
      return null
    }

    const userPatternPassFound = Validators.pattern(
      new RegExp(/username|password/i),
    )(control)
    if (userPatternPassFound) {
      return null
    }
    return { identicalToUsernameOrPasswordStr: true }
  }
}
