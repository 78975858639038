import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { ContentService } from '@app/shared/services/contentService'
import { MatDialogRef } from '@angular/material/dialog'
import { environment } from '@env'
import { DialogComponent, VuiDialogConfig } from '@vanguard/vui/dialog'

@Component({
  selector: 'oob-terms-view',
  templateUrl: './oobTerms.component.html',
  styleUrls: ['./oobTerms.component.scss'],
  providers: [DialogComponent],
})
export class OobTermsComponent implements OnInit {
  @ViewChild('OOBTermsDialog', { static: false })
  private ooBTermsDialog: TemplateRef<any>
  content: any

  vuiDialogConfig: VuiDialogConfig = {
    dialogType: 'simple',
    backdropDismiss: true,
  }

  constructor(
    private contentService: ContentService,
    private readonly viewContainer: ViewContainerRef,
    public vuiDialog: DialogComponent,
  ) {}

  ngOnInit(): void {
    this.content = this.contentService.caasContent
  }

  bulletContent(number) {
    return this.content.Data.oobSecurityPage.content.oobTerms.oobTerms.tcDetails
      .tcDetails[environment.OOB_TC_BULLET + number]
  }

  openTermsLayer() {
    this.vuiDialog.openDialog(
      this.viewContainer,
      this.ooBTermsDialog,
      this.vuiDialogConfig,
    )
  }
}
