import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { ContentService } from '@app/shared/services/contentService'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { DialogComponent, VuiDialogConfig } from '@vanguard/vui/dialog'

@Component({
  selector: 'tc-view',
  templateUrl: './termsAndConditions.component.html',
  styleUrls: ['./termsAndConditions.component.scss'],
  providers: [DialogComponent],
})
export class TermsAndConditionsComponent implements OnInit {
  @ViewChild('termsAndConditionDialog', { static: false })
  private termsAndConditionDialog: TemplateRef<any>

  dialogConfig: VuiDialogConfig = {
    width: '50vw',
  }
  content: any
  tcContent: any
  @Input() checkedTC: boolean

  constructor(
    private contentService: ContentService,
    private readonly viewContainer: ViewContainerRef,
    public vuiDialog: DialogComponent,
  ) {}

  ngOnInit(): void {
    this.content = this.contentService.caasContent
    this.tcContent =
      this.contentService.caasContent.Data.termsAndConditionPage.content.hubTermsConditionsThirdRev.hubTermsConditionsThirdRev
  }

  openTermsAndConditionsLayer() {
    this.vuiDialog.openDialog(
      this.viewContainer,
      this.termsAndConditionDialog,
      this.dialogConfig,
    )
  }
}
