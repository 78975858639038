import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { TransmitSdkService } from '@app/shared/services/transmitCommonService/transmitSdk.service'
import { HttpClient } from '@angular/common/http'
import { environment as env } from '@env'

@Injectable({
  providedIn: 'root',
})
export class TransmitQAService {
  question: string
  state: any
  resolver: any
  authError: any
  serverPayload: string
  description: any

  constructor(
    private transmitService: TransmitSdkService,
    private httpClient: HttpClient,
  ) {}

  legacyLogon = this.isPrettyUrl()

  invokeQAJourney(transmitModel: any, transmitParams: any): Promise<any> {
    this.transmitService.isEnroll = false
    return this.transmitService.authenticate(
      transmitModel.ticket,
      transmitModel.isBoundDevice,
      transmitModel.poId,
      transmitModel.journeyName,
      transmitParams,
    )
  }

  validateQA(url: string, body: any): Observable<any> {
    return this.httpClient.post<any>(
      this.legacyLogon ? env.LEGACYLOGON + url : url,
      body,
    )
  }

  isPrettyUrl(): boolean {
    return window.location.origin.includes('institutional.vanguard.com')
  }
}
