// eslint-disable-next-line no-shadow
export enum EmailTemplate {
  OOB_LOCKED_TEMPLATE = 'INST_OOB_ACCOUNT_LOCKED',
  ACCOUNT_LOCKED_TEMPLATE = 'INST_ACCOUNT_LOCKED',
  QA_LOCKED_TEMPLATE = 'INST_QA_LOCKED',
  PASSWD_UPDATE_TEMPLATE = 'INST_PASSWORD_UPDATED',
  REGISTRATION_COMPLETE_TEMPLATE = 'INST_REGISTRATION_COMPLETE',
  SECURITY_QUESTION_UPDATE_TEMPLATE = 'INST_SECURITY_QUESTION',
  OOB_SERVICE_ESTABLISHED_TEMPLATE = 'INST_OOB_SERVICE',
  INST_USER_NAME_TEMPLATE = 'INST_USER_NAME',
}
