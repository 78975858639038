import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class TransmitEmailService {
  static sendEmail(url: string, body: { poId: string; emailTemplate: string }) {
    throw new Error('Method not implemented.')
  }

  constructor(private httpClient: HttpClient) {}

  sendEmail(url: string, emailTemplate: string, poId: number): Observable<any> {
    const payload = {
      emailTemplate: emailTemplate,
      poId: poId,
    }
    return this.httpClient.post<any>(url, payload)
  }
}
