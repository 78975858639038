import { Component, OnInit } from '@angular/core'
import { ContentService } from '@app/shared/services/contentService'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { checkCircleMd } from '@vanguard/vui/icons'

@Component({
  selector: 'warning-banner',
  templateUrl: './warningBanner.component.html',
  styleUrls: ['./warningBanner.component.scss'],
})
export class WarningBannerComponent implements OnInit {
  message: string
  content: any
  public readonly checkCircleMd = checkCircleMd

  constructor(
    private contentService: ContentService,
    private warningBanner: WarningBannerService,
  ) {}

  ngOnInit() {
    this.content = this.contentService.caasContent
    this.warningBanner
      .getWarningBannerMessage()
      .subscribe((appComponent) => (this.message = appComponent))
  }
}
