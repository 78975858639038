import { Content } from '../content/content'

export class ContentService {
  private content: Content = {}

  get caasContent(): any {
    return this.content.caasContent
  }

  set caasContent(caasContent: any) {
    this.content.caasContent = caasContent
  }
}
