import { Injectable } from '@angular/core'
import { environment } from '@env'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(private httpClient: HttpClient) {}

  legacyLogon = this.isPrettyUrl()

  info(message: string) {
    this.logServerSide('info', message)
  }

  warn(message: string) {
    this.logServerSide('warn', message)
  }

  error(message: string) {
    console.log('Error :-', message)
    this.logServerSide('error', message)
  }

  private logServerSide(level: string, msg: string) {
    const log = {
      errorLevel: level,
      msg: msg,
    }

    this.postLogServerSide(log)
  }

  private postLogServerSide(log) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    }

    this.httpClient
      .post(
        this.legacyLogon
          ? environment.LEGACYLOGON + environment.CLIENT_LOG
          : environment.CLIENT_LOG,
        log,
        httpOptions,
      )
      .subscribe({
        next: () => {},
        error: (err) => {
          console.log(err)
        },
      })
  }

  isPrettyUrl(): boolean {
    return window.location.origin.includes('institutional.vanguard.com')
  }
}
