import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core'
import { ContentService } from '@app/shared/services/contentService'
import { DialogComponent } from '@vanguard/vui/dialog'

@Component({
  selector: 'qa-tips-dialog',
  templateUrl: './qaTipsDialog.component.html',
  styleUrls: ['./qaTipsDialog.component.scss'],
})
export class QaTipsDialogCustomComponent implements OnInit {
  content: any

  constructor(
    private contentService: ContentService,
    public vuiDialog: DialogComponent,
  ) {}

  ngOnInit() {
    this.content = this.contentService.caasContent
  }

  closeDialog() {
    this.vuiDialog.closeDialog()
  }
}
