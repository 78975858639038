import { Injectable } from '@angular/core'
import {
  CommonUiHandlerService,
  TransmitUiHandler,
} from '../transmitCommonService/transmitCommonUiHandler'
import { TransmitLogonQAAuthSession } from '@app/shared/services/transmitLogonQA/transmitLogonQAAuthSession.service'
import { Router } from '@angular/router'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { SessionService } from '@app/shared/services/sessionService'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { TransmitValidateUserStatus } from '@app/models/TransmitConstants'
import { TransmitSdkService } from '@app/shared/services/transmitCommonService/transmitSdk.service'
import { ViewService } from '@app/shared/services/viewService'
import { environment } from '@env'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { ChallengeComponent } from '@app/challenge/challenge.component'
import { LoggerService } from '@app/shared/services/loggerService'

const sdk = XmSdk()
declare const com: any

@Injectable({
  providedIn: 'root',
})
export class TransmitLogonQAUiHandler {
  //Need a public variable  in order for the challenge component to be able to share the value of the remember me indicator
  public bound: boolean
  public userStatus: string
  public isResetQa: boolean
  jsonQuestion: string

  constructor(
    private readonly commonUiHandler: CommonUiHandlerService,
    private readonly router: Router,
    private readonly session: SessionService,
    private readonly bannerService: WarningBannerService,
    private readonly transmitSdkService: TransmitSdkService,
    private readonly viewService: ViewService,
    private errorMsgService: ErrorMsgService,
    private readonly loggerService: LoggerService,
  ) {}

  setUpQaUiHandler(qaComponent: ChallengeComponent, transmitQaService): void {
    this.commonUiHandler.getCommonUiHandler()

    TransmitUiHandler.prototype.createPlaceholderAuthSession = (
      placeholderName: string,
      placeholderType: string,
      title: string,
      username: string,
      authenticatorConfiguredData: string,
      serverPayload: string,
    ) => {
      qaComponent.loading = false
      document.getElementById('displayQA').style.display = 'inline'
      document.getElementById('global-header').style.display = 'inline'
      document.getElementById('global-footer').style.display = 'inline'
      document.getElementById('header-text').style.display = 'inline'
      return new TransmitLogonQAAuthSession(
        qaComponent,
        transmitQaService,
        placeholderName,
        placeholderType,
        title,
        username,
        authenticatorConfiguredData,
        serverPayload,
        this.session,
        this.bannerService,
        this.viewService,
        this.errorMsgService,
        this.transmitSdkService,
        this.jsonQuestion,
      )
    }

    TransmitUiHandler.prototype.selectAuthenticator = (
      options: any[],
      actionContext: any | null,
      clientContext: object | null,
    ) => {
      const qaAuth = options[0].getAuthenticator()
      const result =
        com.ts.mobile.sdk.AuthenticatorSelectionResult.createSelectionRequest(
          qaAuth,
        )
      return Promise.resolve(result)
    }

    TransmitUiHandler.prototype.processJsonData = (
      jsonData: any,
      actionContext: any,
      clientContext: any,
    ) => {
      return new Promise((resolve, reject) => {
        if (jsonData) {
          qaComponent.deviceId = jsonData.deviceId
          qaComponent.question = jsonData.questionText
          this.jsonQuestion = jsonData.questionText

          if (jsonData.status === TransmitValidateUserStatus.UNVERIFIED) {
            // unverified = reset questions
            if (
              jsonData.userStatus === TransmitValidateUserStatus.USER_STATUS_F
            ) {
              this.userStatus = jsonData.userStatus
              this.isResetQa = true
            } else {
              this.userStatus = jsonData.status
            }
            resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true))
          } else if (jsonData.status === TransmitValidateUserStatus.LOCKOUT) {
            resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(false))
            //redirect to logon page and set banner messsage to call administrator
            this.viewService.setView(environment.USERNAME_AND_PASSWORD)
            this.errorMsgService.invalidMessage = 'locked'
            document.getElementById('global-header').style.display = 'inline'
            document.getElementById('global-footer').style.display = 'inline'
            document.getElementById('header-text').style.display = 'inline'
          } else if (jsonData.status === TransmitValidateUserStatus.DELETED) {
            // users with status of "D" indicates brand new users who have not been registered yet (deleted/not enrolled)
            this.userStatus =
              jsonData.userStatus === TransmitValidateUserStatus.USER_STATUS_C
                ? jsonData.userStatus
                : TransmitValidateUserStatus.DELETED
            resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true))
          } else {
            resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true))
            qaComponent.loading = false
            this.userStatus = jsonData.userStatus
          }
        } else {
          resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(false))
        }
      })
    }

    TransmitUiHandler.prototype.handlePolicyRejection = async (
      title,
      text,
      buttonText,
      failureData,
      actionContext,
      clientContext,
    ) => {
      if (
        failureData &&
        failureData.reason &&
        failureData.reason.data &&
        failureData.reason.data.QALOCKED.includes('User is LOCKED') === true
      ) {
        this.loggerService.error(
          'user is locked' + ' for Poid: ' + this.session.poid,
        )
        const okResponseNumber = -1
        return Promise.resolve(
          com.ts.mobile.sdk.ConfirmationInput.create(okResponseNumber),
        )
      } else {
        this.loggerService.error(
          failureData + ' for Poid: ' + this.session.poid,
        )
        const okResponseNumber = -1
        return Promise.resolve(
          com.ts.mobile.sdk.ConfirmationInput.create(okResponseNumber),
        )
      }
    }

    TransmitUiHandler.prototype.createFormSession = (
      formId,
      payload: { any },
    ) => {
      // eslint-disable-next-line no-shadow
      function collectBindInfo(formId, payload) {
        this.startSession = function (clientContext, actionContext) {
          this.clientContext = clientContext
        }

        this.endSession = function () {}

        this.promiseFormInput = () => {
          return new Promise((resolve, reject) => {
            const payload1 = {
              type: 'dynamic_form',
              actions: {
                bound: String(TransmitLogonQAUiHandler.prototype.bound),
              },
            }
            resolve(
              com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
                Object.assign(payload1),
              ),
            )
          })
        }
        // eslint-disable-next-line no-shadow
        this.onContinue = function (payload) {}
        // eslint-disable-next-line no-shadow
        this.onError = function (payload) {}
      }
      return new collectBindInfo(formId, payload)
    }

    const qaUiHandler = new TransmitUiHandler()
    this.commonUiHandler.sdkSetUiHandler(qaUiHandler)
  }
}
