import { Injectable } from '@angular/core'
import { ServiceResponse } from '@app/models/ServiceResponse'
import { environment } from '@env'
import { ViewService } from '@app/shared/services/viewService'
import { WindowRefService } from '../window-ref'
import { ErrorMsgService } from '../errorMsgService'
import { SessionService } from '../sessionService'
import { OobChallengeService } from '../oobChallengeService'
import { CancelResponse } from '@app/models/CancelResponse'
import { RegionService } from '../regionService'
import { Logon } from '../logonService'
import { Router } from '@angular/router'
import { TransmitTicketService } from '../transmitCommonService/transmitTicket.service'
import { TransmitTicketPayload } from '@app/models/TransmitTicketPayload'
import { TransmitTicketResponse } from '@app/models/TransmitTicketResponse'
import {
  TRANSMIT_TICKET_URL,
  NOT_APPLICABLE,
  POLICY_ID,
  TICKET_APP_ID,
  JOURNEY_ID,
  ACCOUNT_ID,
  SERVICE_ID,
  LOGON_TRANSACTION_TYPE,
  OOB_CHALLENGE_TYPE,
  Transmit,
} from '@app/models/TransmitConstants'
import { LoggerService } from '@app/shared/services/loggerService'
import { response } from 'express'

@Injectable()
export class ProcessResponseService {
  cancelRestEndPoint: string
  private transmitTicketPayload: TransmitTicketPayload
  ticketResponse: TransmitTicketResponse
  isVieUser: boolean
  isNewUser = false
  isResetQA: boolean

  constructor(
    private viewService: ViewService,
    private errorMsgService: ErrorMsgService,
    private session: SessionService,
    private oobChallengeService: OobChallengeService,
    private regionService: RegionService,
    private logonService: Logon,
    private windowRefService: WindowRefService,
    private router: Router,
    private ticketService: TransmitTicketService,
    private readonly loggerService: LoggerService,
  ) {}

  proccess(resp: ServiceResponse) {
    switch (resp.state) {
      case environment.SUCCESSFULL:
        this.proccessSucessfulResponse(resp)
        this.errorMsgService.loading = false
        break
      case environment.USERNAME_AND_PASSWORD:
        this.processUsernameAndPwdResponse(resp)
        this.errorMsgService.loading = false
        break
      case environment.QUESTION_AND_ANSWER_TRANSMIT:
        this.session.transmitEnabled = true
        this.processQandAResponse(resp)
        this.errorMsgService.loading = false
        break
      case environment.OUT_OF_BAND_TRANSMIT:
        this.processOOBResponse(resp)
        this.errorMsgService.loading = false
        break
      case environment.UNSUCCESSFULL:
        this.proccessUnsucessfulResponse(resp)
        this.errorMsgService.loading = false
        break
      case environment.FORGOT_PASSWD:
      case environment.FORGOT_USERNAME:
        this.proccessForgotUserNamePasswordResponse(resp)
        this.errorMsgService.loading = false
        break
      case environment.SELF_SERVICE_QA:
        this.proccessSelfServiceQAResponse(resp)
        this.errorMsgService.loading = false
        break
      default:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        this.cancel()
        this.errorMsgService.loading = false
        break
    }
  }

  proccessForcedOobEnrollment(resp: ServiceResponse) {
    switch (resp.authAction) {
      case environment.OOB_ENROLL:
        this.errorMsgService.loading = false
        this.errorMsgService.popUpMsg = 'resendSuccess'
        this.errorMsgService.openOtpPopUp = true
        break
      case environment.MAX_AA_SESSIONS_REACHED:
        this.errorMsgService.loading = false
        this.errorMsgService.popUpMsg = 'maxSessions'
        this.errorMsgService.invalidMessage = 'maxSessions'
        this.errorMsgService.openOtpPopUp = false
        break
      default:
        this.errorMsgService.loading = false
        this.errorMsgService.popUpMsg = 'serviceFailure'
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        this.errorMsgService.openOtpPopUp = false
        break
    }
  }

  private cancel() {
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.session.clear()
    this.logonService.cancel(this.cancelRestEndPoint).subscribe(
      (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
        document.getElementById('global-header').style.display = 'inline'
        document.getElementById('global-footer').style.display = 'inline'
        document.getElementById('header-text').style.display = 'inline'
      },
      (error) => {
        this.loggerService.error(
          'Error occured while canceling from Response Service: Status: ' +
            error.status +
            ', error: ' +
            error.message +
            ' for Poid: ' +
            this.session.poid,
        )
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    )
  }

  proccessForgotUserNamePasswordResponse(resp: ServiceResponse) {
    switch (resp.authAction) {
      case environment.MISMATCH:
        this.errorMsgService.invalidMessage = 'mismatch'
        break
      case environment.GENERIC_ERROR:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        break
      default:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        break
    }
  }

  proccessSelfServiceQAResponse(resp: ServiceResponse) {
    switch (resp.authAction) {
      case environment.MISMATCH:
        this.errorMsgService.invalidMessage = 'mismatch'
        break
      case environment.GENERIC_ERROR:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        break
      case environment.AA_CHALLENGE_FAILED:
        this.errorMsgService.incorrectSecurityAnswer = true
        this.session.question = resp.challenge.question
        this.session.aaSessionAttributes = resp.aaSessionAttributes
        break
      case environment.AA_CHALLENGE_EXPIRED:
        this.errorMsgService.invalidMessage = 'answerExpired'
        this.cancel()
        break
      default:
        this.viewService.isRefresh = false
        this.session.selfService = true
        this.session.transmitEnabled = true
        this.session.phoneLast4Digits = resp.challenge?.phoneLast4Digits
        this.retrieveTicketForUser(
          resp.poid.toString(),
          resp.challenge.primaryPhoneMethod,
        )
        break
    }
  }

  private proccessSucessfulResponse(resp: ServiceResponse) {
    if (!(resp.OOBBoundDevice && resp.authAction === '')) {
      document.getElementById('global-header').style.display = 'inline'
      document.getElementById('global-footer').style.display = 'inline'
      document.getElementById('header-text').style.display = 'inline'
    }
    switch (resp.authAction) {
      case environment.USER_STATUS_C:
        this.viewService.setView(environment.REGISTRATION)
        this.isVieUser = resp.isVieUser // for VIE forms redirect
        break
      case environment.USER_STATUS_F:
        this.viewService.setView(environment.FORCED_PW)
        break
      case environment.AA_RESET:
        this.viewService.setView(environment.SECURITY_QA)
        this.isVieUser = resp.isVieUser // for VIE forms redirect
        break
      case environment.AA_ENROLL:
        this.viewService.setView(environment.SECURITY_QA)
        break
      default:
        this.viewService.setUnloading(true)
        if (resp.transmitRedirectUrl && resp.transmitRedirectUrl !== '') {
          this.loggerService.info(
            'Transmit User poid: ' +
              this.session.poid +
              ' Successfully Transferred to Url :' +
              resp.transmitRedirectUrl,
          )
          this.windowRefService.nativeWindow.location.href =
            resp.transmitRedirectUrl
        } else {
          this.loggerService.info(
            'Non Transmit User poid ' +
              this.session.poid +
              ' Successfully Transferred to Url :' +
              resp.redirectUrl,
          )
          this.windowRefService.nativeWindow.location.href = resp.redirectUrl
        }
        this.errorMsgService.loading = true
        break
    }
  }

  private proccessUnsucessfulResponse(resp: ServiceResponse) {
    switch (resp.authAction) {
      case environment.PASSWORD_LOCKEDOUT:
        this.errorMsgService.invalidMessage = 'locked'
        this.cancel()
        break
      case environment.INVALID_LOGON_CREDENTIALS:
        this.errorMsgService.invalidMessage = 'invalidCred'
        this.errorMsgService.clearTempPasswordField = true
        break
      case environment.MAX_AA_SESSIONS_REACHED:
        this.errorMsgService.invalidMessage = 'maxSessions'
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
        break
      case environment.GENERIC_ERROR:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
        break
      case environment.AA_CHALLENGE_EXPIRED:
        this.errorMsgService.invalidMessage = 'answerExpired'
        this.cancel()
        break
      default:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        this.errorMsgService.loading = false
        this.errorMsgService.clearForm = true
        break
    }
  }

  private processUsernameAndPwdResponse(resp: ServiceResponse) {
    switch (resp.authAction) {
      case environment.AA_QA_LOCKEDOUT:
      case environment.AA_OOB_LOCKEDOUT:
        this.errorMsgService.invalidMessage = 'locked'
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
        break
      case environment.PASSWORD_LOCKEDOUT:
        this.errorMsgService.invalidMessage = 'locked'
        break
      case environment.INVALID_LOGON_CREDENTIALS:
        this.errorMsgService.invalidMessage = 'invalidAttemptLogon'
        break
      case environment.GENERIC_ERROR:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        break
      case environment.CALL_IN:
        this.errorMsgService.invalidMessage = 'callIn'
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
        this.router.navigate(['/'])
        break
      case environment.FORGOT_PASWD_SUCCESS:
        this.errorMsgService.invalidMessage = 'tempPwdSuccess'
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
        break
      case environment.FORGOT_USER_SUCCESS:
        this.errorMsgService.invalidMessage = 'userNameSuccess'
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
        break
      case environment.AA_CHALLENGE_EXPIRED:
        this.errorMsgService.invalidMessage = 'answerExpired'
        this.cancel()
        break
      default:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        break
    }
  }

  private processQandAResponse(resp: ServiceResponse) {
    switch (resp.authAction) {
      case environment.AA_CHALLENGE_FAILED:
        this.errorMsgService.incorrectSecurityAnswer = true
        this.session.question = resp.challenge.question
        break
      case environment.GENERIC_ERROR:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        break
      case environment.AA_CHALLENGE_EXPIRED:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        this.cancel()
        break
      default: // for VIE forms redirect
        //The security question will come from the call to transmit which is populated in the UI handler
        this.session.transmitTicket = resp.transmitTicket
        this.session.auth1 = resp.auth1
        this.session.auth2 = resp.auth2
        this.session.poid = resp.poid.toString()
        this.loggerService.info(
          'Transmit User poid ' +
            this.session.poid +
            ' is being challenged with a Question and Answer, Ticket# ' +
            this.session.transmitTicket,
        )
        this.session.oobBypass = resp.isOOBBypassClient
        this.session.redirectUrl = resp.transmitRedirectUrl
        this.isVieUser = resp.isVieUser
        this.viewService.setView(environment.QUESTION_AND_ANSWER)
        break
    }
  }

  private processOOBResponse(resp: ServiceResponse) {
    switch (resp.authAction) {
      case environment.OOB_ENROLL:
        this.oobChallengeService.transmitEnabledUser = true
        this.viewService.setView(environment.OUT_OF_BAND)
        break
      case environment.CHALLENGE_OTP_TRANSMIT:
        this.oobChallengeService.oobPhone = resp.challenge.phoneLast4Digits
        this.oobChallengeService.oobBound = resp.OOBBoundDevice
        this.oobChallengeService.logOnResponse = resp
        this.oobChallengeService.transmitEnabledUser = true
        this.session.poid = resp.poid.toString()
        this.loggerService.info(
          'Transmit User poid ' +
            this.session.poid +
            ' is being challenged with a security code, Ticket# ' +
            resp.transmitTicket,
        )
        this.viewService.setView(environment.SECURITY_CODE)
        break
      case environment.SELF_SERVICE_OOB_TRANSMIT:
        this.oobChallengeService.transmitEnabledUser = true
        this.oobChallengeService.oobPhone = this.session.phoneLast4Digits
        this.oobChallengeService.logOnResponse = resp
        this.session.selfService = true
        this.viewService.setView(environment.SECURITY_CODE)
        break
      case environment.AA_CHALLENGE_EXPIRED:
        this.errorMsgService.invalidMessage = 'invalidCode'
        break
      case environment.AA_CHALLENGE_FAILED:
        this.errorMsgService.invalidMessage = 'invalidCode'
        break
      case environment.AA_OTP_VALUE_EXPIRED:
        this.errorMsgService.invalidMessage = 'invalidCode'
        break
      case environment.OOB_EXPIRED_PASSCODE:
        this.errorMsgService.invalidOTPMessage = 'invalidCode'
        break
      case environment.OOB_INVALID_PASSCODE:
        this.errorMsgService.invalidOTPMessage = 'invalidCode'
        break
      case environment.TOKEN_NOT_VERIFIED:
      default:
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        break
    }
  }

  retrieveTicketForUser(userPoid: string, deliveryMethod: string) {
    this.transmitTicketPayload = {
      user_id: userPoid,
      policy_id: POLICY_ID,
      app_id: TICKET_APP_ID,
      car: false,
      extendedTimeOutIndicator: false,
      oobChallengeType: OOB_CHALLENGE_TYPE,
      bound: 'false',
      channel: deliveryMethod,
      accountId: ACCOUNT_ID,
      serviceId: SERVICE_ID,
      message: '12345',
      transactionType: LOGON_TRANSACTION_TYPE,
      userName: userPoid,
      userPrimaryPhoneNumber: '',
      contactNumber: '',
      journeyId: Transmit.SELF_SERVICE,
      isEnrollment: false,
      poid: userPoid,
    }
    const ticketServiceResponse = this.ticketService.getTransmitTicket(
      TRANSMIT_TICKET_URL,
      this.transmitTicketPayload,
    )
    ticketServiceResponse.subscribe(
      (data) => {
        this.session.transmitTicket = data.transmitTicket
        this.session.boundDevices = data.boundDevices
        this.session.poid = userPoid
        this.viewService.setView(environment.SELF_SERVICE_QA)
        this.router.navigate(['/'])
      },
      (error) => {
        this.loggerService.error(
          '******Error occured while retrieving transmit ticket for Poid: ' +
            userPoid +
            ', Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        this.ticketResponse = {
          transmitTicket: '',
          boundDevices: 0,
        }
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
      },
    )
  }
}
