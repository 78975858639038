import { NgModule } from '@angular/core'
import { WindowRefService } from '../../services/window-ref/window-ref.service'

@NgModule({
  imports: [],
  declarations: [],
  providers: [WindowRefService],
  exports: [],
})
export class WindowRefModule {}
