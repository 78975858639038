import { Component, OnInit, AfterViewChecked } from '@angular/core'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { environment } from '@env'
import { AdobeAnalyticsService } from '../shared/services/adobe-analytics/adobe-analytics.service'
import { ContentService } from '@app/shared/services/contentService'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { CancelResponse } from '@app/models/CancelResponse'
import { Logon } from '@app/shared/services/logonService'
import { RegionService } from '@app/shared/services/regionService'
import { Router } from '@angular/router'
import { SelfService } from '@app/shared/services/selfService/selfService.service'
import { SelfServicePayload } from '@app/models/SelfServicePayload'
import { ServiceResponse } from '@app/models/ServiceResponse'
import { ProcessResponseService } from '@app/shared/services/processResponseService'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { GatekeeperService } from '@app/shared/services/gatekeeperService'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { AdobeLaunchConfigurationService } from '@app/shared/services/adobe-launch-configuration/adobeLaunchConfiguration.service'
import { LoggerService } from '@app/shared/services/loggerService'

@Component({
  selector: 'self-service-view',
  templateUrl: './selfService.component.html',
  styleUrls: ['./selfService.component.scss'],
})
export class SelfServiceComponent implements OnInit, AfterViewChecked {
  content: any
  loading: boolean
  selfServiceUsernameForm: FormGroup
  selfServicePasswordForm: FormGroup

  cancelRestEndPoint: string
  private selfServicePayload: SelfServicePayload
  private serviceResponse: ServiceResponse
  invalidMessage = ''
  pwdLookUpUrl = ''
  userNameLookUpUrl = ''
  routerUrl: string
  isMpmSelfService = true
  checkedMmpUser = false
  planNumber = ''
  firstNameError = 'firstNameError'
  lastNameError = 'lastNameError'
  emailError = 'emailError'
  userNameError = 'userNameError'
  planNumberError = 'planNumberError'
  firstName: string
  lastName: string
  email: string
  userName: string
  mpmUserCheckbox: string
  activeTab: string

  constructor(
    private headerText: HeaderTextService,
    private adobeAnalytics: AdobeAnalyticsService,
    private contentService: ContentService,
    private warningBanner: WarningBannerService,
    private fb: FormBuilder,
    private logonService: Logon,
    private regionService: RegionService,
    private router: Router,
    private selfService: SelfService,
    private processResponseService: ProcessResponseService,
    private gatekeeperService: GatekeeperService,
    private errorMsgService: ErrorMsgService,
    private readonly adobeLaunchConfigurationService: AdobeLaunchConfigurationService,
    private readonly loggerService: LoggerService,
  ) {}

  ngOnInit(): void {
    this.clearMsgs()
    this.loading = false
    const data = {
      pageId: environment.FORGOT_PWD_ADOBE_PAGEID,
    }
    this.content = this.contentService.caasContent
    this.headerText.setComponent(
      this.content.Data.selfServicePage.content.titleId,
    )
    this.headerText.setShowHeadText(false)
    this.routerUrl = this.router.url
    this.adobeAnalytics.updateData(data)
    this.adobeAnalytics.track()
    this.checkSelfService()
    this.pwdLookUpUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_REST_BASE +
      environment.LOGON_REST_PATH +
      environment.PASSWORD_LOOKUP_REST_ENDPOINT
    this.userNameLookUpUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_REST_BASE +
      environment.LOGON_REST_PATH +
      environment.USERNAME_LOOKUP_REST_ENDPOINT
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.selfServicePasswordForm = this.fb.group({
      fName: new FormControl('', [
        Validators.required,
        Validators.pattern(environment.NAME_REGEX),
      ]),
      lName: new FormControl('', [
        Validators.required,
        Validators.pattern(environment.NAME_REGEX),
      ]),
      email2: new FormControl('', [Validators.required, Validators.email]),
      userName: new FormControl('', [
        Validators.required,
        Validators.pattern(environment.USERNAME_REGEX),
      ]),
    })
    this.selfServiceUsernameForm = this.fb.group({
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(environment.NAME_REGEX),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(environment.NAME_REGEX),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      mpmUserCheckbox: new FormControl('', [Validators.nullValidator]),
      planNumber: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.pattern(environment.NUMBER_REGEX),
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    })

    if (this.router.url === environment.FORGOT_USERNAME_ROUTE) {
      this.activeTab = 'forgotUserNameTab'
      window.setTimeout(() => document.getElementById('firstName').focus(), 0)
      this.adobeLaunchConfigurationService.trackPageLoad(
        environment.SELF_SERVICE_QA,
      )
    } else if (this.router.url === environment.FORGOT_PASSWORD_ROUTE) {
      this.activeTab = 'forgotPasswordTab'
      window.setTimeout(() => document.getElementById('fName').focus(), 0)
      this.adobeLaunchConfigurationService.trackPageLoad(
        environment.SELF_SERVICE_PASSWD,
      )
    }

    //The title is the same now for both tabs
    this.headerText.setHeader(
      this.content.Data.selfServicePage.content.resetCredentialsForm
        .resetCredentialsForm.pageHeader,
    )
  }

  ngAfterViewChecked() {
    /* istanbul ignore else*/
    if (document.getElementById('continueBtn')) {
      document
        .getElementById('continueBtn')
        .getElementsByTagName('button')
        .item(0)
        .setAttribute('aria-describedby', 'warningBannerError')
    }
  }

  cancel() {
    this.clearMsgs()
    this.logonService.cancel(this.cancelRestEndPoint).subscribe(
      (data: CancelResponse) => {
        this.router.navigateByUrl(environment.HOME_URL)
      },
      (error) => {
        this.loggerService.error(
          'Error occured while canceling from self service component: Status: ' +
            error.status +
            ', error: ' +
            error.message +
            ' for email: ' +
            this.email,
        )
        this.router.navigateByUrl(environment.HOME_URL)
      },
    )
  }

  passwordLookUp(): void {
    if (this.selfServicePasswordForm.valid) {
      this.clearMsgs()
      this.selfServicePayload = {
        firstName: this.selfServicePasswordForm.value.fName.trim(),
        lastName: this.selfServicePasswordForm.value.lName.trim(),
        email: this.selfServicePasswordForm.value.email2.trim(),
        userName: this.selfServicePasswordForm.value.userName
          .trim()
          .toLowerCase(),
      }
      this.loading = true
      this.selfService
        .lookUp(this.pwdLookUpUrl, this.selfServicePayload)
        .subscribe(
          (data: ServiceResponse) => {
            this.processResponseService.proccess(data)
            this.loading = this.errorMsgService.loading
            this.checkMsgs()
          },
          (error) => {
            this.loggerService.error(
              'Error occured while doing look up for forgot password: Status: ' +
                error.status +
                ', error: ' +
                error.message +
                ' for email: ' +
                this.selfServicePayload.email,
            )
            this.invalidMessage = 'serviceFailureSelfService'
            this.warningBanner.setWarningBannerMessage(
              'serviceFailureSelfService',
            )
            this.loading = false
            this.resetForm(this.selfServicePasswordForm)
          },
        )
    } else {
      this.selfServicePasswordForm.markAllAsTouched()
      this.selfServicePasswordForm.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      })
    }
  }

  userNameLookUp(): void {
    if (this.selfServiceUsernameForm.valid) {
      this.clearMsgs()
      this.selfServicePayload = {
        firstName: this.selfServiceUsernameForm.value.firstName.trim(),
        lastName: this.selfServiceUsernameForm.value.lastName.trim(),
        email: this.selfServiceUsernameForm.value.email.trim(),
      }
      if (!!this.selfServiceUsernameForm.value.planNumber) {
        this.selfServicePayload.planNumber =
          this.selfServiceUsernameForm.value.planNumber.trim()
      }
      this.loading = true
      this.selfService
        .lookUp(this.userNameLookUpUrl, this.selfServicePayload)
        .subscribe(
          (data: ServiceResponse) => {
            this.processResponseService.proccess(data)
            this.loading = this.errorMsgService.loading
            this.checkMsgs()
          },
          (error) => {
            this.loggerService.error(
              'Error occured while doing look up for forgot username: Status: ' +
                error.status +
                ', error: ' +
                error.message +
                ' for email: ' +
                this.selfServicePayload.email,
            )

            this.invalidMessage = 'serviceFailureSelfService'
            this.warningBanner.setWarningBannerMessage(
              'serviceFailureSelfService',
            )
            this.loading = false
            this.resetForm(this.selfServiceUsernameForm)
            this.checkedMmpUser = false
          },
        )
    } else {
      this.selfServiceUsernameForm.markAllAsTouched()
      this.selfServiceUsernameForm.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      })
    }
  }

  clearMsgs(): void {
    this.errorMsgService.clearAll()
    this.invalidMessage = this.errorMsgService.invalidMessage
    this.warningBanner.setWarningBannerMessage(
      this.errorMsgService.invalidMessage,
    )
  }

  checkMsgs(): void {
    this.invalidMessage = this.errorMsgService.invalidMessage
    this.warningBanner.setWarningBannerMessage(
      this.errorMsgService.invalidMessage,
    )
  }

  checkSelfService() {
    const featureId: string = environment.MPM_SELFSERVICE
    this.gatekeeperService.getToggle(featureId).subscribe(
      (data: boolean) => {
        if (data === true) {
          this.isMpmSelfService = true
        } else {
          this.isMpmSelfService = false
        }
      },
      (error) => {
        this.loggerService.error(
          'Error occured while retrieving gatekeeper service: Status: ' +
            error.status +
            ', error: ' +
            error.message +
            ' for email: ' +
            this.email,
        )
      },
    )
  }

  onCheckBoxStateChanged(event) {
    if (event) {
      if (!event.isChecked) {
        this.checkedMmpUser = false
        this.selfServiceUsernameForm.get('planNumber')?.disable()
      } else {
        this.checkedMmpUser = true
        this.selfServiceUsernameForm.get('planNumber')?.enable()
      }
      this.planNumber = ''
    }
  }

  onTabChange(event) {
    this.clearMsgs()
    if (event) {
      if (event === 'forgotUserNameTab') {
        this.resetForm(this.selfServicePasswordForm)
        window.setTimeout(
          () => document.getElementById('firstName').focus(),
          300,
        )
      } else if (event === 'forgotPasswordTab') {
        this.resetForm(this.selfServiceUsernameForm)
        this.checkedMmpUser = false
        window.setTimeout(() => document.getElementById('fName').focus(), 300)
      }
    }
  }

  resetForm(form: FormGroup) {
    form.reset()

    Object.keys(form.controls).forEach((key) => {
      form.get(key).reset()
    })
  }
}
