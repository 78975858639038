import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { ForcedOobEnrollmentComponent } from '@app/forcedOobEnrollment/forcedOobEnrollment.component'
import { TransmitEnrollOtpAuthSession } from './transmitEnrollOtpAuthSession.service'
import { TransmitValidateUserStatus } from '@app/models/TransmitConstants'
import { TransmitOtpService } from '@app/shared/services/transmitOtpService/transmitOtp.service'

const sdk = XmSdk()
declare const com: any

export function MyUIHandler() {
  XmSdk.call(this)
}

export function setUiHandler(
  forcedOOBComponent?: ForcedOobEnrollmentComponent,
) {
  MyUIHandler.prototype.constructor = MyUIHandler

  MyUIHandler.prototype.controlFlowStarting = function controlFlowStarting(
    clientContext,
  ) {}

  MyUIHandler.prototype.controlFlowCancelled = function controlFlowCancelled(
    clientContext,
  ) {}

  MyUIHandler.prototype.controlFlowEnded = function controlFlowEnded(
    error,
    clientContext,
  ) {}

  MyUIHandler.prototype.controlFlowActionStarting =
    function controlFlowActionStarting(policyAction, clientContext) {}

  MyUIHandler.prototype.controlFlowActionEnded =
    function controlFlowActionEnded(error, actionContext, clientContext) {}

  MyUIHandler.prototype.handlePolicyRejection = function handlePolicyRejection(
    title,
    text,
    buttonText,
    failureData,
    actionContext,
    clientContext,
  ) {
    return Promise.resolve(com.ts.mobile.sdk.ConfirmationInput.create(-1))
  }

  MyUIHandler.prototype.endActivityIndicator = function endActivityIndicator(
    clientContext,
  ) {}

  MyUIHandler.prototype.handlePolicyRedirect = (
    redirectType,
    policyId,
    userId,
    additionalParameters,
    clientContext,
  ) => {
    return new Promise((resolve) => {
      resolve(
        com.ts.mobile.sdk.RedirectInput.create(
          com.ts.mobile.sdk.RedirectResponseType.RedirectToPolicy,
        ),
      )
    })
  }

  MyUIHandler.prototype.startActivityIndicator = (actionContext) => {
    const loader = document.createElement('div')
    loader.style.display = 'none'
  }

  MyUIHandler.prototype.getInformationResponse = createOkResponse

  MyUIHandler.prototype.selectAuthenticatorFallbackAction = (
    validOptions,
    fallbackAuth,
    session,
    actionContext,
    clientContext,
  ) => {
    return new Promise((resolve, reject) => {
      resolve(validOptions[0])
    })
  }

  MyUIHandler.prototype.createOtpAuthSession = (
    title,
    username,
    possibleTargets,
    initialTarget,
  ) => {
    //do not display the security code page until the sdk shifts the control to the createotpauthsession. until then, keep spinning
    // forcedOOBComponent.displayOob = true
    // forcedOOBComponent.loading = false
    // document.getElementById('global-header').style.display = 'inline'
    // document.getElementById('global-footer').style.display = 'inline'
    // document.getElementById('header-text').style.display = 'inline'
    const otpService = forcedOOBComponent.transmitOtpservice
    if (
      forcedOOBComponent.checkedTC &&
      forcedOOBComponent.oobOtpChallengeForm.valid
    ) {
      forcedOOBComponent.openModalDialog()
    }
    return new TransmitEnrollOtpAuthSession(forcedOOBComponent, otpService)
  }

  MyUIHandler.prototype.createFormSession = (formId, payload: { any }) => {
    function collectBindInfo(formId, payload) {
      this.startSession = function (clientContext, actionContext) {
        this.clientContext = clientContext
      }

      this.endSession = function () {}

      this.promiseFormInput = () => {
        return new Promise((resolve, reject) => {
          const payload1 = {
            type: 'dynamic_form',
            actions: {
              phone: forcedOOBComponent.phone,
              channel: forcedOOBComponent.channel,
            },
          }
          resolve(
            com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
              Object.assign(payload1),
            ),
          )
        })
      }
      // eslint-disable-next-line no-shadow
      this.onContinue = function (payload) {}
      // eslint-disable-next-line no-shadow
      this.onError = function (payload) {}
    }
    return new collectBindInfo(formId, payload)
  }

  const myHandler = new MyUIHandler()
  sdk.setUiHandler(myHandler)
}

export function createOkResponse() {
  return new Promise((resolve, reject) => {
    const okResponseNumber = -1
    resolve(com.ts.mobile.sdk.ConfirmationInput.create(okResponseNumber))
  })
}
