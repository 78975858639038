import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class XForwardedProtoInterceptor implements HttpInterceptor {
  private readonly X_FORWARDED_PROTO_HEADER_NAME: string = 'X-Forwarded-Proto'
  private readonly X_FORWARDED_PROTO_HEADER_VALUE: string = 'https'
  private readonly X_FORWARDED_PROTO_HTTP_METHODS: string[] = ['POST']

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.X_FORWARDED_PROTO_HTTP_METHODS.indexOf(httpRequest.method) >= 0) {
      httpRequest = httpRequest.clone({
        headers: httpRequest.headers.set(
          this.X_FORWARDED_PROTO_HEADER_NAME,
          this.X_FORWARDED_PROTO_HEADER_VALUE,
        ),
      })
    }
    return next.handle(httpRequest)
  }
}
