import {
  OnInit,
  Component,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
} from '@angular/core'
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { SessionService } from '@app/shared/services/sessionService'
import { SecurityQNAService } from '@app/shared/services/securityQNAService/securityQNA.service'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'
import { ContentService } from '@app/shared/services/contentService'
import { AdobeAnalyticsService } from '@app/shared/services/adobe-analytics/adobe-analytics.service'
import { environment } from '@env'
import { find } from 'rxjs/operators'
import { from } from 'rxjs'
import { confirmationValidator } from '@app/shared/validators/confirmationValidator'
import { SecurityQuestionsResponse } from '@app/models/SecurityQuestionsResponse'
import { RegionService } from '@app/shared/services/regionService'
import { SecurityAnswersPayload } from '@app/models/SecurityAnswersPayload'
import { ProcessResponseService } from '@app/shared/services/processResponseService/processResponse.service'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { DialogComponent, VuiDialogConfig } from '@vanguard/vui/dialog'
import { TransmitTicketPayload } from '@app/models/TransmitTicketPayload'
import { CancelResponse } from '@app/models/CancelResponse'
import { ViewService } from '@app/shared/services/viewService'
import {
  ENROLL_TRANSACTION_TYPE,
  Transmit,
  TRANSMIT_TICKET_URL,
} from '@app/models/TransmitConstants'

import { TransmitTicketResponse } from '@app/models/TransmitTicketResponse'
import { TransmitTicketHelperService } from '@app/shared/services/transmitCommonService/transmitTicketHelper.service'
import { TransmitTicketService } from '@app/shared/services/transmitCommonService/transmitTicket.service'
import { CommonUIhandler } from '@vanguard/transmit/src/commonUIHandler'
import { GetQuestions } from '@vanguard/transmit/src/getQuestions'
import { ReturnToken } from '@vanguard/transmit/src/returnToken'
import { TransmitJWTValidationService } from '@app/shared/services/transmitCommonService/transmitJWTValidation.service'
import { TransmitJWTPayLoad } from '@app/models/TransmitJWTPayLoad'
import * as Jose from 'jose'
import { EmailTemplate } from '@app/models/EmailTemplate'
import { TransmitEmailService } from '@app/shared/services/transmitEmailService/transmitEmail.service'
import { sameAnswerValidator } from '@app/shared/validators/sameAnswerValidator'
import { sameUsernameValidator } from '@app/shared/validators/sameUsernameValidator'
import { ServiceResponse } from '@app/models/ServiceResponse'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { Logon } from '@app/shared/services/logonService'
import { ValidatorService } from '@app/shared/services/validatorService'
import { LoggerService } from '@app/shared/services/loggerService'
import { SelectState } from '@vg-constellation/angular-15/select'

const sdk = XmSdk()

@Component({
  selector: 'security-qa-view',
  templateUrl: './securityQA.component.html',
  styleUrls: ['./securityQA.component.scss'],
  providers: [DialogComponent],
})
export class SecurityQAComponent implements OnInit {
  @ViewChild('tipsContentDialog', { static: false })
  private tipsContentDialog: TemplateRef<any>
  @ViewChild('cancelDialog', { static: false })
  private cancelDialog: TemplateRef<any>
  vuiDialogConfig: VuiDialogConfig = {
    dialogType: 'simple',
    backdropDismiss: true,
  }
  content: any
  userName: string
  disableForm = false
  submitBtnDisabled = false
  securityQuestions: SecurityQuestionsResponse['questionGroup']
  selected1: string
  selected2: string
  selected3: string
  answerId1: string
  answerId2: string
  answerId3: string
  securityAnswersPayload: SecurityAnswersPayload
  private validPattern = /^[\w\s]*$/
  cancelRestEndPoint: string
  registerServiceResponse: any
  dialogRef: MatDialogRef<any, any>
  invalidMessage = ''
  loading = false
  securityQuestionsForm: FormControl[] = [
    new FormControl(null, Validators.required),
    new FormControl(null, Validators.required),
    new FormControl(null, Validators.required),
  ]
  securityQuestionsForm1 = new UntypedFormControl('', [Validators.required])
  securityQuestionsForm2 = new UntypedFormControl('', [Validators.required])
  securityQuestionsForm3 = new UntypedFormControl('', [Validators.required])
  securityQuestions1 = []
  securityQuestions2 = []
  securityQuestions3 = []
  secAnswer1ErrorMsg = ''
  secAnswer2ErrorMsg = ''
  secAnswer3ErrorMsg = ''
  secReEnter1ErrorMsg = ''
  secReEnter2ErrorMsg = ''
  secReEnter3ErrorMsg = ''
  transmitEnabledUser = false
  transmitTicketPayload: TransmitTicketPayload
  ticketResponse: TransmitTicketResponse
  enrollmentSuccess = false
  transmitEmailNotificationUrl: string
  typeOfUser: boolean
  private dialogOpen = false

  constructor(
    private headerText: HeaderTextService,
    private session: SessionService,
    private securityQNAService: SecurityQNAService,
    private warningBanner: WarningBannerService,
    private contentService: ContentService,
    private adobeAnalytics: AdobeAnalyticsService,
    private regionService: RegionService,
    private processResponseService: ProcessResponseService,
    private errorMsgService: ErrorMsgService,
    private viewContainer: ViewContainerRef,
    public vuiDialog: DialogComponent,
    private formBuilder: FormBuilder,
    private ticketHelperService: TransmitTicketHelperService,
    private ticketService: TransmitTicketService,
    private readonly transmitJWTValidationService: TransmitJWTValidationService,
    private readonly logonService: Logon,
    private readonly viewService: ViewService,
    private transmitEmailService: TransmitEmailService,
    private validatorService: ValidatorService,
    private readonly loggerService: LoggerService,
  ) {}

  securityQAForm = this.formBuilder.group({
    secAnswer1: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(this.validPattern),
      sameUsernameValidator(this.session.userName), //this validator is used to check for username in answers
      sameAnswerValidator('secAnswer2', 'secAnswer3'), // this validator is used to check for duplicate answers
    ]),
    secReEnter1: new FormControl('', [
      Validators.required,
      confirmationValidator('secAnswer1'),
    ]),
    secAnswer2: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(this.validPattern),
      sameUsernameValidator(this.session.userName),
      sameAnswerValidator('secAnswer1', 'secAnswer3'),
    ]),
    secReEnter2: new FormControl('', [
      Validators.required,
      confirmationValidator('secAnswer2'),
    ]),
    secAnswer3: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern(this.validPattern),
      sameUsernameValidator(this.session.userName),
      sameAnswerValidator('secAnswer1', 'secAnswer2'),
    ]),
    secReEnter3: new FormControl('', [
      Validators.required,
      confirmationValidator('secAnswer3'),
    ]),
    securityQuestionsForm: [this.securityQuestionsForm, Validators.required],
    securityQuestionsForm1: this.securityQuestionsForm1,
    securityQuestionsForm2: this.securityQuestionsForm2,
    securityQuestionsForm3: this.securityQuestionsForm3,
  })

  ngOnInit(): void {
    const data = {
      section: environment.LOGON_ADOBE_SECTION,
      subSection: environment.LOGON_ADOBE_SUBSECTION,
      pageId: environment.SECURITY_QA_ADOBE_PAGEID,
    }
    this.content = this.contentService.caasContent
    this.headerText.setComponent(
      this.content.Data.registrationPage.content.titleIdSecurityQA,
    )
    this.headerText.setHeader(
      this.content.Data.registrationPage.content.stepContainer.stepContainer
        .stepTwo,
    )
    this.headerText.setShowHeadText(false)
    this.userName = this.session.userName
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.transmitEmailNotificationUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE +
      environment.TRANSMIT_EMAIL_NOTIFICATION_ENDPOINT
    this.adobeAnalytics.updateData(data)
    this.adobeAnalytics.track()
    this.transmitEnabledUser = this.session.transmitEnabled
    this.retrieveTicketForUser()

    window.setTimeout(
      () => document.getElementById('tipsContentLink').focus(),
      0,
    )
  }

  retrieveTicketForUser() {
    // we do not need phone number, channel, oobChallengeType for QA enrollment, but need to pass in some values
    // username = username from login, not poid for qa enrollment
    this.transmitTicketPayload =
      this.ticketHelperService.buildTransmitTicketPayload(
        'SMS_TEXT',
        ENROLL_TRANSACTION_TYPE,
        '1111111111',
        true,
        this.session.userName,
        this.processResponseService.isNewUser //Only change the journey used for the ticket for reset & enroll questions
          ? Transmit.QA_ENROLLMENT
          : Transmit.RESET_QUESTIONS,
      )

    this.loggerService.info(
      'Retrieving the ticket for QA Enrollment journey. Poid: ' +
        this.session.poid,
    )
    const ticketServiceResponse = this.ticketService.getTransmitTicket(
      TRANSMIT_TICKET_URL,
      this.transmitTicketPayload,
    )

    ticketServiceResponse.subscribe({
      next: (data: TransmitTicketResponse) => {
        this.session.transmitTicket = data.transmitTicket
        this.session.boundDevices = data.boundDevices
        this.loggerService.info(
          'Got the ticket for QA enrollment journey: ' +
            data.transmitTicket +
            ' POID : ' +
            this.session.poid,
        )
        localStorage.clear()
        this.getSecurityQNA()
      },
      error: (error) => {
        this.loggerService.error(
          '******Error occured while retrieving transmit ticket for QA enrollment POID : ' +
            this.session.poid +
            ' Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        this.ticketResponse = {
          transmitTicket: '',
          boundDevices: 0,
        }
        this.loading = false
        this.errorMsgService.invalidMessage = 'serviceFailureLogon'
        this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
      },
    })
  }

  retrieveQuestions = async () => {
    await CommonUIhandler.prototype.setUIHandler(
      environment[this.regionService.envType][this.regionService.userType]
        .TRANSMIT_URL,
      Transmit.ENROLLMENT_APP_ID,
      '',
      '',
      {
        username: this.session.poid,
        policyId: Transmit.RETRIEVE_QUESTIONS,
        additionalParams: { ticket: this.session.transmitTicket }, //Just use same ticket as reset/enroll questions
        clientContext: {},
      },
    )
    const q = await GetQuestions.prototype.retrieveQuestions()
    return q
  }

  getSecurityQNA(): void {
    this.retrieveQuestions()
      .then((response) => {
        const transmitSecQuestions: SecurityQuestionsResponse = {
          questionGroup: [
            {
              challengeQuestion: response['questionGroup_1'],
            },
            {
              challengeQuestion: response['questionGroup_2'],
            },
            {
              challengeQuestion: response['questionGroup_3'],
            },
          ],
        }
        this.securityQuestions = transmitSecQuestions.questionGroup
        this.displayQuestions()
      })
      .catch((error) => {
        this.loggerService.error(
          'Error occured while getting security questions from securityQA component for a transmit user POID : ' +
            this.session.poid +
            ' Error: ' +
            error,
        )
      })
  }

  displayQuestions() {
    this.selected1 = this.securityQuestions[0].challengeQuestion[0].questionText
    this.selected2 = this.securityQuestions[1].challengeQuestion[0].questionText
    this.selected3 = this.securityQuestions[2].challengeQuestion[0].questionText
    this.answerId1 = this.securityQuestions[0].challengeQuestion[0].questionId
    this.answerId2 = this.securityQuestions[1].challengeQuestion[0].questionId
    this.answerId3 = this.securityQuestions[2].challengeQuestion[0].questionId
    this.securityQuestionsForm[0].setValue(
      this.securityQuestions[0].challengeQuestion[0],
    )
    this.securityQuestionsForm[1].setValue(
      this.securityQuestions[1].challengeQuestion[0],
    )
    this.securityQuestionsForm[2].setValue(
      this.securityQuestions[2].challengeQuestion[0],
    )

    this.securityQuestions1 = this.securityQuestions[0].challengeQuestion
    this.securityQuestions2 = this.securityQuestions[1].challengeQuestion
    this.securityQuestions3 = this.securityQuestions[2].challengeQuestion
  }

  checkValidation(): boolean {
    if (this.securityQAForm.invalid) {
      return true
    } else {
      return false
    }
  }

  enrollQuestions = async (qa: SecurityAnswersPayload) => {
    try {
      await CommonUIhandler.prototype.setUIHandler(
        environment[this.regionService.envType][this.regionService.userType]
          .TRANSMIT_URL,
        Transmit.ENROLLMENT_APP_ID,
        '',
        '',
        {
          username: this.session.poid,
          policyId: this.processResponseService.isNewUser
            ? Transmit.QA_ENROLLMENT
            : Transmit.RESET_QUESTIONS,
          additionalParams: {
            ticket: this.session.transmitTicket,
            Q1: qa.challengeQuestionsAnswers[0].questionId,
            Q2: qa.challengeQuestionsAnswers[1].questionId,
            Q3: qa.challengeQuestionsAnswers[2].questionId,
            A1: qa.challengeQuestionsAnswers[0].userAnswer,
            A2: qa.challengeQuestionsAnswers[1].userAnswer,
            A3: qa.challengeQuestionsAnswers[2].userAnswer,
            devicePrint: this.session.devicePrint,
            username: this.session.poid,
          },
          clientContext: {},
        },
      )
      const token = await ReturnToken.prototype.retrieveQuestions()

      const decodedToken = Jose.decodeJwt(token)
      if (decodedToken.qaEnrollment['status'] === 200) {
        this.enrollmentSuccess = true
        const transmitJwtPayload: TransmitJWTPayLoad = {
          poid: this.session.poid,
          policyId: this.processResponseService.isNewUser
            ? Transmit.QA_ENROLLMENT
            : Transmit.RESET_QUESTIONS,
          token: token,
          environment: this.regionService.angular_env,
        }
        const jwtValidResponse =
          this.transmitJWTValidationService.validateJWT(transmitJwtPayload)

        jwtValidResponse.subscribe(
          (data) => {
            if (data.response === 'Token Verified') {
              this.loggerService.info(
                '-- TOKEN VERIFIED -- POID: ' + this.session.poid,
              )
            } else {
              this.loggerService.info(
                '-- TOKEN NOT VERIFIED -- POID: ' + this.session.poid,
              )
            }
          },
          (error) => {
            //show technical error
            this.loggerService.error(
              'JWT Validation Error for POID  :' +
                this.session.poid +
                ' Error Msg :' +
                error,
            )
          },
        )
      } else {
        this.enrollmentSuccess = false
        const numofErrors = decodedToken['qaEnrollment']['body'].length
        for (let index = 0; index < numofErrors; index++) {
          this.loggerService.error(
            'Error is in answer : ' +
              decodedToken['qaEnrollment']['body'][index]['field'] +
              ' POID: ' +
              this.session.poid,
          )
          this.loggerService.error(
            'Error message is : ' +
              decodedToken['qaEnrollment']['body'][index]['errorMessage'] +
              ' POID: ' +
              this.session.poid,
          )
          this.loggerService.error(
            'Error code is : ' +
              decodedToken['qaEnrollment']['body'][index]['errorCode'] +
              ' POID: ' +
              this.session.poid,
          )
        }
        this.invalidMessage = 'transmitErrors'
        this.warningBanner.setWarningBannerMessage('transmitErrors')
        this.loading = false
      }
    } catch (e: unknown) {
      // <-- note `e` has explicit `unknown` type
      if (typeof e === 'string') {
        this.loggerService.info(e.toUpperCase()) // works, `e` narrowed to string
      } else if (e instanceof Error) {
        this.loggerService.info(e.message) // works, `e` narrowed to Error
      }
      this.securityQAForm.reset()
      this.invalidMessage = 'serviceFailureLogon'
      this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
      this.loading = false
    }
  }

  submit() {
    this.securityQAForm.markAllAsTouched()
    this.allFieldValidator()
    if (!this.securityQAForm.invalid) {
      this.securityAnswersPayload = {
        bindDevice: false,
        challengeQuestionsAnswers: [
          {
            questionId: this.answerId1,
            userAnswer: this.securityQAForm.value.secAnswer1.trim(),
          },
          {
            questionId: this.answerId2,
            userAnswer: this.securityQAForm.value.secAnswer2.trim(),
          },
          {
            questionId: this.answerId3,
            userAnswer: this.securityQAForm.value.secAnswer3.trim(),
          },
        ],
        devicePrint: this.session.devicePrint,
      }
      this.clearMsgs()
      this.loading = true
      this.enrollQuestions(this.securityAnswersPayload).then(() => {
        if (this.enrollmentSuccess) {
          let serviceResponse: ServiceResponse
          if (this.session.transmitOobEnrolled || this.session.oobBypass) {
            serviceResponse = {
              state: environment.SUCCESSFULL,
              transmitRedirectUrl: this.session.redirectUrl,
            }
          } else {
            serviceResponse = {
              authAction: environment.OOB_ENROLL,
              state: environment.OUT_OF_BAND_TRANSMIT,
            }
          }
          this.processResponseService.proccess(serviceResponse)

          this.transmitEmailService
            .sendEmail(
              this.transmitEmailNotificationUrl,
              EmailTemplate.SECURITY_QUESTION_UPDATE_TEMPLATE,
              Number(this.session.poid),
            )
            .subscribe({
              next: (data) => {
                this.loggerService.info(
                  'Email sent got successful response. POID: ' +
                    this.session.poid,
                )
              },
              error: (error) => {
                this.loggerService.info(
                  'Error occured while sending email to user POID: ' +
                    this.session.poid +
                    ' Status: ' +
                    error.status,
                )
              },
            })
        } else {
          this.loggerService.info(
            'Error occured while sending user security answers from securityQA component for a transmit user' +
              this.session.poid,
          )
        }
      })
    } else {
      this.securityQAForm.markAllAsTouched()
      this.securityQAForm.updateValueAndValidity({
        onlySelf: false,
        emitEvent: true,
      })
    }
  }

  setSelected(selection: SelectState, questionListIndex: number) {
    let question = ''
    question = selection.label

    if (questionListIndex === 1) {
      this.selected1 = question
      this.answerId1 =
        this.securityQuestions1[selection.selectedIndex].questionId
    } else if (questionListIndex === 2) {
      this.selected2 = question
      this.answerId2 =
        this.securityQuestions2[selection.selectedIndex].questionId
    } else if (questionListIndex === 3) {
      this.selected3 = question
      this.answerId3 =
        this.securityQuestions3[selection.selectedIndex].questionId
    }
  }

  setFocus(questionListIndex: number) {
    const menuItems = from(
      document
        .querySelector('.mat-menu-panel')
        .getElementsByClassName('mat-menu-item'),
    )
    setTimeout(() => {
      const result = menuItems.pipe(
        find((element: HTMLElement) => {
          if (questionListIndex === 1) {
            return element.innerText.trim() === this.selected1
          } else if (questionListIndex === 2) {
            return element.innerText.trim() === this.selected2
          } else if (questionListIndex === 3) {
            return element.innerText.trim() === this.selected3
          }
        }),
      )
      result.subscribe((x) => x && x.focus())
    }, 0)
  }

  closeDialog() {
    this.vuiDialog.closeDialog()
    this.dialogOpen = false
  }

  clear() {
    this.session.clear()
    if (this.dialogOpen) {
      this.closeDialog()
    }
    this.errorMsgService.clearAll()
    this.logonService.cancel(this.cancelRestEndPoint).subscribe({
      next: (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
      error: (error) => {
        this.loggerService.info(
          'Error occured while canceling from reset security Q&A component: Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        // To do - unhappy path for registration
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    })
  }

  clearMsgs(): void {
    this.errorMsgService.clearAll()
    this.warningBanner.setWarningBannerMessage('')
    this.checkMsgs()
  }

  checkMsgs(): void {
    this.invalidMessage = this.errorMsgService.invalidMessage
    this.warningBanner.setWarningBannerMessage(
      this.errorMsgService.invalidMessage,
    )
    this.loading = this.errorMsgService.loading
  }

  openTipsLayer() {
    this.vuiDialog.openDialog(
      this.viewContainer,
      this.tipsContentDialog,
      this.vuiDialogConfig,
    )
  }

  allFieldValidator() {
    Object.keys(this.securityQAForm.controls).forEach((ctrlName) => {
      this.fieldValidator(ctrlName)
    })
  }

  fieldValidator(ctrlName: string) {
    const controller = this.securityQAForm.get(ctrlName)
    controller.updateValueAndValidity()
    if (controller.errors) {
      if (!controller.touched && !controller.errors['required']) {
        controller.markAsTouched()
      }
    }
    switch (ctrlName) {
      case environment.SECURITY_ANSWER_1:
        if (
          this.securityQAForm.controls.secAnswer1.touched &&
          this.securityQAForm.controls.secAnswer1.errors !== null
        ) {
          this.secAnswer1ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secAnswer1ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_REENTER_1:
        if (
          this.securityQAForm.controls.secReEnter1.touched &&
          this.securityQAForm.controls.secReEnter1.errors !== null
        ) {
          this.secReEnter1ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secReEnter1ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_2:
        if (
          this.securityQAForm.controls.secAnswer2.touched &&
          this.securityQAForm.controls.secAnswer2.errors !== null
        ) {
          this.secAnswer2ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secAnswer2ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_REENTER_2:
        if (
          this.securityQAForm.controls.secReEnter2.touched &&
          this.securityQAForm.controls.secReEnter2.errors !== null
        ) {
          this.secReEnter2ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secReEnter2ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_3:
        if (
          this.securityQAForm.controls.secAnswer3.touched &&
          this.securityQAForm.controls.secAnswer3.errors !== null
        ) {
          this.secAnswer3ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secAnswer3ErrorMsg = ''
        }
        break
      case environment.SECURITY_ANSWER_REENTER_3:
        if (
          this.securityQAForm.controls.secReEnter3.touched &&
          this.securityQAForm.controls.secReEnter3.errors !== null
        ) {
          this.secReEnter3ErrorMsg = this.validatorService.getValidationMsg(
            controller,
            environment.SECURITY_QNA,
            0,
          )
        } else {
          this.secReEnter3ErrorMsg = ''
        }
        break
      default:
        break
    }
  }
}
