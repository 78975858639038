import { Component, OnInit, AfterViewChecked } from '@angular/core'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { environment } from '@env'
import { ContentService } from '@app/shared/services/contentService'
import { ViewService } from '@app/shared/services/viewService'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { CancelResponse } from '../models/CancelResponse'
import { Logon } from '@app/shared/services/logonService'
import { RegionService } from '@app/shared/services/regionService'
import { AdobeAnalyticsService } from '../shared/services/adobe-analytics/adobe-analytics.service'
import { SessionService } from '@app/shared/services/sessionService'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { OobChallengeService } from '@app/shared/services/oobChallengeService'
import { ResendCodeResponse } from '@app/models/ResendCodeResponse'
import { ResendCodePayload } from '@app/models/ResendCodePayload'
import { ChallengeResponse } from '@app/models/ChallengeResponse'
import { ChallengePayload } from '@app/models/ChallengePayload'
import { ProcessResponseService } from '@app/shared/services/processResponseService/processResponse.service'
import { SelfServiceChallengePayload } from '@app/models/SelfServiceChallengePayload'
import { SelfService } from '@app/shared/services/selfService/selfService.service'
import { ServiceResponse } from '@app/models/ServiceResponse'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { checkCircleMd } from '@vanguard/vui/icons'
import {
  Transmit,
  TransmitAuthenticateResult,
} from '@app/models/TransmitConstants'
import { EmailTemplate } from '@app/models/EmailTemplate'
import { setUiHandler } from '@app/shared/services/transmitOtpService/transmitOtpUiHandler'
import { TransmitOtpService } from '@app/shared/services/transmitOtpService/transmitOtp.service'
import { TransmitEmailService } from '@app/shared/services/transmitEmailService/transmitEmail.service'
import { VieFormsRedirectService } from '@app/shared/services/vieFormsRedirect/vieFormsRedirect.service'
import { TransmitSdkService } from '@app/shared/services/transmitCommonService/transmitSdk.service'
import { AdobeLaunchConfigurationService } from '@app/shared/services/adobe-launch-configuration/adobeLaunchConfiguration.service'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { LoggerService } from '@app/shared/services/loggerService'

const accountMaintenance = 'account-maintenance%2F'
const sdk = XmSdk()

@Component({
  selector: 'security-code-view',
  templateUrl: './securityCode.component.html',
  styleUrls: ['./securityCode.component.scss'],
})
export class SecurityCodeComponent implements OnInit, AfterViewChecked {
  content: any
  securityCodeForm: FormGroup
  cancelRestEndPoint: string
  continueBtnDisabled = false
  disableForm = false
  validationCodeMsgText: string[]
  EM_DASH: string
  adobeData: { section: string; subSection: string; pageId: string }
  hideRememberMe = true
  oobPhone: string
  resendCodePayload: ResendCodePayload
  loading = false
  popUpMsg: string
  showPopUpMsg = false
  challengeOTPResendEndPoint: string
  challengePayload: ChallengePayload
  invalidMessage = ''
  securityCodeUrl: string
  counter = 0
  disableLink = false
  selfService = false
  selfServiceChallengePayload: SelfServiceChallengePayload
  authOobRestEndPoint = ''
  selfServiceResendCodePayload: ResendCodePayload
  deviceRadio: FormControl = new FormControl(false)
  public readonly checkCircleMd = checkCircleMd
  oobCodeError = 'oobCodeError'
  maxSessionsReached = false
  isBounded: boolean
  resendRequest = false
  transmitUserStatus: string
  displayOob = true
  transmitSubmitCode = false
  transmitEmailNotificationUrl: string
  formUrl: string
  transmitResetQA = false
  rememberThisComputer: string
  additionalInfo: string

  constructor(
    private logonService: Logon,
    private fb: FormBuilder,
    private viewService: ViewService,
    readonly warningBanner: WarningBannerService,
    private contentService: ContentService,
    private session: SessionService,
    private adobeAnalytics: AdobeAnalyticsService,
    private regionService: RegionService,
    private headerText: HeaderTextService,
    readonly errorMsgService: ErrorMsgService,
    readonly oobChallengeService: OobChallengeService,
    private processResponseService: ProcessResponseService,
    private selfServiceService: SelfService,
    readonly transmitOtpService: TransmitOtpService,
    private transmitEmailService: TransmitEmailService,
    private readonly vieFormsRedirectService: VieFormsRedirectService,
    private readonly transmitSdkService: TransmitSdkService,
    private readonly adobeLaunchConfigurationService: AdobeLaunchConfigurationService,
    private readonly logger: LoggerService,
  ) {}

  ngOnInit() {
    this.loading = true
    this.displayOob = false
    document.getElementById('global-header').style.display = 'none'
    document.getElementById('global-footer').style.display = 'none'
    document.getElementById('header-text').style.display = 'none'
    this.isBounded = this.oobChallengeService.oobBound
    this.adobeData = {
      section: environment.LOGON_ADOBE_SECTION,
      subSection: environment.LOGON_ADOBE_SUBSECTION,
      pageId: environment.OOB_CHALLENGE_ADOBE_PAGEID,
    }
    this.content = this.contentService.caasContent
    this.headerText.setComponent(
      this.content.Data.oobSecurityPage.content.titleId,
    )
    this.headerText.setHeader(
      this.content.Data.oobSecurityPage.content.headerText,
    )
    this.headerText.setShowHeadText(false)
    this.selfService = this.session.selfService
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX

    this.challengeOTPResendEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CHALLENGE_OTP_RESEND_POSTFIX

    this.transmitEmailNotificationUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_REST_BASE +
      environment.LOGON_REST_PATH +
      environment.TRANSMIT_EMAIL_NOTIFICATION_ENDPOINT

    this.securityCodeUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_REST_BASE +
      environment.LOGON_REST_PATH +
      environment.SECURIY_CODE_VALIDATION_POSTFIX

    this.authOobRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.SELF_SERVICE_CHALLENGE_ENDPOINT

    this.validationCodeMsgText = [
      this.content.Data.oobSecurityPage.content.securityCodeForm
        .securityCodeForm.validationCodeMsgText.validationCodeMsgText.text,
      this.content.Data.oobSecurityPage.content.securityCodeForm
        .securityCodeForm.validationCodeMsgText.validationCodeMsgText.link,
    ]
    this.securityCodeForm = this.fb.group({
      oobCode: new FormControl('', [
        Validators.required,
        Validators.pattern(environment.ALPHA_NUM_SPECIAL_CHAR_REG_EX),
        Validators.maxLength(20),
      ]),
    })
    if (!this.selfService) {
      this.securityCodeForm.addControl('deviceRadio', this.deviceRadio)
    }
    if (this.isBounded) {
      this.securityCodeForm.addControl('deviceRadio', new FormControl(true))
    }
    this.EM_DASH = environment.EM_DASH
    this.adobeAnalytics.updateData(this.adobeData)
    this.adobeAnalytics.track()
    this.adobeLaunchConfigurationService.trackPageLoad(
      environment.SECURITY_CODE,
    )
    this.oobPhone = this.oobChallengeService.oobPhone
    this.hideRememberMe = this.oobChallengeService.oobBound
    window.setTimeout(() => document.getElementById('oobCodeInput').focus(), 0)
    this.initializeTransmitJourney()
    this.formUrl = window.location.href
  }

  initializeTransmitJourney() {
    const transmitModel = {
      ticket: this.selfService
        ? this.session.transmitTicket
        : this.oobChallengeService.logOnResponse.transmitTicket,
      isBoundDevice: this.selfService
        ? false
        : this.oobChallengeService.oobBound,
      poId: this.selfService
        ? this.session.poid
        : this.oobChallengeService.logOnResponse.poid?.toString(),
      journeyName: Transmit.OTP_JOURNEY_NAME,
    }
    this.logger.info(
      'Transmit ' +
        transmitModel.journeyName +
        ' for Transmit Ticket # ' +
        transmitModel.ticket +
        ' Initiating for Poid : ' +
        transmitModel.poId,
    )

    this.transmitOtpService.transmitService.selfServiceFlowFlag =
      this.selfService

    const encryptedRetailParams = false

    const transmitParams = {
      auth1: this.selfService
        ? 0
        : this.oobChallengeService.logOnResponse.auth1,
      auth2: this.selfService
        ? 0
        : this.oobChallengeService.logOnResponse.auth2,
      devicePrint: this.session.devicePrint,
      retailParams: encryptedRetailParams,
    }

    //Need to set the serviceResponse to the logOnResponse for transmit users as a response is no longer being
    //returned by transmit, but we need this object to be populated
    const serviceResponse = this.oobChallengeService.logOnResponse
    setUiHandler(this)
    this.transmitOtpService
      .authenticateOtpJourney(transmitModel, transmitParams)
      .then((result: TransmitAuthenticateResult) => {
        this.logger.info(
          'Transmit Journey ' +
            transmitModel.journeyName +
            ' for Transmit Ticket # ' +
            transmitModel.ticket +
            'is complete and logged out for poid: ' +
            transmitModel.poId,
        )
        if (result === TransmitAuthenticateResult.SUCCESSFUL) {
          //add tagging here for transmit users
          this.tagContinueBtn()

          this.logger.info(
            'Transmit Journey ' +
              transmitModel.journeyName +
              ' for Transmit Ticket # ' +
              transmitModel.ticket +
              ' Successfull for Poid : ' +
              transmitModel.poId,
          )

          if (!this.selfService) {
            serviceResponse.authAction = this.getAuthAction()
            serviceResponse.state = environment.SUCCESSFULL

            //VIE forms redirection
            if (
              this.formUrl.includes(accountMaintenance) &&
              serviceResponse.isVieUser
            ) {
              serviceResponse.transmitRedirectUrl =
                this.vieFormsRedirectService.vieFormUrl(this.formUrl)
            }

            if (this.transmitResetQA) {
              this.processResponseService.isResetQA = this.transmitResetQA
              if (
                serviceResponse.authAction !== environment.USER_STATUS_F &&
                serviceResponse.authAction !== environment.USER_STATUS_C
              ) {
                serviceResponse.authAction = environment.AA_RESET
              }
              this.session.transmitEnabled = true
              this.session.poid =
                this.oobChallengeService.logOnResponse.poid?.toString()
              this.session.transmitOobEnrolled = true
              this.session.redirectUrl = serviceResponse.transmitRedirectUrl
            }
            serviceResponse.OOBBoundDevice = this.isBounded
            this.processResponseService.proccess(serviceResponse)
          } else {
            this.sendEmailForSelfService()
          }
        } else if (result === TransmitAuthenticateResult.LOCKOUT) {
          this.logger.info(
            'User Poid ' +
              transmitModel.poId +
              ' is in LOCKED State for Transmit ' +
              transmitModel.journeyName +
              ' Transmit # ' +
              transmitModel.ticket,
          )

          serviceResponse.state = environment.UNSUCCESSFULL
          serviceResponse.authAction = environment.PASSWORD_LOCKEDOUT
          this.loading = true
          if (this.transmitSubmitCode) {
            this.transmitEmailService
              .sendEmail(
                this.transmitEmailNotificationUrl,
                EmailTemplate.OOB_LOCKED_TEMPLATE,
                this.selfService
                  ? Number(this.session.poid)
                  : this.oobChallengeService.logOnResponse.poid,
              )
              .subscribe(
                (data: any) => {
                  this.logger.info(
                    'Email Successfully Sent for User Poid ' +
                      transmitModel.poId,
                  )
                  this.processResponseService.proccess(serviceResponse)
                },
                (error) => {
                  this.logger.error(
                    'Error occured while sending email User Poid ' +
                      transmitModel.poId +
                      ' with Error Status :' +
                      error.status,
                  )
                  this.errorHandling()
                },
              )
          } else {
            this.processResponseService.proccess(serviceResponse)
          }
        } else {
          this.logger.error(
            'Transmit ' +
              transmitModel.journeyName +
              ' for Transmit Ticket # ' +
              transmitModel.ticket +
              ' for Poid :' +
              transmitModel.poId +
              ' Failed Status :' +
              result,
          )
          this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
          this.errorMsgService.invalidMessage = 'serviceFailureLogon'
          this.loading = false
        }
      })
      .catch((err) => {
        this.logger.error(err)
        this.errorHandling()
      })
  }

  modifyAriaIndex(element, id) {
    if (element != null) {
      return element.setAttribute('aria-describedby', id)
    }
  }

  authUserSecurityCode() {
    if (this.maxSessionsReached) {
      return
    }
    this.clearMsgs()
    if (!this.securityCodeForm.invalid) {
      if (!this.selfService) {
        this.authChallenge()
      } else {
        this.authSelfServiceChallenge()
      }
    } else {
      document.getElementById('oobCodeInput').focus()
      this.resetForm()
      this.securityCodeForm.markAllAsTouched()
      this.securityCodeForm.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      })
      this.securityCodeForm.controls['oobCode'].markAsTouched()
    }
  }

  authChallenge(): void {
    this.challengePayload = {
      answer: this.securityCodeForm.value.oobCode,
      device: this.securityCodeForm.value.deviceRadio,
      devicePrint: this.session.devicePrint,
      pageSource: environment.CHALLENGE_OTP,
    }
    this.loading = true
    this.transmitSubmitCode = true
    this.transmitOtpService.submitOtpCode(this.securityCodeForm.value.oobCode)
    document.getElementById('oobCodeInput').focus()
  }

  errorHandling() {
    this.errorMsgService.invalidMessage = 'serviceFailureLogon'
    this.viewService.setView(environment.USERNAME_AND_PASSWORD)
  }

  authSelfServiceChallenge(): void {
    this.transmitSubmitCode = true
    const otpCode = (<HTMLInputElement>document.getElementById('oobCodeInput'))
      .value
    this.logger.info(
      'Comparision ======> {}' +
        (otpCode === this.securityCodeForm.value.oobCode),
    )
    this.transmitOtpService.submitOtpCode(otpCode)
  }

  sendEmailForSelfService() {
    this.selfServiceChallengePayload = {
      response: '',
      transmitUser: true,
      transmitJwtData: {
        transmitPolicyId: Transmit.OTP_JOURNEY_NAME,
        transmitEnvironment: this.regionService.angular_env,
        transmitToken: this.transmitSdkService.token,
      },
    }
    this.callSelfServiceChallenge()
  }

  private callSelfServiceChallenge() {
    this.selfServiceService
      .authChallenge(this.authOobRestEndPoint, this.selfServiceChallengePayload)
      .subscribe(
        (data: ServiceResponse) => {
          this.securityCodeForm.controls.oobCode.reset()
          this.processResponseService.proccess(data)
          this.checkMsgs()
        },
        (error) => {
          this.logger.error(
            'Error occured while sending user code for self service from security code component: Status: ' +
              error.status +
              ', error: ' +
              error.message,
          )
          if (error.status === 400) {
            this.invalidMessage = 'invalidCode'
            this.warningBanner.setWarningBannerMessage('invalidCode')
          } else {
            this.invalidMessage = 'serviceFailureLogon'
            this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
          }
          this.loading = false
        },
      )
  }

  cancel() {
    this.session.clear()
    if (!this.selfService) {
      this.clearMsgs()
    }
    this.logonService.cancel(this.cancelRestEndPoint).subscribe(
      (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
      (error) => {
        this.logger.error(
          'Error occured while canceling Q&A from challenge component: Status: ' +
            error.status +
            ', error: ' +
            error.message +
            'for Poid: ' +
            this.session.poid,
        )
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    )
    //Need to cancel the current journey, get a new ticket, and start a new journey if the user wants to start the otp journey again
    sdk.cancelCurrentRunningControlFlow()
  }

  onOkay() {
    this.showPopUpMsg = false
  }

  toggleIndicators() {
    this.showPopUpMsg = !this.showPopUpMsg
    this.loading = !this.loading
  }

  resendOTP() {
    if (!this.selfService) {
      this.onSendCode()
    } else {
      this.selfServiceOnSendCode()
    }
    this.adobeLaunchConfigurationService.trackResendSecurityCode()
  }

  onSendCode() {
    if (this.counter < 4) {
      this.loading = true
      this.resendRequest = true
      this.transmitOtpService.sendNewCode(
        this.oobChallengeService.logOnResponse.challenge?.USER_CHALLENGE_METHOD,
      )
    } else if (this.counter === 4) {
      this.loading = true
      this.maxSessionsReached = true
      this.toggleIndicators()
      this.popUpMsg = 'maxSessions'
      this.warningBanner.setWarningBannerMessage('maxSessions')
      this.disableFormForMaxSession()
    }
  }

  selfServiceOnSendCode() {
    if (this.counter < 4) {
      this.loading = true
      this.resendRequest = true
      this.transmitOtpService.selfServiceSendNewCode()
    } else if (this.counter === 4) {
      this.loading = true
      this.maxSessionsReached = true
      this.toggleIndicators()
      this.popUpMsg = 'maxSessions'
      this.warningBanner.setWarningBannerMessage('maxSessions')
      this.disableFormForMaxSession()
    }
  }

  resetForm() {
    this.securityCodeForm.controls['oobCode'].setValue('')
    if (!this.selfService) {
      this.securityCodeForm.controls['deviceRadio'].setValue(false)
    }
  }

  disableFormForMaxSession() {
    this.securityCodeForm.controls['oobCode'].setValue('1')
    this.securityCodeForm.controls['oobCode'].disable()
    this.securityCodeForm.controls['oobCode'].setValue(' ')

    this.disableLink = true
    this.continueBtnDisabled = true

    // add tabIndex = -1 to prevent the link getting the focus when disabled
    document.getElementById('sendNewCode').style.pointerEvents = 'none'
    document.getElementById('sendNewCode').style.cursor = 'pointer'
    document
      .getElementById('sendNewCode')
      .setAttribute('class', 'sendNewCodeDisabled')
    document
      .getElementById('timeIcon')
      .setAttribute('class', 'sendNewCodeDisabled')

    document
      .getElementById('cancelBtn')
      .getElementsByTagName('button')
      .item(0)
      .focus()
  }

  ngAfterViewChecked() {
    if (document.getElementById('cancelBtn')) {
      const button = document
        .getElementById('cancelBtn')
        .getElementsByTagName('button')
        .item(0)
      this.modifyAriaIndex(button, 'warningBannerError')
    }
  }

  checkCounter() {
    if (this.counter > 5) {
      this.disableLink = true
    }
  }

  clearMsgs(): void {
    this.errorMsgService.clearAll()
    this.invalidMessage = ''
    this.warningBanner.setWarningBannerMessage('')
    this.errorMsgService.invalidOTPMessage = ''
    this.loading = false
  }

  checkMsgs(): void {
    if (this.errorMsgService.invalidMessage) {
      this.invalidMessage = this.errorMsgService.invalidMessage
      this.warningBanner.setWarningBannerMessage(
        this.errorMsgService.invalidMessage,
      )
    } else {
      this.invalidMessage = this.errorMsgService.invalidOTPMessage
      this.warningBanner.setWarningBannerMessage(
        this.errorMsgService.invalidOTPMessage,
      )
    }
    this.loading = this.errorMsgService.loading
  }

  getAuthAction() {
    switch (this.transmitUserStatus) {
      case environment.TRANSMIT_USER_STATUS_F:
        return environment.USER_STATUS_F
      case environment.TRANSMIT_USER_STATUS_C:
        return environment.USER_STATUS_C
      default:
        return ''
    }
  }

  tagContinueBtn(): void {
    if (this.securityCodeForm.value.deviceRadio) {
      this.rememberThisComputer =
        this.content.Data.oobSecurityPage.content.rememberThisPC.rememberThisPC.allowBound.toLowerCase()
    } else {
      this.rememberThisComputer =
        this.content.Data.oobSecurityPage.content.rememberThisPC.rememberThisPC.preventBound.toLowerCase()
    }
    this.adobeLaunchConfigurationService.trackAuthUserSecurityCode(
      this.rememberThisComputer,
    )
  }
}
