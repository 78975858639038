import { Component, Input, OnInit } from '@angular/core'
import { ContentService } from '@app/shared/services/contentService'
import { environment } from '@env'

@Component({
  selector: 'toggle-message',
  templateUrl: './toggle-message.component.html',
  styleUrls: ['./toggle-message.component.scss'],
})
export class ToggleMessageComponent implements OnInit {
  @Input() messageHeader: string
  content: any

  constructor(private readonly contentService: ContentService) {}

  ngOnInit() {
    this.content = this.contentService.caasContent
  }

  isUnderMaint(): boolean {
    return this.messageHeader === environment.UNDER_MAINTENANCE
  }

  isAppDown(): boolean {
    return this.messageHeader === environment.APP_DOWN
  }
}
