import { Injectable } from '@angular/core'
import { SelfServicePayload } from '@app/models/SelfServicePayload'
import { ServiceResponse } from '@app/models/ServiceResponse'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { SelfServiceChallengePayload } from '@app/models/SelfServiceChallengePayload'

@Injectable()
export class SelfService {
  constructor(private httpClient: HttpClient) {}

  lookUp(url: string, body: SelfServicePayload): Observable<ServiceResponse> {
    return this.httpClient.post<ServiceResponse>(url, body)
  }

  authChallenge(
    url: string,
    body: SelfServiceChallengePayload,
  ): Observable<ServiceResponse> {
    return this.httpClient.post<ServiceResponse>(url, body)
  }
}
