//import { SecurityCodeComponent } from '@app/securityCode/securityCode.component'
import { ForcedOobEnrollmentComponent } from '@app/forcedOobEnrollment/forcedOobEnrollment.component'
import { TransmitErrorCode, OtpAuthState } from '@app/models/TransmitConstants'
import { TransmitOtpService } from '@app/shared/services/transmitOtpService/transmitOtp.service'
import { environment } from '@env'
import { RegionService } from '@app/shared/services/regionService'
import { SessionService } from '@app/shared/services/sessionService'
import { Logon } from '@app/shared/services/logonService'
import { CancelResponse } from '@app/models/CancelResponse'
import { ViewService } from '@app/shared/services/viewService'

declare const com: any

export class TransmitEnrollOtpAuthSession {
  forcedOobEnrollmentComponent: ForcedOobEnrollmentComponent
  transmitOtpService: TransmitOtpService
  mode: any
  primarySmsTarget: any
  primaryVoiceTarget: any
  primaryMethod: string

  constructor(forcedOobEnrollmentComponent, transmitOtpService) {
    this.forcedOobEnrollmentComponent = forcedOobEnrollmentComponent
    this.transmitOtpService = transmitOtpService
  }

  startSession = (description, mode, actionContext, clientContext) => {
    this.mode = mode
    this.transmitOtpService.actionContext = actionContext
  }

  endSession() {}

  setAvailableTargets(targets) {
    this.transmitOtpService.possibleTargets = targets
    this.transmitOtpService.possibleTargetAng = targets
  }

  getErrorCode() {}

  setGeneratedOtp(format, target) {
    if (target) {
      this.transmitOtpService.state = 1
      if (format instanceof com.ts.mobile.sdk.OtpFormatNumericImpl) {
        this.transmitOtpService.codeLength = format.getOtpLength()
        this.transmitOtpService.isSupportedFormat = true
      } else {
        this.transmitOtpService.isSupportedFormat = false
      }
    } else {
      this.transmitOtpService.state = 0
    }
    this.transmitOtpService.target = target
  }

  showAuthUi() {
    let inputPromise

    switch (this.transmitOtpService.state) {
      case OtpAuthState.TargetSelection:
        //this.sendOtpToTarget()
        this.transmitOtpService.selfServiceSendNewCode()
        this.transmitOtpService.state = 1
        break
      case OtpAuthState.Input:
        if (this.transmitOtpService.isSupportedFormat) {
          this.showOtpInput()
        } else {
          Promise.reject('Reject due to unsupported otp code format')
        }
    }
    return inputPromise
  }

  //commented the below as same implementation is in transmitOtpService.selfServiceSendNewCode()
  // sendOtpToTarget() {
  //   this.transmitOtpService.state = 1  //Is this needed here???

  //   const target = this.transmitOtpService.possibleTargets[0]
  //   const input =
  //     com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createTargetSelectionRequest(
  //       target,
  //     )

  //   this.transmitOtpService.resolver(
  //     com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(input),
  //   )
  //   console.log('end of sendOtpToTarget()')
  // }

  showOtpInput() {
    this.forcedOobEnrollmentComponent.openOtpPopUp(true)
    this.forcedOobEnrollmentComponent.loading = false
  }

  getInitialTarget() {
    let target

    const otpTargets = this.transmitOtpService.possibleTargetAng
    this.primaryMethod = this.forcedOobEnrollmentComponent.channel

    if (this.primaryMethod === 'SMS' || this.primaryMethod === 'INTL_SMS') {
      target = otpTargets.find(
        (t) =>
          t._targetIdentifier === '1' &&
          t._channel === com.ts.mobile.sdk.OtpChannel.Sms,
      )
    } else {
      target = otpTargets.find(
        (t) =>
          t._targetIdentifier === '1' &&
          t._channel === com.ts.mobile.sdk.OtpChannel.VoiceCall,
      )
    }
    return target
  }

  promiseRecoveryForError(error, validRecoveries, defaultRecovery) {
    let promiseRecovery
    return new Promise((resolve) => {
      if (
        error._errorCode === TransmitErrorCode.InvalidInput &&
        error._data.additional_data.additional_error_code === undefined
      ) {
        this.forcedOobEnrollmentComponent.popUpMsg = 'invalidCode'
        this.forcedOobEnrollmentComponent.showPopUpMsg = true
        this.forcedOobEnrollmentComponent.oobEnrollmentForm.controls[
          'oobCode'
        ].reset()
        this.forcedOobEnrollmentComponent.oobEnrollmentForm.enable()
        promiseRecovery = defaultRecovery
        resolve(promiseRecovery)
      } else if (
        error._errorCode === TransmitErrorCode.InvalidInput &&
        error._data.assertion_error_code === TransmitErrorCode.OtpExpired
      ) {
        this.forcedOobEnrollmentComponent.popUpMsg = 'expiredCode'
        this.forcedOobEnrollmentComponent.showPopUpMsg = true
        this.forcedOobEnrollmentComponent.oobEnrollmentForm.controls[
          'oobCode'
        ].reset()
        this.forcedOobEnrollmentComponent.loading = false
        this.forcedOobEnrollmentComponent.oobEnrollmentForm.controls[
          'oobCode'
        ].disable()
        this.forcedOobEnrollmentComponent.oobEnrollmentForm.setErrors({
          invalid: true,
        })
        document.getElementById('sendNewCode').onclick = () => {
          promiseRecovery = defaultRecovery
          resolve(promiseRecovery)
        }
      } else if (
        (error._errorCode === TransmitErrorCode.InvalidInput &&
          error._data !== undefined &&
          error._data.additional_data.additional_error_code ===
            TransmitErrorCode.InvalidInput) ||
        error._errorCode === TransmitErrorCode.ControlFlowExpired ||
        error._errorCode === TransmitErrorCode.SessionRequired
      ) {
        //set the banner here for expired session
        this.forcedOobEnrollmentComponent.warningBanner.setWarningBannerMessage(
          'answerExpired',
        )

        this.forcedOobEnrollmentComponent.loading = false
        promiseRecovery = defaultRecovery
        resolve(promiseRecovery)
      } else {
        this.forcedOobEnrollmentComponent.warningBanner.setWarningBannerMessage(
          'serviceFailureLogon',
        )
        this.forcedOobEnrollmentComponent.errorMsgService.invalidMessage =
          'serviceFailureLogon'
        promiseRecovery = com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
        resolve(promiseRecovery)
      }
    })
  }

  handleAuthenticateAssertionResult(controlFlowResponse) {}

  promiseInput() {
    return new Promise((accept, reject) => {
      this.transmitOtpService.resolver = accept
      this.showAuthUi()
    }).then((input) => {
      this.transmitOtpService.authError = null
      return input
    })
  }
}
