<spinner [active]="loading"></spinner>
<div class="view-container">
        <div class="row">
               <form [formGroup]="logonForm" class="margin-top-30 width-565" id="logon" name="logon">

                  <div class="logon-input-fields-div margin-bottom-24">

                  <label c11n-label [for]="'userNameInput'" id="userNameInputLabel" class="logon-c11n-label">{{content.Data.logonPage.content.userNameLabel}}</label>
                  <c11n-input [hasError]="logonForm?.get('userNameInputBox')?.touched && logonForm.get('userNameInputBox')?.errors">
                    <input c11nInput
                      inputmode="text"
                      id="userNameInputBox" 
                      name="userNameInputBox"
                      aria-describedby="userNameInputBoxError"
                      formControlName="userNameInputBox"
                      placeholder={{content.Data.logonPage.content.userNamePlaceholder}}
                      />
                  </c11n-input>
                    
                  <c11n-hint-error *ngIf="content.Data.logonPage.content.usernameRequired != undefined"
                    [hasError]="logonForm?.get('userNameInputBox')?.touched && logonForm.get('userNameInputBox')?.hasError('required')"
                    [errorText]="content.Data.logonPage.content.usernameRequired"
                    [hintErrorId]="'userNameInputBoxError'">
                  </c11n-hint-error>

                  <c11n-hint-error *ngIf="content.Data.logonPage.content.usernameRequired === undefined"
                    [hasError]="logonForm?.get('userNameInputBox')?.touched && logonForm.get('userNameInputBox')?.hasError('required')"
                    [errorText]="this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.requiredField"
                    [hintErrorId]="'userNameInputBoxError'">
                  </c11n-hint-error>
                </div>

                    <div class="logon-input-fields-div">
                    <div class="pw-input-wrapper">

                    <label c11n-label [for]="'passwordInput'" id="passwordInputLabel" class="logon-c11n-label">{{content.Data.logonPage.content.passwordLabel}}</label>
                    <c11n-input [hasError]="logonForm?.get('passwordInputBox')?.touched && logonForm.get('passwordInputBox')?.errors">
                      <input
                        c11nInput
                        [attr.type]="inputType"
                        id="passwordInputBox"
                        name="passwordInputBox"
                        placeholder={{content.Data.logonPage.content.passwordPlaceholder}}
                        formControlName="passwordInputBox"
                        aria-describedby="passwordInputBoxError"
                      />
                    </c11n-input>
                    <div class="c11n-text-sm pw-show-hide">
                      <button
                          c11n-link
                          class="showHideBtn showHideBtnHoverAnimation"
                          size="small"
                          type="button"
                          aria-controls="passwordInputBox"
                          (click)="handleShowHideBtn()"
                      >
                          {{ hidePw ? 'Show' : 'Hide' }}
                      </button>
                      </div>
                  </div>
                    <c11n-hint-error *ngIf="content.Data.logonPage.content.passwordRequired != undefined"
                      [hasError]="logonForm?.get('passwordInputBox')?.touched && logonForm.get('passwordInputBox')?.hasError('required')"
                      [errorText]="content.Data.logonPage.content.passwordRequired"
                      [hintErrorId]="'passwordInputBoxError'">
                    </c11n-hint-error>

                    <c11n-hint-error *ngIf="content.Data.logonPage.content.passwordRequired === undefined"
                      [hasError]="logonForm?.get('passwordInputBox')?.touched && logonForm.get('passwordInputBox')?.hasError('required')"
                      [errorText]="this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.requiredField"
                      [hintErrorId]="'passwordInputBoxError'">
                    </c11n-hint-error>
                  </div>

                  <div class="c11n-text-md display-block width-400 logon-input-fields-div padding-top-32">
                    <a id="forgotCredentialsLink" c11n-link routerLink="/forgot-username">{{content.Data.logonPage.content.forgotUsernamePasswordLink}}</a>
                  </div>


                 <div class="justify-content-between btn-container margin-bottom-45 padding-top-40"> 
                      <c11n-button class="margin-right-50"
                        id="cancelLogonBtn" 
                        buttonType="button"
                        labelText={{content.Data.logonPage.content.cancelBtn}}
                        variant="secondary"
                        (clickEvent)="onCancel()"
                        >
                      </c11n-button>
                      <c11n-button
                        id="logonBtn"
                        name="logonBtn"
                        buttonType="submit"
                        labelText={{content.Data.logonPage.content.logonBtn}}
                        variant="primary"
                        [disabled]="logonBtnDisabled"
                        (clickEvent)="onLogon()"
                      >
                  </c11n-button>
                  </div>
               </form>

               <div id="titleHeaderMsg" class="sub-header-text">{{this.content.Data.logonPage.content.subHeaderText}} <a id="retirementPartEx" class="sub-header-text-link vui-link vui-text-color-link" href="{{content.Data.logonPage.content.subHeaderTextLinkRef}}" (click)="link()">{{content.Data.logonPage.content.subHeaderTextLink}}</a></div>
          </div>
      </div> 
