import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core'
import { ContentService } from '@app/shared/services/contentService'

@Component({
  selector: 'oob-tips-dialog',
  templateUrl: './oobCodeTips.component.html',
  styleUrls: ['./oobCodeTips.component.scss'],
})
export class OobCodeTipsComponent implements OnInit {
  content: any

  constructor(private contentService: ContentService) {}

  ngOnInit() {
    this.content = this.contentService.caasContent
  }
}
