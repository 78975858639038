const region = 'external'

/*
  All URLs need to change, base on ENV and REGION type.
*/
export const uri = {
  REGION: region,
  local: {
    envType: 'local',
    external: {
      VGN_REST_BASE: 'https://satccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://satlogon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://satinstitutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://satlogon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfat.vanguard.com',
      VIE_BASE_URL: 'https://idde.inst.c1.vanguard.com',
    },
  },
  'eng-us-east-1': {
    envType: 'development',
    external: {
      VGN_REST_BASE: 'https://satccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://devlogon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://satinstitutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://devlogon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfat.vanguard.com',
      VIE_BASE_URL: 'https://idde.inst.c1.vanguard.com',
    },
  },
  'test-us-east-1': {
    envType: 'development',
    external: {
      VGN_REST_BASE: 'https://satccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://satlogon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://satinstitutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://satlogon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfat.vanguard.com',
      VIE_BASE_URL: 'https://idde.inst.c1.vanguard.com',
    },
  },
  'production-us-east-1': {
    envType: 'production',
    external: {
      VGN_REST_BASE: 'https://ccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://logon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://institutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://logon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfa.vanguard.com',
      VIE_BASE_URL: 'https://idde.vanguard.com',
    },
  },
  'eng-us-east-2': {
    envType: 'development',
    external: {
      VGN_REST_BASE: 'https://satccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://devlogon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://satinstitutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://devlogon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfat.vanguard.com',
      VIE_BASE_URL: 'https://idde.inst.c1.vanguard.com',
    },
  },
  'test-us-east-2': {
    envType: 'development',
    external: {
      VGN_REST_BASE: 'https://satccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://satlogon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://satinstitutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://satlogon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfat.vanguard.com',
      VIE_BASE_URL: 'https://idde.inst.c1.vanguard.com',
    },
  },
  'production-us-east-2': {
    envType: 'production',
    external: {
      VGN_REST_BASE: 'https://ccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://logon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://institutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://logon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfa.vanguard.com',
      VIE_BASE_URL: 'https://idde.vanguard.com',
    },
  },
  'eng-us-west-2': {
    envType: 'development',
    external: {
      VGN_REST_BASE: 'https://satccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://devlogon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://satinstitutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://devlogon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfat.vanguard.com',
      VIE_BASE_URL: 'https://idde.inst.c1.vanguard.com',
    },
  },
  'test-us-west-2': {
    envType: 'development',
    external: {
      VGN_REST_BASE: 'https://satccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://satlogon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://satinstitutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://satlogon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfat.vanguard.com',
      VIE_BASE_URL: 'https://idde.inst.c1.vanguard.com',
    },
  },
  'production-us-west-2': {
    envType: 'production',
    external: {
      VGN_REST_BASE: 'https://ccc.vgcontent.info/vg-vgn/latest',
      LOGON_REST_BASE: 'https://logon.institutional.vanguard.com',
      IIG_BASE_URL: 'https://institutional.vanguard.com',
      LOGON_WEBSERVICE_BASE:
        'https://logon.institutional.vanguard.com/logon-api',
      TRANSMIT_URL: 'https://mfa.vanguard.com',
      VIE_BASE_URL: 'https://idde.vanguard.com',
    },
  },
  VGN_APP_PREFIX: '?appPrefix=IL1',
  LOGON_REST_PATH: '/logon-api',
  LOGON_REST_POSTFIX: '/logon/username-and-password',
  QNA_REST_POSTFIX: '/logon/question-and-answer',
  LOGON_REST_ENDPOINT: '/logon',
  REDIRECT_REST_POSTFIX: '/logon',
  CANCEL_REST_POSTFIX: '/logon/cancel',
  LOGOFF_REST_END_POINT: '/logon/logoff',
  B2BKONG_LOGOUT_END_POINT: '/b2blogon/logout',
  PW_CHANGE_REST_POSTFIX: '/logon/user/change-password',
  USER_REGISTRATION_POSTFIX: '/logon/user/register',
  CHALLENGE_OTP_RESEND_POSTFIX: '/logon/challenge-otp/resend',
  SECURIY_CODE_VALIDATION_POSTFIX: '/logon/challenge-otp',
  SECURIY_CODE_OTP_ENROLLMENT: '/logon/otp/enrollment',
  PASSWORD_LOOKUP_REST_ENDPOINT: '/logon/self-service/password',
  USERNAME_LOOKUP_REST_ENDPOINT: '/logon/self-service/username',
  SELF_SERVICE_CHALLENGE_ENDPOINT: '/logon/self-service/challenge',
  COUNTRY_PHONE_CODES: '/logon/country-phone-codes',
  TRANSMIT_EMAIL_NOTIFICATION_ENDPOINT: '/logon/transmit/sendEmail',
  VIE_FORM_BASE_PATH: '/service/account-maintenance',
  USER_DETAIL_ENDPOINT: '/logon/transmit/userDetail/',
  LEGACYLOGON: 'legacylogon/',
}
