import { AbstractControl, ValidatorFn, FormControl } from '@angular/forms'

export function sameUsernameValidator(username: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.parent) {
      return null
    }

    return control.value === username ? { sameUsername: true } : null
  }
}
