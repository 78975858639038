import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Location } from '@angular/common'
import { RegionResponse } from '@app/models/RegionResponse'
import { Observable } from 'rxjs'

@Injectable()
export class CaaS {
  constructor(private httpClient: HttpClient, private location: Location) {}

  getContent(url: string) {
    return this.httpClient.get(this.location.prepareExternalUrl(url))
  }

  getRegion(url: string): Observable<RegionResponse> {
    return this.httpClient.get<RegionResponse>(
      this.location.prepareExternalUrl(url),
    )
  }
}
