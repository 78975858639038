import { Injectable } from '@angular/core'
import { TransmitTicketPayload } from '@app/models/TransmitTicketPayload'
import {
  ACCOUNT_ID,
  ENROLL_TRANSACTION_TYPE,
  JOURNEY_ID,
  OOB_CHALLENGE_TYPE,
  POLICY_ID,
  SERVICE_ID,
  TICKET_APP_ID,
  TRANSMIT_TICKET_URL,
} from '@app/models/TransmitConstants'
import { SessionService } from '@app/shared/services/sessionService'

@Injectable({
  providedIn: 'root',
})
export class TransmitTicketHelperService {
  transmitTicketPayload: TransmitTicketPayload

  constructor(private session: SessionService) {}

  buildTransmitTicketPayload(
    channel: string,
    trans_type: string,
    phoneNumber: string,
    isEnroll: boolean,
    username: string,
    journeyName: string,
  ): TransmitTicketPayload {
    this.transmitTicketPayload = {
      user_id: this.session.poid,
      policy_id: POLICY_ID,
      app_id: TICKET_APP_ID,
      car: false,
      extendedTimeOutIndicator: false,
      oobChallengeType: OOB_CHALLENGE_TYPE,
      bound: 'false',
      channel: channel,
      accountId: ACCOUNT_ID,
      serviceId: SERVICE_ID,
      message: '1234',
      transactionType: trans_type,
      userName: username,
      userPrimaryPhoneNumber: phoneNumber,
      contactNumber: phoneNumber,
      journeyId: journeyName,
      isEnrollment: isEnroll,
      poid: this.session.poid,
    }
    return this.transmitTicketPayload
  }
}
