import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable()
export class WarningBannerService {
  private warningBannerMessage: BehaviorSubject<string> =
    new BehaviorSubject<string>('')
  private warningBannerMessage$: Observable<string> =
    this.warningBannerMessage.asObservable()

  constructor() {}

  setWarningBannerMessage(warningBannerMessage: string) {
    this.warningBannerMessage.next(warningBannerMessage)
  }

  getWarningBannerMessage(): Observable<string> {
    return this.warningBannerMessage$
  }
}
