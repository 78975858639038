import { Injectable } from '@angular/core'
import { environment } from '@env'

@Injectable({
  providedIn: 'root',
})
export class AdobeLaunchConfigurationService {
  baseField = {
    country: 'us',
    lang: 'en',
    platform: 'web',
    subDomain: 'institutional',
    subSection1: 'clientportal',
    subSection2: 'iis',
    pageTemplate: 'maintenance',
  }
  pageName: string
  colonSymbol = ':'
  userLoggedOut = 'logged out'
  userLoggedIn = 'logged in'
  siteSection2: string
  userStatus: string

  constructor() {
    this.pageName =
      this.baseField.country +
      this.colonSymbol +
      this.baseField.lang +
      this.colonSymbol +
      this.colonSymbol +
      this.baseField.platform +
      this.colonSymbol +
      this.baseField.subDomain +
      this.colonSymbol +
      this.baseField.subSection1 +
      this.colonSymbol +
      this.baseField.subSection2
  }

  getDataLayer() {
    ;(window as any).dataLayer = (window as any).dataLayer || []
    return (window as any).dataLayer
  }

  trackPageLoadCompletedEvent() {
    this.getDataLayer().push({
      event: 'pageLoadCompleted',
    })
  }

  trackUserSignedOut() {
    this.getDataLayer().push({
      event: 'userSignedOut',
    })
  }

  trackOobSignUp() {
    this.getDataLayer().push({
      event: 'callToAction',
      callToAction: {
        location: this.siteSection2,
        ctaName: 'Sign Up',
        ctaType: 'button',
      },
    })
  }

  trackResendSecurityCode() {
    this.getDataLayer().push({
      event: 'callToAction',
      callToAction: {
        location: this.siteSection2,
        ctaName: 'send a new code',
        ctaType: 'button',
      },
    })
  }

  trackAuthUserSecurityCode(rememberThisComputer) {
    this.getDataLayer().push({
      event: 'callToAction',
      callToAction: {
        location: this.siteSection2,
        ctaName: 'continue',
        ctaType: rememberThisComputer,
      },
    })
  }

  trackOpenTipsLayer(additionalInfo) {
    this.getDataLayer().push({
      event: 'navigationPopup',
      navigationPopup: {
        linkName: additionalInfo,
      },
    })
  }

  trackLogonLink() {
    this.getDataLayer().push({
      event: 'onSiteLinkClick',
      onSiteLink: {
        linkName: 'logon|retirement participant experience',
      },
    })
  }

  trackUserLogStatus(status: string) {
    this.getDataLayer().push({
      event: 'userDetected',
      user: {
        sPOID: '',
        crmContactID: '',
        crewsPOID: '',
        loginStatus: status,
        businessLine: '',
        crmCompanyNumber: '',
        companyTier: '',
      },
    })
  }

  trackUserLoggedOut() {
    this.getDataLayer().push({
      event: 'pageLoaded',
      page: {
        pageName: this.pageName,
        pageTitle: document.title.toLowerCase(),
        siteSection1: this.baseField.subSection1,
        siteSection2: this.baseField.subSection2,
        is404: false,
        DLVersion: '1.0.0',
        platform: this.baseField.platform,
        siteIdentifier: '',
        pageTemplate: this.baseField.pageTemplate,
      },
    })
    this.trackUserLogStatus(this.userLoggedOut)
    this.trackUserSignedOut()
    this.trackPageLoadCompletedEvent()
  }

  trackPageLoad(fromPage: string) {
    switch (fromPage) {
      case environment.OUT_OF_BAND: {
        this.siteSection2 = environment.ADOBE_OOB_SITE_SECTION_2
        this.userStatus = this.userLoggedIn
        break
      }
      case environment.SELF_SERVICE_QA: {
        this.siteSection2 =
          environment.ADOBE_SELF_SERVICE_SITE_USERNAME_SECTION_2
        this.userStatus = this.userLoggedOut
        break
      }
      case environment.SELF_SERVICE_PASSWD: {
        this.siteSection2 =
          environment.ADOBE_SELF_SERVICE_SITE_PASSWORD_SECTION_2
        this.userStatus = this.userLoggedOut
        break
      }
      case environment.USERNAME_AND_PASSWORD: {
        this.siteSection2 = environment.ADOBE_LOGON_SITE_SECTION_2
        this.userStatus = this.userLoggedOut
        break
      }
      case environment.SECURITY_CODE: {
        this.siteSection2 = environment.ADOBE_LOGON_SITE_SECTION_3
        this.userStatus = this.userLoggedOut
        break
      }
      default: {
        this.siteSection2 = ''
      }
    }
    this.getDataLayer().push({
      event: 'pageLoaded',
      page: {
        pageName:
          this.baseField.country +
          this.colonSymbol +
          this.baseField.lang +
          this.colonSymbol +
          environment.ADOBE_SITE_IDENTIFIER +
          this.colonSymbol +
          this.baseField.platform +
          this.colonSymbol +
          this.baseField.subDomain +
          this.colonSymbol +
          environment.ADOBE_SITE_SECTION_1 +
          this.colonSymbol +
          this.siteSection2,
        pageTitle: document.title.toLowerCase(),
        siteSection1: environment.ADOBE_SITE_SECTION_1,
        siteSection2: this.siteSection2,
        is404: false,
        DLVersion: '1.0.0',
        platform: this.baseField.platform,
        siteIdentifier: environment.ADOBE_SITE_IDENTIFIER,
        pageTemplate: '',
      },
    })
    this.trackUserLogStatus(this.userStatus)
    this.trackPageLoadCompletedEvent()
  }
}
