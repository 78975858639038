<div>
    <vui-button type="submit" id="cancelBtn" (click)="openCancelLayer()" [style]="'secondary'">
        {{content.Data.cancelLayer.content.cancelBtn}}
    </vui-button>
    <div id="cancelDialogBoxIdQnA">
        <ng-template #dialogCancelLayer>
            <div class="vui-dialog-header">
                <span>
                    <vui-icon [icon]="warn" class="vui-dialog-icon warning"></vui-icon>
                </span>
                <div id="areYouSureIdQnA" class="vui-dialog-title">{{content.Data.cancelLayer.content.cancelLayerQuestion}}</div>
            </div>
            <div class="vui-dialog-body-content">
                <div id="textDiaologBoxInnerIdQnA" class="para-class">{{content.Data.cancelLayer.content.cancelLayerSubtext}}</div>
            </div>
            <div class="button-container">
                <vui-button [style]="'secondary'" id="cancelBtnNoQnA" (click)="closeDialog()">{{content.Data.cancelLayer.content.confirmCanceNo}}</vui-button>
                <vui-button type="submit" [style]="'secondary'" id="cancelBtnYesQnA" (click)="confirmCancel()">{{content.Data.cancelLayer.content.confirmCanceYes}}</vui-button>
            </div>
        </ng-template>
    </div>
</div>