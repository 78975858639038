import { AbstractControl, ValidatorFn } from '@angular/forms'

export function twoAlphaTwoNumValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value
    let i = 0
    let flag = true
    const result: any = {
      letters: 0,
      numbers: 0,
      notUSSpecialCharacter: false,
    }
    if (value) {
      for (i; i < value.length; i++) {
        if (value.charCodeAt(i) < 33 || value.charCodeAt(i) > 126) {
          result.notUSSpecialCharacter = true
        }
        if (isNaN(value.charAt(i))) {
          if (
            (value.charCodeAt(i) > 64 && value.charCodeAt(i) < 91) ||
            (value.charCodeAt(i) > 96 && value.charCodeAt(i) < 123)
          ) {
            result.letters++
          }
        } else {
          result.numbers++
        }
      }
      if (
        result.letters > 1 &&
        result.numbers > 1 &&
        !result.notUSSpecialCharacter
      ) {
        flag = false
      } else {
        flag = true
      }
    }
    return flag ? { invalidTwoAlphaTwoNum: true } : null
  }
}
