<span id="termsCheckboxContent" class="font-size-16">
  <a id="oobTermsLink"
     class="link-without-href font-family-UBM font-size-16 font-weight-normal nowrap-white-space underline-border"
     dialogClass="vui-dialog vui-dialog-simple"
     tabindex="0" (click)="openTermsLayer()" (keydown.enter)="openTermsLayer()">
          {{content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobTermsLink}}
  </a>
</span>
<ng-template #OOBTermsDialog>
  <div class="padding-8">
    <div class="vui-dialog-title scroll-bar">
      <h2 id="oobCodeTCHeader"
          class="vui-dialog-title">{{content.Data.oobSecurityPage.content.oobTerms.oobTerms.headerText}}</h2>
    </div>
    <div class="text-align-left">
      <div class="vui-dialog-body-content tc-dialiog-box font-size-14 font-family-UBR" tabindex="0">
        <p>{{content.Data.oobSecurityPage.content.oobTerms.oobTerms.instructions}}</p><br>
        <p>{{content.Data.oobSecurityPage.content.oobTerms.oobTerms.additionalInfo}}</p><br>
        <p>{{content.Data.oobSecurityPage.content.oobTerms.oobTerms.acknowledgedInfo}}</p><br>
        <ul>
          <li class="tcDetails-bullet" *ngFor="let item of [].constructor(13); let i = index">
            <span *ngIf="i == 5">{{bulletContent(i + 1)}}
              <u>{{content.Data.oobSecurityPage.content.oobTerms.oobTerms.tcDetails.tcDetails.bullet6Underlined}}</u></span>
            <span *ngIf="i != 5">{{bulletContent(i + 1)}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

