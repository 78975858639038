import {
  Component,
  ViewChild,
  Renderer2,
  OnInit,
  AfterViewChecked,
} from '@angular/core'
import { environment } from '@env'
import { RegionService } from '@app/shared/services/regionService'
import { ContentService } from '@app/shared/services/contentService'

@Component({
  selector: 'global-header',
  templateUrl: './globalHeader.component.html',
  styleUrls: ['./globalHeader.component.scss'],
})
export class GlobalHeaderComponent implements OnInit {
  @ViewChild('header', { static: false }) header: { nativeElement: any }
  content: any
  domain: string
  links: any

  constructor(
    private readonly renderer: Renderer2,
    private readonly regionService: RegionService,
    private readonly contentService: ContentService,
  ) {}

  ngOnInit(): void {
    this.content = this.modifyGlobalNavObj(
      this.contentService.caasContent.Data.globalNav.content,
    )
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'globalHeaderFooterScript'
    script.src =
      environment[this.regionService.envType][this.regionService.userType]
        .VGN_REST_BASE +
      '/js/vgn.min.js' +
      environment.VGN_APP_PREFIX
    document.body.appendChild(script)

    const link = this.renderer.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href =
      environment[this.regionService.envType][this.regionService.userType]
        .VGN_REST_BASE +
      '/css/vgn.min.css' +
      environment.VGN_APP_PREFIX
    document.body.appendChild(link)
  }

  modifyTabIndex(element, number) {
    if (element != null) {
      return (element.tabIndex = number)
    }
  }

  modifyGlobalNavObj(globalNavObj): any {
    this.domain = this.getDomain(this.regionService.envType)
    globalNavObj.subUtilityBar = {}
    globalNavObj.navItems = []
    return this.addDomainToGlobalNav(this.matchTeamSiteLayout(globalNavObj))
  }

  addDomainToGlobalNav(globalNavObj): any {
    globalNavObj.logo.href = this.domain + globalNavObj.logo.href
    this.links = globalNavObj.legal.links
    for (let i = 0; i < globalNavObj.legal.links.length; i++) {
      if (!this.links[i].href.includes(environment.HTTP)) {
        this.links[i].href = this.domain + globalNavObj.legal.links[i].href
      }
    }
    globalNavObj.legal.links = this.links
    return globalNavObj
  }

  getDomain(region): string {
    if (region.includes(environment.PRODUCTION)) {
      return this.contentService.caasContent.Data.logonPage.content
        .institutionalDomain.institutionalDomain.prodDomain
    }
    return this.contentService.caasContent.Data.logonPage.content
      .institutionalDomain.institutionalDomain.satDomain
  }

  //Code to convert the AEM content format to match global navigation layout
  matchTeamSiteLayout(globalNavObj): any {
    if (globalNavObj.logo.hasOwnProperty('logo')) {
      const logo = { href: globalNavObj.logo.logo.href }
      globalNavObj.logo = logo
    }

    if (globalNavObj.legal.hasOwnProperty('legal')) {
      const legal = {
        boilerplate: globalNavObj.legal.legal.boilerplate,
        copyright: globalNavObj.legal.legal.copyright,
        feedbackDisabled: globalNavObj.legal.legal.feedbackDisabled,
        links: [],
        termsAndConditions: globalNavObj.legal.legal.termsAndConditions,
        termsAndConditionsLink: globalNavObj.legal.legal.termsAndConditionsLink,
      }

      legal.links.push(globalNavObj.legal.legal.links[0])
      legal.links.push(globalNavObj.legal.legal.links[1])
      legal.links.push(globalNavObj.legal.legal.links[2])
      legal.links.push(globalNavObj.legal.legal.links[3])

      globalNavObj.legal = legal
    }

    if (globalNavObj.sitePreferences.hasOwnProperty('sitePreferences')) {
      const sitePreferences = {
        siteList: {
          label:
            globalNavObj.sitePreferences.sitePreferences.siteList.siteList
              .label,
          mobileLabel:
            globalNavObj.sitePreferences.sitePreferences.siteList.siteList
              .mobileLabel,
        },
      }

      globalNavObj.sitePreferences = sitePreferences
    }

    if (globalNavObj.verticalNav.hasOwnProperty('verticalNav')) {
      const verticalNav = {
        back: {
          altText: globalNavObj.verticalNav.verticalNav.back['back-1'].altText,
        },
        label: globalNavObj.verticalNav.verticalNav.label,
      }

      globalNavObj.verticalNav = verticalNav
    }

    globalNavObj.utilityItems = ''

    return globalNavObj
  }
}
