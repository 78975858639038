import { Injectable } from '@angular/core'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'

declare const com: any
const sdk = XmSdk()

export function TransmitUiHandler() {
  XmSdk.call(this)
}

@Injectable({
  providedIn: 'root',
})
export class CommonUiHandlerService {
  constructor() {}

  getCommonUiHandler() {
    TransmitUiHandler.prototype.constructor = TransmitUiHandler

    TransmitUiHandler.prototype.controlFlowStarting =
      function controlFlowStarting(clientContext) {}

    TransmitUiHandler.prototype.controlFlowCancelled =
      function controlFlowCancelled(clientContext) {}

    TransmitUiHandler.prototype.controlFlowEnded = function controlFlowEnded(
      error,
      clientContext,
    ) {}

    TransmitUiHandler.prototype.controlFlowActionStarting =
      function controlFlowActionStarting(policyAction, clientContext) {}

    TransmitUiHandler.prototype.controlFlowActionEnded =
      function controlFlowActionEnded(error, actionContext, clientContext) {}

    TransmitUiHandler.prototype.handlePolicyRejection =
      function handlePolicyRejection(
        title,
        text,
        buttonText,
        failureData,
        actionContext,
        clientContext,
      ) {
        return Promise.resolve(com.ts.mobile.sdk.ConfirmationInput.create(-1))
      }

    TransmitUiHandler.prototype.startActivityIndicator =
      function startActivityIndicator(clientContext) {}

    TransmitUiHandler.prototype.endActivityIndicator =
      function endActivityIndicator(clientContext) {}

    TransmitUiHandler.prototype.handlePolicyRedirect = (
      redirectType,
      policyId,
      userId,
      additionalParameters,
      clientContext,
    ) => {
      return new Promise((resolve) => {
        resolve(
          com.ts.mobile.sdk.RedirectInput.create(
            com.ts.mobile.sdk.RedirectResponseType.RedirectToPolicy,
          ),
        )
      })
    }

    TransmitUiHandler.prototype.shouldIncludeDisabledAuthenticatorsInMenu = (
      actionContext,
      clientContext,
    ) => {
      return false
    }

    TransmitUiHandler.prototype.getInformationResponse = this.createOkResponse

    return new TransmitUiHandler()
  }

  sdkSetUiHandler(uiHandler: object) {
    sdk.setUiHandler(uiHandler)
  }

  private createOkResponse(): Promise<unknown> {
    const okResponseNumber = -1
    return Promise.resolve(
      com.ts.mobile.sdk.ConfirmationInput.create(okResponseNumber),
    )
  }
}
