import {
  Directive,
  HostListener,
  ElementRef,
  Renderer2,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { FormControl } from '@angular/forms'

@Directive({
  selector: '[phoneFormatter]',
})
export class PhoneFormatterDirective implements OnChanges {
  @Input('phoneFormatter') formControl: FormControl
  // tslint:disable-next-line:no-input-rename
  @Input('countryCode') countryCode: string

  borderElement: any
  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnChanges() {
    const value = this.formControl.value.trim()
    if (this.countryCode !== undefined && this.formControl.value !== '') {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'value',
        this.formControl.value.trim(),
      )
    } else if (
      this.countryCode !== undefined &&
      this.formControl.value === ''
    ) {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'value',
        ` ${value}`,
      )
    } else {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'value',
        `${value}`,
      )
    }
  }

  @HostListener('blur') onBlur() {
    const value = this.formControl.value.trim()
    if (this.countryCode !== undefined && this.formControl.value !== '') {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'value',
        this.formControl.value.trim(),
      )
    } else if (
      this.countryCode !== undefined &&
      this.formControl.value === ''
    ) {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'value',
        ` ${value}`,
      )
    } else {
      const formattedValue = this.formControl.value.replace(
        /^(\d{3})(\d{3})(\d{4})+$/,
        '$1$2$3',
      )
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'value',
        formattedValue,
      )
    }
  }

  @HostListener('click') onClick() {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      this.formControl.value.trim(),
    )
  }
}
