<spinner [active]="loading"></spinner>
<div class="view-container">
  <c11n-tabs activeItem={{activeTab}} (activeItemChange)="onTabChange($event)" smallResponsiveBreakpoint="375" variant="left-aligned" overflowOption="nav-overflow" hasAnimation="true" class="self-service-c11n-tabs">
     
    <c11n-tab-panel id="forgotUserNameTab" labelText="{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.forgotUserNameTab}}">
      <ng-container *ngTemplateOutlet="forgotUsernameTemplate"></ng-container>
    </c11n-tab-panel>
    
    <c11n-tab-panel id="forgotPasswordTab" labelText="{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.forgotPasswordTab}}">
      <ng-container *ngTemplateOutlet="forgotPasswordTemplate"></ng-container>
    </c11n-tab-panel>

    <!-- FORGOT USERNAME STARTS HERE -->
    <ng-template #forgotUsernameTemplate>
      <div class="c11n-text-crop">
          <p class="margin-bottom-16 self-service-c11n-info-header-p">
            <span id="infoTextUsername">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.forgotUserTabDesc}}</span>
          </p>
           <form [formGroup]="selfServiceUsernameForm" id="selfServiceUsername" name="selfServiceUsername">
            <table class="responsive-left-padding">
                <div class="margin-top-24 self-service-input-fields-div">

                  <label c11n-label [for]="'firstName'" id="firstNameLabel" class="self-service-c11n-label">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.firstNameFieldLabel}} </label>
                  <c11n-input [hasError]="selfServiceUsernameForm?.get('firstName')?.touched && selfServiceUsernameForm.get('firstName')?.errors">
                    <input 
                      c11nInput
                      inputmode="text"
                      id="firstName" 
                      name="firstName"
                      aria-describedby="firstNameError"
                      formControlName="firstName"
                      placeholder={{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.firstNamePlaceHolder}}
                      tabindex=0
                      autofocus
                      />
                  </c11n-input>
    
                  <c11n-hint-error
                    [hasError]="selfServiceUsernameForm?.get('firstName')?.touched && selfServiceUsernameForm?.get('firstName')?.hasError('required')"
                    [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.firstNameRequired"
                    [hintErrorId]="'firstNameError'">
                  </c11n-hint-error>
                  <c11n-hint-error
                    [hasError]="selfServiceUsernameForm?.get('firstName')?.touched && selfServiceUsernameForm?.get('firstName')?.hasError('pattern')"
                    [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.firstNameIncorrectValue"
                    [hintErrorId]="'firstNameError'">
                  </c11n-hint-error>
                </div>
    
                <div class="self-service-input-fields-div">
                  <label c11n-label [for]="'lastName'" id="lastNameLabel" class="self-service-c11n-label">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.lastNameFieldLabel}} </label>
                  <c11n-input [hasError]="selfServiceUsernameForm?.get('lastName')?.touched && selfServiceUsernameForm.get('lastName')?.errors">
                    <input 
                      c11nInput
                      inputmode="text"
                      id="lastName" 
                      name="lastName"
                      aria-describedby="lastNameError"
                      formControlName="lastName"
                      placeholder={{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.lastNamePlaceHolder}}
                      />
                  </c11n-input>
    
                  <c11n-hint-error
                    [hasError]="selfServiceUsernameForm?.get('lastName')?.touched && selfServiceUsernameForm?.get('lastName')?.hasError('required')"
                    [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.lastNameRequired"
                    [hintErrorId]="'lastNameError'">
                  </c11n-hint-error>
                  <c11n-hint-error
                    [hasError]="selfServiceUsernameForm?.get('lastName')?.touched && selfServiceUsernameForm?.get('lastName')?.hasError('pattern')"
                    [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.lastNameIncorrectValue"
                    [hintErrorId]="'lastNameError'">
                  </c11n-hint-error>
                </div>
    
                <div class="self-service-input-fields-div">
                  <label c11n-label [for]="'email'" id="emailLabel">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.emailFieldLabel}}</label>
                  <c11n-input [hasError]="selfServiceUsernameForm?.get('email')?.touched && selfServiceUsernameForm.get('email')?.errors">
                    <input 
                      c11nInput
                      inputmode="text"
                      id="email" 
                      name="email"
                      aria-describedby="emailError"
                      formControlName="email"
                      placeholder={{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.emailPlaceHolder}}
                      />
                  </c11n-input>
    
                  <c11n-hint-error
                    [hasError]="selfServiceUsernameForm?.get('email')?.touched && selfServiceUsernameForm?.get('email')?.hasError('required')"
                    [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.emailRequired"
                    [hintErrorId]="'emailError'">
                  </c11n-hint-error>
                  <c11n-hint-error
                    [hasError]="selfServiceUsernameForm?.get('email')?.touched && selfServiceUsernameForm?.get('email')?.hasError('email')"
                    [errorText]="content.Data.selfServicePage.content.emailInvalidFormat"
                    [hintErrorId]="'emailError'">
                  </c11n-hint-error>
                </div>
    
                <div [hidden]="!isMpmSelfService">
                    <div class="margin-bottom-10 margin-top-8 row margin-right-8">
    
                      <c11n-checkbox [isIndeterminate]="false" [labelText]="content.Data.selfServicePage.content.selfServiceForm.selfServiceForm.mpmUserInfoLabel" [isDisabled]="false" 
                         [variant]="'default'" [labelWeight]="'normal'" (stateChange)="onCheckBoxStateChanged($event)">
                        <input c11nCheckboxInput 
                          (click)="onCheckBoxStateChanged($event)" 
                          class="c11n-checkbox__input" 
                          id="mpmUserCheckbox" 
                          type="checkbox" 
                          name="mpmUserCheckbox" 
                          value="checkedMmpUser" 
                          formControlName="mpmUserCheckbox"/>
                      </c11n-checkbox>
                    </div>
    
                    <div class="margin-top-24 self-service-input-fields-div" [hidden]="!checkedMmpUser">
                      <label c11n-label [for]="'planNumber'" id="planNumberLabel" class="self-service-c11n-label">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.planNumberFieldLabel}} </label>
                      <c11n-input [hasError]="selfServiceUsernameForm?.get('planNumber')?.touched && selfServiceUsernameForm.get('planNumber')?.errors">
                          <input 
                            c11nInput
                            inputmode="text"
                            id="planNumber" 
                            name="planNumber"
                            aria-describedby="planNumberError"
                            formControlName="planNumber"
                            placeholder={{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.planNumberPlaceHolder}}
                            />
                      </c11n-input>
                        <c11n-hint-error
                          [hasError]="selfServiceUsernameForm?.get('planNumber')?.touched && (selfServiceUsernameForm?.get('planNumber')?.hasError('required') || selfServiceUsernameForm?.get('planNumber')?.hasError('pattern') || selfServiceUsernameForm.get('planNumber')?.hasError('minlength') || selfServiceUsernameForm.get('planNumber')?.hasError('maxlength'))"
                          [errorText]="content.Data.selfServicePage.content.resetCredentialsErrorMsg.resetCredentialsErrorMsg.planNumberInvalidFormat"
                          [hintErrorId]="'planNumberError'">
                        </c11n-hint-error>
                    </div>
                </div>
            </table>
            <div class="d-flex self-service-button-div">
              <c11n-button 
                id="cancelBtn" 
                buttonType="button"
                labelText={{content.Data.challengePage.content.cancelBtn}}
                variant="secondary"
                (clickEvent)="cancel()"
                >
              </c11n-button>
              <c11n-button class="margin-left-40 continue-btn" 
                id="continueBtn"
                name="continueBtn"
                buttonType="submit"
                labelText={{content.Data.challengePage.content.continueBtn}}
                variant="primary"
                (clickEvent)="userNameLookUp()"
                >
            </c11n-button>
            </div>
           </form>
      </div>
    </ng-template>

     <!-- FORGOT PASSWORD STARTS HERE -->
    <ng-template #forgotPasswordTemplate>
      <div class="c11n-text-crop">
        <p class="margin-bottom-16 self-service-c11n-info-header-p">
          <span id="infoTextPassword">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.forgotPassTabDesc}}</span>
        </p>
         <form [formGroup]="selfServicePasswordForm" id="selfServicePassword" name="selfServicePassword">
          <table class="responsive-left-padding">
            <div class="margin-top-24 self-service-input-fields-div">

              <label c11n-label [for]="'fName'" id="fNameLabel" class="self-service-c11n-label">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.firstNameFieldLabel}} </label>
              <c11n-input [hasError]="selfServicePasswordForm?.get('fName')?.touched && selfServicePasswordForm.get('fName')?.errors">
                <input 
                  c11nInput
                  inputmode="text"
                  id="fName" 
                  name="fName"
                  aria-describedby="firstNameError"
                  formControlName="fName"
                  placeholder={{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.firstNamePlaceHolder}}
                  tabindex=0
                  autofocus
                  />
              </c11n-input>

              <c11n-hint-error
                [hasError]="selfServicePasswordForm?.get('fName')?.touched && selfServicePasswordForm?.get('fName')?.hasError('required')"
                [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.firstNameRequired"
                [hintErrorId]="'firstNameError'">
              </c11n-hint-error>

              <c11n-hint-error
                [hasError]="selfServicePasswordForm?.get('fName')?.touched && selfServicePasswordForm?.get('fName')?.hasError('pattern')"
                [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.firstNameIncorrectValue"
                [hintErrorId]="'firstNameError'">
              </c11n-hint-error>
            </div>

            <div class="self-service-input-fields-div">
              <label c11n-label [for]="'lName'" id="lNameLabel" class="self-service-c11n-label">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.lastNameFieldLabel}}</label>
              <c11n-input [hasError]="selfServicePasswordForm?.get('lName')?.touched && selfServicePasswordForm.get('lName')?.errors">
                <input 
                  c11nInput
                  inputmode="text"
                  id="lName" 
                  name="lName"
                  aria-describedby="lastNameError"
                  formControlName="lName"
                  placeholder={{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.lastNamePlaceHolder}}
                  />
              </c11n-input>

              <c11n-hint-error
                [hasError]="selfServicePasswordForm?.get('lName')?.touched && selfServicePasswordForm?.get('lName')?.hasError('required')"
                [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.lastNameRequired"
                [hintErrorId]="'lastNameError'">
              </c11n-hint-error>
              
              <c11n-hint-error
                [hasError]="selfServicePasswordForm?.get('lName')?.touched && selfServicePasswordForm?.get('lName')?.hasError('pattern')"
                [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.lastNameIncorrectValue"
                [hintErrorId]="'lastNameError'">
              </c11n-hint-error>
            </div>

            <div class="self-service-input-fields-div">
              <label c11n-label [for]="'email2'" id="email2Label" class="self-service-c11n-label">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.emailFieldLabel}}</label>
              <c11n-input [hasError]="selfServicePasswordForm?.get('email2')?.touched && selfServicePasswordForm.get('email2')?.errors">
                <input 
                  c11nInput
                  inputmode="text"
                  id="email2" 
                  name="email2"
                  aria-describedby="emailError"
                  formControlName="email2"
                  placeholder={{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.emailPlaceHolder}}
                  />
              </c11n-input>

              <c11n-hint-error
                [hasError]="selfServicePasswordForm?.get('email2')?.touched && selfServicePasswordForm?.get('email2')?.hasError('required')"
                [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.emailRequired"
                [hintErrorId]="'emailError'">
              </c11n-hint-error>
              <c11n-hint-error
                [hasError]="selfServicePasswordForm?.get('email2')?.touched && selfServicePasswordForm?.get('email2')?.hasError('email')"
                [errorText]="content.Data.selfServicePage.content.emailInvalidFormat"
                [hintErrorId]="'emailError'">
              </c11n-hint-error>
            </div>
  
            <div class="self-service-input-fields-div">
                <label c11n-label [for]="'userName'" id="userNameLabel" class="self-service-c11n-label">{{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.userNameFieldLabel}}</label>
                <c11n-input [hasError]="selfServicePasswordForm?.get('userName')?.touched && selfServicePasswordForm.get('userName')?.errors">
                  <input 
                    c11nInput
                    inputmode="text"
                    id="userName" 
                    name="userName"
                    aria-describedby="userNameError"
                    formControlName="userName"
                    placeholder={{content.Data.selfServicePage.content.resetCredentialsForm.resetCredentialsForm.userNamePlaceHolder}}
                  />
                </c11n-input>
                <c11n-hint-error
                  [hasError]="selfServicePasswordForm?.get('userName')?.touched && selfServicePasswordForm?.get('userName')?.hasError('required')"
                  [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernameRequired"
                  [hintErrorId]="'userNameError'">
                </c11n-hint-error>
            </div>
          </table>
  
          <div class="d-flex self-service-button-div">
            <c11n-button 
              id="cancelBtn" 
              buttonType="button"
              labelText={{content.Data.challengePage.content.cancelBtn}}
              variant="secondary"
              (clickEvent)="cancel()"
              >
            </c11n-button>
            <c11n-button class="margin-left-40 continue-btn" 
              id="continueBtn"
              name="continueBtn"
              buttonType="submit"
              labelText={{content.Data.challengePage.content.continueBtn}}
              variant="primary"
              (clickEvent)="passwordLookUp()"
              >
          </c11n-button>
          </div>
         </form>
      </div>
    </ng-template>  
  </c11n-tabs>
</div>