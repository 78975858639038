import { Injectable } from '@angular/core'
import { ErrorMsg } from '../../errorMsg/errorMsg'

@Injectable()
export class ErrorMsgService {
  constructor() {}

  private errorMsg: ErrorMsg = {}

  get invalidMessage() {
    return this.errorMsg.invalidMessage
  }

  set invalidMessage(invalidMessage: string) {
    this.errorMsg.invalidMessage = invalidMessage
  }

  get invalidOTPMessage() {
    return this.errorMsg.invalidOTPMessage
  }

  set invalidOTPMessage(invalidOTPMessage: string) {
    this.errorMsg.invalidOTPMessage = invalidOTPMessage
  }

  get popUpMsg() {
    return this.errorMsg.popUpMsg
  }

  set popUpMsg(popUpMsg: string) {
    this.errorMsg.popUpMsg = popUpMsg
  }

  get loading() {
    return this.errorMsg.loading
  }

  set loading(loading: boolean) {
    this.errorMsg.loading = loading
  }

  get clearForm() {
    return this.errorMsg.clearForm
  }

  set clearForm(clearForm: boolean) {
    this.errorMsg.clearForm = clearForm
  }

  get clearTempPasswordField() {
    return this.errorMsg.clearTempPasswordField
  }

  set clearTempPasswordField(clearTempPasswordField: boolean) {
    this.errorMsg.clearTempPasswordField = clearTempPasswordField
  }

  get incorrectSecurityAnswer() {
    return this.errorMsg.incorrectSecurityAnswer
  }

  set incorrectSecurityAnswer(incorrectSecurityAnswer: boolean) {
    this.errorMsg.incorrectSecurityAnswer = incorrectSecurityAnswer
  }

  get openOtpPopUp() {
    return this.errorMsg.openOtpPopUp
  }

  set openOtpPopUp(openOtpPopUp: boolean) {
    this.errorMsg.openOtpPopUp = openOtpPopUp
  }

  clearAll() {
    this.errorMsg = {
      invalidMessage: '',
      invalidOTPMessage: '',
      popUpMsg: '',
      loading: false,
      incorrectSecurityAnswer: false,
      clearTempPasswordField: false,
      clearForm: false,
      openOtpPopUp: false,
    }
  }
}
