import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { ContentService } from '@app/shared/services/contentService'
import { DialogComponent, VuiDialogConfig } from '@vanguard/vui/dialog'

@Component({
  selector: 'international-oob-terms',
  templateUrl: './international-oob-terms.component.html',
  styleUrls: ['./international-oob-terms.component.scss'],
  providers: [DialogComponent],
})
export class InternationalOobTermsComponent implements OnInit {
  content: any
  @ViewChild('OOBTermsDialog', { static: false })
  private ooBTermsDialog: TemplateRef<any>

  vuiDialogConfig: VuiDialogConfig = {
    dialogType: 'simple',
    backdropDismiss: true,
  }

  constructor(
    private contentService: ContentService,
    private viewContainer: ViewContainerRef,
    public vuiDialog: DialogComponent,
  ) {}

  ngOnInit(): void {
    this.content = this.contentService.caasContent
  }

  openTermsLayer() {
    this.vuiDialog.openDialog(
      this.viewContainer,
      this.ooBTermsDialog,
      this.vuiDialogConfig,
    )
  }
}
