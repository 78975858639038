import { Inject, Injectable } from '@angular/core'
import { Router, NavigationStart, RouteConfigLoadEnd } from '@angular/router'
import { interval } from 'rxjs'
import { take, filter } from 'rxjs/operators'
import { WindowRefService } from '../window-ref/window-ref.service'

@Injectable()
export class AdobeAnalyticsService {
  $window: { adobeAnalyticsImpl: any }
  MAX_RETRY = 30
  WAIT_TIME = 100

  constructor(private windowRef: WindowRefService, private router: Router) {
    this.$window = windowRef.nativeWindow
    this.setRouteListeners(router)
  }

  /**
   * Creates common listeners to properly set the previous page name
   * on route changes.  The AllAppMeasurement.js file has the onLoad
   * and beforeUnload functions that properly set/read the property.
   *
   * @param router
   */
  private setRouteListeners(router) {
    /**
     * On NavigationStart, the active page name will be written to
     * the s_sess cookie
     */
    router.events
      .pipe(filter((event: any) => event instanceof NavigationStart))
      .subscribe(() => {
        this.adobeAnalyticsImpl().then(
          (impl: any) => {
            impl.beforeUnload()
          },
          () => {},
        )
      })
    /**
     * On RouteConfigLoadEnd, the previous pageName is read from the s_sess
     * cookie and set on the adobe contextData
     *
     * This block is ignored for coverage because we are unable to execute
     * this Route event directly in unit tests.
     */
    /* istanbul ignore next */
    router.events
      .pipe(filter((event: any) => event instanceof RouteConfigLoadEnd))
      .subscribe(() => {
        this.adobeAnalyticsImpl().then(
          (impl: any) => {
            impl.onLoad()
          },
          () => {},
        )
      })
  }

  private log(error: string) {
    // this.logger.warn({event: 'adobe-analytics-failure', error: error});
  }

  private adobeAnalyticsImpl() {
    return new Promise((resolve, reject) => {
      let adobeAnalyticsImpl = this.$window.adobeAnalyticsImpl

      if (adobeAnalyticsImpl) {
        resolve(adobeAnalyticsImpl)
        return
      }

      const intervalObs = interval(this.WAIT_TIME).pipe(take(this.MAX_RETRY))

      const subscription = intervalObs.subscribe((count) => {
        adobeAnalyticsImpl = this.$window.adobeAnalyticsImpl

        if (adobeAnalyticsImpl) {
          subscription.unsubscribe()
          resolve(adobeAnalyticsImpl)
        } else if (count >= this.MAX_RETRY - 1) {
          this.log('window.adobeAnalyticsImp missing')
          reject()
        }
      })
    })
  }

  public updateData(data: any) {
    this.adobeAnalyticsImpl().then(
      (impl: any) => {
        impl.updateProperties(data)
      },
      () => {},
    )
  }

  public track() {
    this.adobeAnalyticsImpl().then(
      (impl: any) => {
        impl.track()
      },
      () => {},
    )
  }
  /**
   * @param name the name of the link to appear in link report
   * @param type option [(o)ther, (d)ownload, (e)xit]
   */
  public trackLink(name: string, linkType = 'o') {
    return this.adobeAnalyticsImpl().then(
      (impl: any) => {
        return new Promise((resolve) => {
          impl.trackLink({ name: name, type: linkType, callback: resolve })
        })
      },
      () => {},
    )
  }
} /* istanbul ignore next */
