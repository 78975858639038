import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpXsrfTokenExtractor,
} from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
  private readonly CSRF_HEADER_NAME: string = 'X-XSRF-TOKEN'
  private readonly CSRF_HTTP_METHODS: string[] = ['PUT', 'POST', 'DELETE']
  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      this.CSRF_HTTP_METHODS.indexOf(httpRequest.method) >= 0 &&
      !httpRequest.headers.has(this.CSRF_HEADER_NAME)
    ) {
      const csrfCookieValue = this.tokenExtractor.getToken()

      if (csrfCookieValue !== null) {
        httpRequest = httpRequest.clone({
          headers: httpRequest.headers.set(
            this.CSRF_HEADER_NAME,
            csrfCookieValue,
          ),
        })
      }
    }
    return next.handle(httpRequest)
  }
}
