<spinner [active]="loading"></spinner>
<div id ="displayQA">
    <div class="view-container">
        <div class="row">
            <div class="col-12 margin-top-16 margin-bottom-40">
                <tr>
                    <th class="text-align-left">
                        <p class="self-service-c11n-info-header-p">
                            {{content.Data.challengePage.content.message.message.verifyMsg}} {{content.Data.challengePage.content.message.message.recognizeMsg}}
                        </p>
                    </th>
                </tr>
            </div>
        </div>
        <div class="row">
            <form [formGroup]="challengeForm" id="challengeQA" name="challengeQA">
                <div class="col-12 margin-bottom-56">
                    <table class="table-body-regular-font mobile-width-100 margin-bottom-16">
                        <tbody>
                            <tr>
                                <th class="mobile-display-block mobile-padding-bottom-8 userNameLabel">
                                    <label for="userName" id="userNameLabel" class="inline-block">{{content.Data.challengePage.content.userNameLabel}}</label>
                                </th>
                                <td class="text-align-left inline-block mobile-display-block mobile-padding-bottom-12">
                                    <label id="userName" class="inline-block ">{{userName}}</label>
                                </td>
                            </tr>
                            <tr>
                                <th class="padding-bottom-12 mobile-display-block mobile-padding-bottom-8 userNameLabel">
                                    <label id="securityQuestionLabel" class="inline-block margin-right-18 ">{{content.Data.challengePage.content.questionLabel}}</label>
                                </th>
                                <td class="text-align-left inline-block mobile-display-block mobile-padding-bottom-12 ">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td class="text-align-left inline-block mobile-display-block  mobile-padding-bottom-24">
                                    <label for="'answerInputBox'" id="securityQuestion" class="security-question-font">{{question}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <div class="pw-input-wrapper">
                                            <c11n-input [hasError]="challengeForm?.get('securityAnswer')?.touched && challengeForm.get('securityAnswer')?.errors">
                                            <input
                                                c11nInput
                                                [attr.type]="inputType"
                                                id="answerInputBox"
                                                name="answerInputBox"
                                                formControlName="securityAnswer"
                                                aria-describedby="warningBannerError"
                                                placeholder={{content.Data.challengePage.content.answerLabel}}
                                                (keyup.enter)="authUserSecurityQuestion()"
                                            />
                                            </c11n-input>
                                            <div class="c11n-text-sm pw-show-hide">
                                            <button
                                                c11n-link
                                                class="showHideBtn showHideBtnHoverAnimation"
                                                size="small"
                                                type="button"
                                                aria-controls="answerInputBox"
                                                (click)="handleShowHideBtn()"
                                            >
                                                {{ hidePw ? 'Show' : 'Hide' }}
                                            </button>
                                            </div>
                                        </div>
                                        <c11n-hint-error
                                            [hasError]="challengeForm?.get('securityAnswer')?.touched && challengeForm?.get('securityAnswer')?.hasError('required')"
                                            [errorText]="content.Data.challengePage.content.emptyInputMessage"
                                            [hintErrorId]="'warningBannerError'">
                                        </c11n-hint-error>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <th class="nowrap-white-space padding-top-16">
                                    <label for="rememberThisComputer" id="rememberPcLabel" class="challenge-radio-title margin-bottom-24">{{content.Data.challengePage.content.rememberLabel}}</label>
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <div class="radioFlex">
                                        <c11n-radio-group [groupId]="'rememberThisComputer'" [hideLegend]="true">
                                            <div class="challenge-radio-element-wrapper margin-bottom-24">
                                                <c11n-radio [labelText]="content.Data.challengePage.content.boundText.boundText.allowBound">
                                                    <input c11nRadioInput id="rememberThisPc" type="radio" [value]="true" formControlName="device" />
                                                </c11n-radio>
                                            </div>
                                            <div class="challenge-radio-element-wrapper margin-bottom-24">
                                                <c11n-radio [labelText]="content.Data.challengePage.content.boundText.boundText.preventBound">
                                                    <input c11nRadioInput id="doNotRememberThisPc" type="radio" [value]="false" [checked]="true" formControlName="device" />
                                                </c11n-radio>
                                            </div>
                                        </c11n-radio-group>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex challenge-button-div">
                    <c11n-button
                        id="cancelBtn"
                        buttonType="button"
                        labelText={{content.Data.challengePage.content.cancelBtn}}
                        variant="secondary"
                        (clickEvent)="cancel()"
                    ></c11n-button>
                    <c11n-button class="margin-left-40"
                        id="continueBtn"
                        name="continueBtn"
                        buttonType="submit"
                        labelText={{content.Data.challengePage.content.continueBtn}}
                        variant="primary"
                        (clickEvent)="authUserSecurityQuestion()"
                    ></c11n-button>
                </div>
            </form>
        </div>
    </div>
</div>