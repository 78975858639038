import { Region } from '../../region/region'

export class RegionService {
  private region: Region = {}

  set userType(userType: string) {
    this.region.userType = userType
  }

  get userType(): string {
    return this.region.userType
  }

  get envType(): string {
    return this.region.envType
  }

  set envType(envType: string) {
    this.region.envType = envType
  }

  get angular_env(): string {
    return this.region.angular_env
  }

  set angular_env(angular_env: string) {
    this.region.angular_env = angular_env
  }
}
