<spinner [active]="loading"></spinner>
<div class="view-container">
  <div class="row margin-top-16 margin-bottom-34">
    <span id="tipsContentInstruction" class="c11n-info-text">
      {{content.Data.registrationPage.content.tipsContentInstruction}}
      <a id="tipsContentLink" role="link" target="_blank" (click)="qaTipsDialog.openModalDialog()"
        (keydown.enter)="qaTipsDialog.openModalDialog()" class="c11n-link-text" tabindex=0>
        {{content.Data.registrationPage.content.tipsContent.tipsContent.header}}
      </a>
    </span>
    <c11n-modal-dialog [headingText]="content.Data.registrationPage.content.tipsContent.tipsContent.header"
      [maxWidth]="660" [primaryButtonLabel]="content.Data.registrationPage.content.tipsContent.tipsContent.okButton"
      (primaryClick)="qaTipsDialog.closeDialogModal($event)" (closeEvent)="qaTipsDialog.closeDialogModal($event)"
      #qaTipsDialog>
      <div c11n-modal-dialog-body>
        <ng-container *ngTemplateOutlet="tipsContentDialog"></ng-container>
      </div>
    </c11n-modal-dialog>
  </div>

  <form [formGroup]="securityQAForm" class="inline-block width-100" id="securityQA" name="securityQA">

    <!-- 1st dropdown -->
    <div class="inline-block width-100 margin-bottom-46 container-fluid">
      <div class="row margin-bottom-28 securityQuestions security-questions-dropdown-width">
        <c11n-select
          labelText="{{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secQuestionLabel}} {{1}}"
          size="medium"
          hintText=""
          errorText="content.Data.oobSecurityPage.content.selectionError"
          (stateChange)="setSelected($event, 1)"
          aria-describedby="securityQuestionsFormError"
          >
          <select c11nSelectInput id="secQuestionDropdown1" formControlName="securityQuestionsForm1" required [(ngModel)]="securityQuestionsForm1">
            <option *ngFor="let item of securityQuestions1" [value]="item">{{item.questionText}}</option>
          </select>
        </c11n-select>
      </div>
      <div class="row width-85 margin-left-0 security-answers-div">
        <c11n-input [hasError]="securityQAForm?.get('secAnswer1')?.touched && securityQAForm.get('secAnswer1')?.errors">
          <input c11nInput
            inputmode="text"
            id="secAnswer1"
            name="secAnswer1"
            formControlName="secAnswer1"
            aria-describedby="secAnswerInputBoxError1"
            placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secAnswerLabel}}
            type="securityQnA"
            (keyup.enter)="submit()"
            (blur)="allFieldValidator()"
            (input)="allFieldValidator()"
            trim
            />
        </c11n-input>
        <c11n-hint-error 
          [hasError]="(securityQAForm?.get('secAnswer1')?.touched && (
            securityQAForm.get('secAnswer1')?.hasError('required') ||
            securityQAForm.get('secAnswer1')?.hasError('maxlength') ||
            securityQAForm.get('secAnswer1')?.hasError('pattern') ||
            securityQAForm?.get('secAnswer1')?.hasError('sameUsername') ||
            securityQAForm?.get('secAnswer1')?.hasError('sameAnswer')
            ))"
          [errorText]="secAnswer1ErrorMsg"
          hintErrorId="secAnswerInputBoxError1">
        </c11n-hint-error>
      </div>
      <div class="row width-85 margin-left-0 security-answers-div">
        <c11n-input [hasError]="securityQAForm?.get('secReEnter1')?.touched && securityQAForm.get('secReEnter1')?.errors">
          <input c11nInput
            inputmode="text"
            id="secReEnter1"
            name="secReEnter1"
            formControlName="secReEnter1"
            aria-describedby="secReEnterInputBoxError1"
            placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secReEnterLabel}}
            type="securityQnA"
            (keyup.enter)="submit()"
            (blur)="allFieldValidator()"
            (input)="allFieldValidator()"
            trim
            />
        </c11n-input>
        <c11n-hint-error 
          [hasError]="(securityQAForm?.get('secReEnter1')?.touched && (
            securityQAForm.get('secReEnter1')?.hasError('required') || 
            securityQAForm?.get('secReEnter1')?.hasError('notMatch')
            ))"
          [errorText]="secReEnter1ErrorMsg"
          hintErrorId="secReEnterInputBoxError1">
        </c11n-hint-error>
      </div>
    </div>

    <!-- 2nd dropdown -->
    <div class="inline-block width-100 margin-bottom-46 container-fluid">
      <div class="row margin-bottom-28 securityQuestions security-questions-dropdown-width">
        <c11n-select
          labelText="{{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secQuestionLabel}} {{2}}"
          size="medium"
          hintText=""
          errorText="content.Data.oobSecurityPage.content.selectionError"
          (stateChange)="setSelected($event, 2)"
          aria-describedby="securityQuestionsFormError"
          >
          <select c11nSelectInput id="secQuestionDropdown2" formControlName="securityQuestionsForm2" required [(ngModel)]="securityQuestionsForm2">
            <option *ngFor="let item of securityQuestions2" [value]="item">{{item.questionText}}</option>
          </select>
        </c11n-select>
      </div>
      <div class="row width-85 margin-left-0 security-answers-div">
        <c11n-input [hasError]="securityQAForm?.get('secAnswer2')?.touched && securityQAForm.get('secAnswer2')?.errors">
          <input c11nInput
            inputmode="text"
            id="secAnswer2"
            name="secAnswer2"
            formControlName="secAnswer2"
            aria-describedby="secAnswerInputBoxError2"
            placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secAnswerLabel}}
            type="securityQnA"
            (keyup.enter)="submit()"
            (blur)="allFieldValidator()"
            (input)="allFieldValidator()"
            trim
            />
        </c11n-input>
        <c11n-hint-error 
          [hasError]="(securityQAForm?.get('secAnswer2')?.touched && (
            securityQAForm.get('secAnswer2')?.hasError('required') ||
            securityQAForm.get('secAnswer2')?.hasError('maxlength') ||
            securityQAForm.get('secAnswer2')?.hasError('pattern') ||
            securityQAForm?.get('secAnswer2')?.hasError('sameUsername') ||
            securityQAForm?.get('secAnswer2')?.hasError('sameAnswer')
            ))"
          [errorText]="secAnswer2ErrorMsg"
          hintErrorId="secAnswerInputBoxError2">
        </c11n-hint-error>
      </div>
      <div class="row width-85 margin-left-0 security-answers-div">
        <c11n-input [hasError]="securityQAForm?.get('secReEnter2')?.touched && securityQAForm.get('secReEnter2')?.errors">
          <input c11nInput
            inputmode="text"
            id="secReEnter2"
            name="secReEnter2"
            formControlName="secReEnter2"
            aria-describedby="secReEnterInputBoxError2"
            placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secReEnterLabel}}
            type="securityQnA"
            (keyup.enter)="submit()"
            (blur)="allFieldValidator()"
            (input)="allFieldValidator()"
            trim
            />
        </c11n-input>
        <c11n-hint-error 
          [hasError]="(securityQAForm?.get('secReEnter2')?.touched && (
            securityQAForm.get('secReEnter2')?.hasError('required') || 
            securityQAForm?.get('secReEnter2')?.hasError('notMatch')
            ))"
          [errorText]="secReEnter2ErrorMsg"
          hintErrorId="secReEnterInputBoxError2">
        </c11n-hint-error>
      </div>
    </div>

    <!-- 3rd dropdown -->
    <div class="inline-block width-100 margin-bottom-46 container-fluid">
      <div class="row margin-bottom-28 securityQuestions security-questions-dropdown-width">
        <c11n-select
          labelText="{{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secQuestionLabel}} {{3}}"
          size="medium"
          hintText=""
          errorText="content.Data.oobSecurityPage.content.selectionError"
          (stateChange)="setSelected($event, 3)"
          aria-describedby="securityQuestionsFormError"
          >
          <select c11nSelectInput id="secQuestionDropdown3" formControlName="securityQuestionsForm3" required [(ngModel)]="securityQuestionsForm3">
            <option *ngFor="let item of securityQuestions3" [value]="item">{{item.questionText}}</option>
          </select>
        </c11n-select>
      </div>
      <div class="row width-85 margin-left-0 security-answers-div">
        <c11n-input [hasError]="securityQAForm?.get('secAnswer3')?.touched && securityQAForm.get('secAnswer3')?.errors">
          <input c11nInput
            inputmode="text"
            id="secAnswer3"
            name="secAnswer3"
            formControlName="secAnswer3"
            aria-describedby="secAnswerInputBoxError3"
            placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secAnswerLabel}}
            type="securityQnA"
            (keyup.enter)="submit()"
            (blur)="allFieldValidator()"
            (input)="allFieldValidator()"
            trim
            />
        </c11n-input>
        <c11n-hint-error 
          [hasError]="(securityQAForm?.get('secAnswer3')?.touched && (
            securityQAForm.get('secAnswer3')?.hasError('required') ||
            securityQAForm.get('secAnswer3')?.hasError('maxlength') ||
            securityQAForm.get('secAnswer3')?.hasError('pattern') ||
            securityQAForm?.get('secAnswer3')?.hasError('sameUsername') ||
            securityQAForm?.get('secAnswer3')?.hasError('sameAnswer')
            ))"
          [errorText]="secAnswer3ErrorMsg"
          hintErrorId="secAnswerInputBoxError3">
        </c11n-hint-error>
      </div>
      <div class="row width-85 margin-left-0 security-answers-div">
        <c11n-input [hasError]="securityQAForm?.get('secReEnter3')?.touched && securityQAForm.get('secReEnter3')?.errors">
          <input c11nInput
            inputmode="text"
            id="secReEnter3"
            name="secReEnter3"
            formControlName="secReEnter3"
            aria-describedby="secReEnterInputBoxError3"
            placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.secQuestionForm.secQuestionForm.secReEnterLabel}}
            type="securityQnA"
            (keyup.enter)="submit()"
            (blur)="allFieldValidator()"
            (input)="allFieldValidator()"
            trim
            />
        </c11n-input>
        <c11n-hint-error 
          [hasError]="(securityQAForm?.get('secReEnter3')?.touched && (
            securityQAForm.get('secReEnter3')?.hasError('required') || 
            securityQAForm?.get('secReEnter3')?.hasError('notMatch')
            ))"
          [errorText]="secReEnter3ErrorMsg"
          hintErrorId="secReEnterInputBoxError3">
        </c11n-hint-error>
      </div>
    </div>

  </form>  

  <div class="d-flex margin-bottom-96 margin-top-32 ">
    <div class="cancel-btn">
      <c11n-button id="cancelBtn" buttonType="button" labelText={{content.Data.registrationPage.content.cancelBtn}}
        variant="secondary" (clickEvent)="cancelModalDialog.openModalDialog()"
        (keydown.enter)="cancelModalDialog.openModalDialog()">
      </c11n-button>
  
      <div id="cancelDialogBoxIdQnA">
        <c11n-modal-dialog modalid="cancelModalDialog" #cancelModalDialog
          [headingText]="content.Data.cancelLayer.content.cancelLayerQuestion"
          [primaryButtonLabel]="content.Data.cancelLayer.content.confirmCanceNo"
          [secondaryButtonLabel]="content.Data.cancelLayer.content.confirmCanceYes" variant="warning"
          (primaryClick)="cancelModalDialog.closeDialogModal($event)" (secondaryClick)="this.clear()" [maxWidth]="660">
          <div c11n-modal-dialog-body>
            <ng-container *ngTemplateOutlet="cancelDialog"></ng-container>
          </div>
        </c11n-modal-dialog>
      </div>
      <ng-template #cancelDialog>
        <p class="c11n-cancel-subtext">{{content.Data.cancelLayer.content.cancelLayerSubtext}}</p>
      </ng-template>
    </div>
  
    <c11n-button class="continue-button" id="submitBtn" name="submitBtn" buttonType="submit"
      labelText={{content.Data.registrationPage.content.submitBtn}} variant="primary" (clickEvent)="submit()">
    </c11n-button>
  </div>
</div>
<ng-template #tipsContentDialog>
  <qa-tips-dialog></qa-tips-dialog>
</ng-template>
