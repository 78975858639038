import { Component, OnInit } from '@angular/core'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { RegionService } from '@app/shared/services/regionService'
import { ContentService } from '@app/shared/services/contentService'
import { AdobeLaunchConfigurationService } from '@app/shared/services/adobe-launch-configuration/adobeLaunchConfiguration.service'

@Component({
  selector: 'header-text',
  templateUrl: './header-text.component.html',
  styleUrls: ['./header-text.component.scss'],
})
export class HeaderTextComponent implements OnInit {
  content: any
  header: string
  component: string
  showHeadText: boolean
  headText: string

  constructor(
    private readonly headerText: HeaderTextService,
    private readonly regionService: RegionService,
    private readonly contentService: ContentService,
    private readonly adobeLaunchConfigurationService: AdobeLaunchConfigurationService,
  ) {}

  ngOnInit() {
    this.content = this.contentService.caasContent
    this.headerText
      .getComponent()
      .subscribe((appComponent) => (this.component = appComponent))
    this.headerText
      .getHeader()
      .subscribe((appHeader) => (this.header = appHeader))
    this.headerText
      .getShowHeadText()
      .subscribe((appShowHeadText) => (this.showHeadText = appShowHeadText))
    this.headerText
      .getHeadText()
      .subscribe((appHeadText) => (this.headText = appHeadText))
  }

  link() {
    this.adobeLaunchConfigurationService.trackLogonLink()
  }
}
