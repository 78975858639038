import { Injectable } from '@angular/core'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { TransmitAuthenticateResult } from '@app/models/TransmitConstants'
import { TransmitSdkService } from '@app/shared/services/transmitCommonService/transmitSdk.service'
import { Observable } from 'rxjs'

const sdk = XmSdk()
declare const com: any

@Injectable({
  providedIn: 'root',
})
export class TransmitOtpService {
  actionContext: any
  status: string
  resolver: any
  possibleTargets: any
  possibleTargetAng: any
  state: number
  codeLength: any
  isSupportedFormat: boolean
  target: any
  authError: any
  primarySmsTarget: any
  primaryVoiceTarget: any

  setResendTargets() {
    const primaryPhoneTargets = this.possibleTargetAng.filter(
      (item) => item._targetIdentifier === '1',
    )

    this.primarySmsTarget = primaryPhoneTargets.find(
      (item) => item._channel === com.ts.mobile.sdk.OtpChannel.Sms,
    )

    this.primaryVoiceTarget = primaryPhoneTargets.find(
      (item) => item._channel === com.ts.mobile.sdk.OtpChannel.VoiceCall,
    )
  }

  selfServiceSendNewCode() {
    // this method is also used in "send a new code" in forced OOB
    const input =
      com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createTargetSelectionRequest(
        this.possibleTargets[0],
      )
    this.resolver(
      com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(input),
    )
  }

  sendNewCode(deliveryMethod: string) {
    this.setResendTargets()
    let newTarget

    switch (deliveryMethod) {
      case 'SMS':
      case 'INTL_SMS':
        newTarget = this.primarySmsTarget
        break
      case 'VOICE':
        newTarget = this.primaryVoiceTarget
        break
    }

    const input =
      com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createTargetSelectionRequest(
        newTarget,
      )
    this.resolver(
      com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(input),
    )
  }

  private otpSubmission: any

  submitOtpCode(otpCode): Observable<any> {
    this.otpSubmission =
      com.ts.mobile.sdk.OtpInputOtpSubmission.createOtpSubmission(otpCode)
    const input = this.otpSubmission
    const inputTargetBased =
      com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(
        input,
      )
    return this.resolver(
      com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
        inputTargetBased,
      ),
    )
  }

  authenticateOtpJourney(
    transmitModel,
    transmitParams,
  ): Promise<TransmitAuthenticateResult> {
    return this.transmitService.authenticate(
      transmitModel.ticket,
      transmitModel.isBoundDevice,
      transmitModel.poId,
      transmitModel.journeyName,
      transmitParams,
    )
  }

  constructor(readonly transmitService: TransmitSdkService) {}
}
