<div class="view-container message-wrapper message-wrapper-padding-bottom">
  <c11n-banner id="toggle-message-header"
    [size]="'large'"
    [headingText]="isAppDown()?content.Data.logonPage.content.toggleMsg.toggleMsg.appDown:content.Data.logonPage.content.toggleMsg.toggleMsg.underMaintenance"
    [hasCloseControl]="false"
    [hasDetails]="false"
    [variant]="'error'"
    [isDetailsExpanded]="false"
    >
    <div class="c11n-text-md--crop">{{ content.Data.logonPage.content.toggleMsg.toggleMsg.messageInfo }}</div>
  </c11n-banner>
</div>
