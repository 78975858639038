import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { environment as env } from '@env'

//Import Services
import { GatekeeperService } from '@app/shared/services/gatekeeperService'
import { CaaS } from '@app/shared/services/caasService'
import { ContentService } from '@app/shared/services/contentService'
import { RegionService } from '@app/shared/services/regionService'
import { SessionService } from '@app/shared/services/sessionService'
import { WindowRefService } from '@app/shared/services/window-ref'
import { ViewService } from '@app/shared/services/viewService'

//Import Model Responses
import { RegionResponse } from '@app/models/RegionResponse'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  view: string
  logonRestEndPoint: string
  contentReady = false
  tcContentReady = false
  loadView: boolean
  unloading: boolean
  underMaintOrAppDown = ''
  legacyLogon = false
  public title = 'institutional-logon'

  constructor(
    private readonly gatekeeperService: GatekeeperService,
    private readonly caasContentService: CaaS,
    private regionService: RegionService,
    private contentService: ContentService,
    private readonly viewService: ViewService,
    private sessionService: SessionService,
    private readonly windowRefService: WindowRefService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.legacyLogon = this.isPrettyUrl()
    this.checkMaintAndDown()
    this.getUserType()

    if (this.underMaintOrAppDown.length === 0) {
      this.setView()
      this.viewService.getView().subscribe((data) => (this.view = data))
      this.viewService
        .isUnloading()
        .subscribe((data) => (this.unloading = data))
    }

    document.getElementById('global-header').style.display = 'inline'
    document.getElementById('global-footer').style.display = 'inline'
    document.getElementById('header-text').style.display = 'inline'
  }

  setView() {
    this.route.data.subscribe({
      next: (data) => {
        this.loadView = data.loadView
      },
    })
  }

  checkMaintAndDown() {
    this.underMaintOrAppDown = ''

    this.checkGateKeeperToggle(env.UNDER_MAINTENANCE)
    this.checkGateKeeperToggle(env.APP_DOWN)
  }

  async checkGateKeeperToggle(featureId: string) {
    await this.gatekeeperService.getToggle(featureId).subscribe({
      next: (data: boolean) => {
        if (data === true) {
          this.underMaintOrAppDown = featureId
        }
      },
      error: (error) => {
        console.log(
          'Error occured while retrieving gatekeeper service for Toggle' +
            featureId +
            ': Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
      },
    })
  }

  getUserType(): void {
    this.caasContentService
      .getRegion(
        this.legacyLogon
          ? env.LEGACYLOGON + env.REGION_ROUTE
          : env.REGION_ROUTE,
      )
      .subscribe({
        next: (data: RegionResponse) => {
          this.regionService.userType = data.srvType
          this.regionService.envType = data.envType
          this.regionService.angular_env = data.angular_env
          this.sessionService.devicePrint =
            this.windowRefService.nativeWindow.encode_deviceprint()
          this.logonRestEndPoint =
            env[data.envType][data.srvType].LOGON_WEBSERVICE_BASE +
            env.LOGON_REST_ENDPOINT
          this.getContent(
            this.legacyLogon
              ? env.LEGACYLOGON + env.CONTENT_ROUTE
              : env.CONTENT_ROUTE,
          )
        },
        error: (error) => {
          console.log(
            'Error occured while retrieving Region: Status: ' +
              error.status +
              ', error: ' +
              error.message,
          )
        },
      })
  }

  getContent(url: string) {
    this.caasContentService.getContent(url).subscribe({
      next: (data: any[]) => {
        this.contentService.caasContent = data
        this.contentReady = true
        this.tcContentReady = true
      },
      error: (error) => {
        console.log(
          'Error occured while retrieving content: Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
      },
    })
  }

  isPrettyUrl(): boolean {
    return window.location.origin.includes('institutional.vanguard.com')
  }
}
