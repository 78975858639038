import { Component, OnInit } from '@angular/core'
import { SessionService } from '@app/shared/services/sessionService'
import { environment } from '@env'
import { Logon } from '@app/shared/services/logonService'
import { StatusResponse } from '@app/models/StatusResponse'
import { ViewService } from '@app/shared/services/viewService'
import { RegionService } from '@app/shared/services/regionService'
import { OobChallengeService } from '@app/shared/services/oobChallengeService'
import { GatekeeperService } from '@app/shared/services/gatekeeperService'

@Component({
  selector: 'view-root',
  templateUrl: './view.component.html',
})
export class ViewComponent implements OnInit {
  view: string
  logonRestEndPoint: string
  isInternationalOOB = ''

  constructor(
    private sessionService: SessionService,
    private readonly logonService: Logon,
    private viewService: ViewService,
    private readonly regionService: RegionService,
    private readonly gatekeeperService: GatekeeperService,
    private oobChallengeService: OobChallengeService,
  ) {}

  ngOnInit(): void {
    this.logonRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.LOGON_REST_ENDPOINT
    this.setView()
    this.checkIsInternationalOOB()
    this.viewService.getView().subscribe((data) => (this.view = data))
  }

  setView() {
    if (this.viewService.isRefresh) {
      this.viewService.isRefresh = false
      this.logonService.get(this.logonRestEndPoint).subscribe({
        next: (data: any) => {
          switch (data.state) {
            case environment.USERNAME_AND_PASSWORD:
              this.viewService.setView(environment.USERNAME_AND_PASSWORD)
              break
            case environment.QUESTION_AND_ANSWER:
              this.sessionService.userName = data.challenge.userName
              this.sessionService.question = data.challenge.question
              this.viewService.setView(environment.QUESTION_AND_ANSWER)
              break
            case environment.SUCCESSFULL:
              switch (data.authAction) {
                case environment.AA_RESET:
                  this.viewService.setView(environment.SECURITY_QA)
                  break
                default:
                  this.viewService.setView(environment.USERNAME_AND_PASSWORD)
                  break
              }
              break
            case environment.OUT_OF_BAND:
              switch (data.authAction) {
                case environment.OOB_ENROLL:
                  this.viewService.setView(environment.OUT_OF_BAND)
                  break
                case environment.CHALLENGE_OTP:
                  this.oobChallengeService.oobPhone = data.challenge.phoneNumber
                  this.oobChallengeService.oobBound = data.OOBBoundDevice
                  this.viewService.setView(environment.SECURITY_CODE)
                  break
                default:
                  this.viewService.setView(environment.USERNAME_AND_PASSWORD)
                  break
              }
              break
            default:
              this.viewService.setView(environment.USERNAME_AND_PASSWORD)
              break
          }
          this.viewService
            .getView()
            .subscribe((currentView) => (this.view = currentView))
        },
        error: (error) => {
          console.log(
            'Error occured while retrieving user status from view component: Status: ' +
              error.status +
              ', error: ' +
              error.message,
          )
          this.viewService.setView(environment.USERNAME_AND_PASSWORD)
          this.viewService.getView().subscribe((data) => (this.view = data))
        },
      })
    }
  }

  checkIsInternationalOOB() {
    const featureId: string = environment.INTERNATIONAL_OOB
    this.gatekeeperService.getToggle(featureId).subscribe({
      next: (data: boolean) => {
        if (data === true) {
          this.isInternationalOOB = environment.INTERNATIONAL_OOB
        } else {
          this.isInternationalOOB = ''
        }
      },
      error: (error) => {
        console.log(
          'Error occured while retrieving gatekeeper service: Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
      },
    })
  }
}
