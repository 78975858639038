import { AbstractControl, ValidatorFn, FormControl } from '@angular/forms'

export function identicalControlValidator(secondControl): ValidatorFn {
  return (first: AbstractControl): { [key: string]: any } | null => {
    if (!first.parent) {
      return null
    }
    const second = first.parent.get(secondControl) as FormControl
    return first.value === second.value ? { identicalControl: true } : null
  }
}
