import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { RegisterPayload } from '@app/models/RegisterPayload'
import { RegisterResponse } from '@app/models/RegisterResponse'

@Injectable()
export class RegisterService {
  constructor(private httpClient: HttpClient) {}

  registerCredentials(
    url: string,
    body: RegisterPayload,
  ): Observable<RegisterResponse> {
    return this.httpClient.post<RegisterResponse>(url, body)
  }
}
