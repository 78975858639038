import { SelfServiceQAComponent } from '../../../selfserviceQA/selfServiceQA.component'
import { TransmitQAService } from '../transmitCommonService/transmitQA.service'
import {
  TRANSMIT_QA_URL,
  TransmitErrorCode,
} from '@app/models/TransmitConstants'
import { TransmitValidateAnswerPayload } from '@app/models/TransmitValidateAnswerPayload'
import { SessionService } from '@app/shared/services/sessionService'
import {
  Transmit,
  TransmitQAValidateResult,
  TransmitValidateUserStatus,
} from '@app/models/TransmitConstants'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { ViewService } from '@app/shared/services/viewService'
import { environment } from '@env'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { TransmitSdkService } from '../transmitCommonService/transmitSdk.service'

declare const com: any

export class TransmitSelfServiceQAAuthSession {
  selfServiceQA: SelfServiceQAComponent
  transmitQAService: TransmitQAService
  placeholderName: string
  placeholderType: string
  title: string
  username: string
  authenticatorConfiguredData: string
  serverPayload: string
  mode: any
  description: any
  poid: string
  deviceId: any
  answer: string
  pmfp: string
  bannerService: WarningBannerService
  question: string
  validateEndpointQuestion: string

  private transmitAnswerPayload: TransmitValidateAnswerPayload

  constructor(
    selfServiceQA: SelfServiceQAComponent,
    transmitQAService: TransmitQAService,
    placeholderName: string,
    placeholderType: string,
    title: string,
    username: string,
    authenticatorConfiguredData: string,
    serverPayload: string,
    session: SessionService,
    bannerService: WarningBannerService,
    private readonly viewService: ViewService,
    private readonly errorMsgService: ErrorMsgService,
    private readonly transmitSdkService: TransmitSdkService,
    question: string,
  ) {
    this.selfServiceQA = selfServiceQA
    this.placeholderName = placeholderName
    this.placeholderType = placeholderType
    this.title = title
    this.username = username
    this.authenticatorConfiguredData = authenticatorConfiguredData
    this.serverPayload = serverPayload
    this.transmitQAService = transmitQAService
    this.transmitQAService.serverPayload = serverPayload
    this.poid = session.poid
    this.deviceId = selfServiceQA.deviceId
    this.pmfp = session.devicePrint
    this.bannerService = bannerService
    this.transmitSdkService = transmitSdkService
    this.question = question
  }

  startSession(description, mode, actionContext, clientContext): void {
    this.mode = mode
    this.description = description
    this.transmitQAService.description = description
  }

  promiseInput = function () {
    return new Promise((accept, reject) => {
      document.getElementById('securityAnswer').onsubmit = () => {
        document.getElementById('continueBtn').click()
      }

      document.getElementById('continueBtn').onclick = () => {
        //Validate the security answer here
        this.populateAnswerPayload()
        //Do not keep validating the answer if it is empty, nothing was entered in the box
        if (this.transmitAnswerPayload.userAnswer !== '') {
          this.transmitQAService
            .validateQA(TRANSMIT_QA_URL, this.transmitAnswerPayload)
            .subscribe(
              (data) => {
                if (data.transmitToken != null) {
                  const inputResponse =
                    com.ts.mobile.sdk.PlaceholderInputResponse.createSuccessResponse(
                      data.transmitToken,
                    )
                  const response =
                    com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                      inputResponse,
                    )
                  accept(response)
                } else {
                  if (
                    data.validateStatus ===
                      TransmitQAValidateResult.VALIDATION_FAILED &&
                    data.userStatus === TransmitValidateUserStatus.USER_VERIFIED
                  ) {
                    //The validate answer failed and the user status is ok, so must be an incorrect answer
                    const inputResponse =
                      com.ts.mobile.sdk.PlaceholderInputResponse.createSuccessResponse(
                        null,
                      )
                    const response =
                      com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                        inputResponse,
                      )
                    this.validateEndpointQuestion = data.questionText
                    accept(response)
                    this.bannerService.setWarningBannerMessage(
                      'incorrectSecurityAnswer',
                    )
                  } else if (
                    data.validateStatus ===
                      TransmitQAValidateResult.VALIDATION_FAILED &&
                    data.userStatus === TransmitValidateUserStatus.USER_LOCKED
                  ) {
                    TransmitSdkService.prototype.userLockedStatus =
                      TransmitValidateUserStatus.USER_LOCKED
                    this.userStatus = TransmitValidateUserStatus.USER_LOCKED

                    const inputResponse =
                      com.ts.mobile.sdk.PlaceholderInputResponse.createSuccessResponse(
                        null,
                      )
                    const response =
                      com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(
                        inputResponse,
                      )
                    accept(response)
                  }
                }
              },
              (error) => {
                this.selfServiceQA.invalidMessage = 'serviceFailureSelfService'
                this.selfServiceQA.warningBanner.setWarningBannerMessage(
                  'serviceFailureSelfService',
                )
              },
            )
        }
      }
    })
  }

  promiseRecoveryForError = function (error, validRecoveries, defaultRecovery) {
    const _this = this
    return new Promise(function (resolve, reject) {
      console.log('Promise Recovery Error :', error)
      if (
        _this.userStatus === 'LOCKOUT' ||
        (error._errorCode === TransmitErrorCode.InvalidInput &&
          error._data !== undefined &&
          error._data.additional_data !== undefined &&
          error._data.additional_data.additional_error_code ===
            TransmitErrorCode.InvalidInput) ||
        error._errorCode === TransmitErrorCode.ControlFlowExpired ||
        error._errorCode === TransmitErrorCode.SessionRequired
      ) {
        defaultRecovery = com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
      } else if (
        defaultRecovery ===
        com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator
      ) {
        if (_this.question !== _this.validateEndpointQuestion) {
          _this.selfServiceQA.question = _this.validateEndpointQuestion
        }
      } else {
        defaultRecovery = com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
      }
      resolve(defaultRecovery)
    })
  }

  changeSessionModeToRegistrationAfterExpiration(): void {}

  endSession(): void {}

  private showQaPage() {
    this.selfServiceQA.loading = false
  }

  populateAnswerPayload() {
    const userAnswer = (<HTMLInputElement>(
      document.getElementById('securityAnswer')
    )).value

    this.transmitAnswerPayload = {
      poid: this.poid,
      deviceId: this.deviceId,
      userAnswer: userAnswer,
      boundIndicator: false,
      tsTicketAppData: this.transmitQAService.serverPayload,
      tsTicketAppId: Transmit.APP_ID,
      pmfp: this.pmfp,
    }
  }
}
