import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { TRANSMIT_JWT_VALIDATION_URL } from '@app/models/TransmitConstants'
import { TransmitJWTPayLoad } from '@app/models/TransmitJWTPayLoad'
import { environment as env } from '@env'

@Injectable({
  providedIn: 'root',
})
export class TransmitJWTValidationService {
  constructor(private readonly httpClient: HttpClient) {}

  legacyLogon = this.isPrettyUrl()

  validateJWT(body: TransmitJWTPayLoad): Observable<any> {
    return this.httpClient.post<any>(
      this.legacyLogon
        ? env.LEGACYLOGON + TRANSMIT_JWT_VALIDATION_URL
        : TRANSMIT_JWT_VALIDATION_URL,
      body,
    )
  }

  isPrettyUrl(): boolean {
    return window.location.origin.includes('institutional.vanguard.com')
  }
}
