import { NgModule } from '@angular/core'
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'
import { LogOffComponent } from '@app/logOff/logOff.component'
import { ViewComponent } from '@app/view/view.component'
import { SelfServiceComponent } from '@app/selfService/selfService.component'

const routes: Routes = [
  {
    path: 'logOff',
    component: LogOffComponent,
    data: { loadView: false },
  },
  {
    path: 'logOff.html',
    component: LogOffComponent,
    data: { loadView: false },
  },
  {
    path: 'forgot-password',
    component: SelfServiceComponent,
    data: { loadView: false },
  },
  {
    path: 'forgot-username',
    component: SelfServiceComponent,
    data: { loadView: false },
  },
  {
    path: '',
    component: ViewComponent,
    data: { loadView: true },
  },
  {
    path: '**',
    redirectTo: '',
  },
]

@NgModule({
  // imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: "legacy" })],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled', // or 'top'
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 64], // [x, y] - adjust scroll offset
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
