import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Logon } from '@app/shared/services/logonService'
import { HeaderTextService } from '@app/shared/services/headerTextService'
import { environment } from '@env'
import { SessionService } from '@app/shared/services/sessionService'
import { AdobeAnalyticsService } from '../shared/services/adobe-analytics/adobe-analytics.service'
import { LogonResponse } from '../models/LogonResponse'
import { LogonPayload } from '@app/models/LogonPayload'
import { RegionService } from '@app/shared/services/regionService'
import { ContentService } from '@app/shared/services/contentService'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { GatekeeperService } from '@app/shared/services/gatekeeperService'
import { ProcessResponseService } from '@app/shared/services/processResponseService/processResponse.service'
import { WindowRefService } from '@app/shared/services/window-ref'
import { VUI_VALIDATORS } from '@vanguard/vui/error-message'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { AdobeLaunchConfigurationService } from '@app/shared/services/adobe-launch-configuration/adobeLaunchConfiguration.service'
import { LoggerService } from '@app/shared/services/loggerService'

@Component({
  selector: 'logon-view',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.scss'],
})
export class LogonComponent implements OnInit, AfterViewChecked {
  @ViewChild('focus', { static: true }) focus: ElementRef
  content: any
  private logonPayload: LogonPayload
  logonUrl: string
  logonBtnDisabled = false
  invalidMessage = ''
  devicePrint = ''
  disableForm = false
  loading: boolean
  cancelRestEndPoint: string

  hidePw = true
  inputType = this.hidePw ? 'password' : 'text'

  constructor(
    private logonService: Logon,
    private headerText: HeaderTextService,
    private warningBanner: WarningBannerService,
    private session: SessionService,
    private adobeAnalytics: AdobeAnalyticsService,
    private regionService: RegionService,
    private contentService: ContentService,
    private errorMsgService: ErrorMsgService,
    private processResponseService: ProcessResponseService,
    private windowRefService: WindowRefService,
    private readonly adobeLaunchConfigurationService: AdobeLaunchConfigurationService,
    private readonly loggerService: LoggerService,
  ) {}

  logonForm: FormGroup = new FormGroup({
    userNameInputBox: new FormControl(
      { value: '', disabled: this.disableForm },
      [Validators.required],
    ),
    passwordInputBox: new FormControl(
      { value: '', disabled: this.disableForm },
      [Validators.required],
    ),
  })

  ngOnInit(): void {
    this.loading = false
    document.getElementById('global-header').style.display = 'inline'
    document.getElementById('global-footer').style.display = 'inline'
    document.getElementById('header-text').style.display = 'inline'

    const data = {
      section: environment.LOGON_ADOBE_SECTION,
      subSection: environment.LOGON_ADOBE_SUBSECTION,
      pageId: environment.LOGON_ADOBE_PAGEID,
    }
    this.content = this.contentService.caasContent
    this.headerText.setComponent(this.content.Data.logonPage.content.titleId)
    this.headerText.setHeader(this.content.Data.logonPage.content.headerText)
    this.headerText.setShowHeadText(false)
    this.devicePrint = this.session.devicePrint
    this.logonUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_REST_BASE +
      environment.LOGON_REST_PATH +
      environment.LOGON_REST_POSTFIX
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
    this.adobeAnalytics.updateData(data)
    this.adobeAnalytics.track()
    this.adobeLaunchConfigurationService.trackPageLoad(
      environment.USERNAME_AND_PASSWORD,
    )
    this.checkErrorMsg()
    this.session.selfService = false
    window.setTimeout(
      () => document.getElementById('userNameInputBox').focus(),
      0,
    )
  }

  ngAfterViewChecked() {
    if (document.getElementById('logonBtn')) {
      this.modifyAriaIndex(
        document
          .getElementById('logonBtn')
          .getElementsByTagName('button')
          .item(0),
        'warningBannerError',
      )
    }
  }

  modifyAriaIndex(element, id) {
    if (element != null) {
      return element.setAttribute('aria-describedby', id)
    }
  }

  handleShowHideBtn(): void {
    this.hidePw = !this.hidePw
    this.inputType = this.hidePw ? 'password' : 'text'
  }

  checkErrorMsg() {
    this.invalidMessage = this.errorMsgService.invalidMessage
    this.warningBanner.setWarningBannerMessage(
      this.errorMsgService.invalidMessage,
    )
    this.errorMsgService.clearAll()
  }

  clearMsgs(): void {
    this.errorMsgService.clearAll()
    this.warningBanner.setWarningBannerMessage('')
    this.checkErrorMsg()
  }

  authUser() {
    this.clearMsgs()
    if (!this.logonForm.invalid && this.isValidLength()) {
      this.logonPayload = {
        userName: this.logonForm.value.userNameInputBox.toLowerCase(),
        password: this.logonForm.value.passwordInputBox,
        target: environment.HOME_URL,
        devicePrint: this.devicePrint,
        pageSource: environment.USERNAME_AND_PASSWORD,
      }
      this.session.userName = this.logonPayload.userName
      this.session.devicePrint = this.devicePrint
      this.loading = true
      this.logonService.authUser(this.logonUrl, this.logonPayload).subscribe(
        (data: LogonResponse) => {
          this.processResponseService.proccess(data)
          this.loggerService.info(
            this.session.userName +
              ' is trying to login with state : ' +
              data.state +
              ', authaction : ' +
              data.authAction,
          )

          this.loading = this.errorMsgService.loading
          this.warningBanner.setWarningBannerMessage(
            this.errorMsgService.invalidMessage,
          )
          this.invalidMessage = this.errorMsgService.invalidMessage
          this.checkErrorMsg()
        },
        (error) => {
          this.loggerService.info(
            'Error occured while sending user credentials from logon component for : ' +
              this.session.userName +
              ' with status : ' +
              error.status +
              ' and error message : ' +
              error.message,
          )
          this.warningBanner.setWarningBannerMessage('serviceFailureLogon')
          this.invalidMessage = 'serviceFailureLogon'
          this.loading = false
        },
      )
    } else {
      this.logonForm.reset()
      this.warningBanner.setWarningBannerMessage('invalidAttemptLogon')
      this.invalidMessage = 'invalidAttemptLogon'
    }
    this.logonForm.reset()
  }

  onCancel() {
    this.windowRefService.nativeWindow.location.href =
      environment[this.regionService.envType][this.regionService.userType]
        .IIG_BASE_URL + this.content.Data.logonPage.content.cancelBtnRef
  }

  onLogon() {
    this.authUser()
  }

  isValidLength(): boolean {
    const usernameLength = this.logonForm.value.userNameInputBox.length
    const passwordLength = this.logonForm.value.passwordInputBox.length
    if (usernameLength >= 6 && usernameLength <= 12) {
      if (passwordLength >= 8 && passwordLength <= 20) {
        return true
      }
    }
    return false
  }

  link() {
    this.adobeLaunchConfigurationService.trackLogonLink()
  }
}
