<spinner [active]="loading"></spinner>
<div class="padding-left-5 view-container">
        <form [formGroup]="forcedPWForm" id="forcedPW" name="forcedPW">
            <table class="responsive-left-padding">
              <tr>
                <td>
                  <div class="margin-top-40 padding-bottom-16">
                    <div class="pw-input-wrapper">
                      <label c11n-label [for]="'tempPassword'" id="tempPasswordLabel" class="forced-password-c11n-label"></label>
                      <c11n-input [hasError]="forcedPWForm?.get('tempPassword')?.touched && forcedPWForm.get('tempPassword')?.errors">
                          <input
                          c11nInput
                          inputmode="text"
                          [attr.type]="inputTypePw"
                          id="tempPasswordInput"
                          name="tempPassword"
                          aria-describedby="tempPasswordError"
                          formControlName="tempPassword"
                          placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.tempPasswordLabel}}
                          tabindex=0
                          autofocus
                          (blur)="allFieldValidator()"
                          (input)="allFieldValidator()"
                          (keyup.enter)="submit()"
                      />
                      </c11n-input>
                      <div class="c11n-text-sm pw-show-hide ">
                        <button
                          c11n-link
                          class="showHideBtn showHideBtnHoverAnimation"
                          size="small"
                          type="button"
                          aria-controls="newPasswordInputBox"
                          (click)="handleShowHidePwBtn()"
                        >
                        {{ hidePw ? 'Show' : 'Hide' }}
                        </button>
                      </div>
                  </div>
                    <div class="margin-left-16">
                      <c11n-hint-error
                          [hasError]="forcedPWForm?.get('tempPassword')?.touched && (
                          forcedPWForm?.get('tempPassword')?.hasError('required') ||
                          forcedPWForm.get('tempPassword')?.hasError('minlength') ||
                          forcedPWForm.get('tempPassword')?.hasError('maxlength') ||
                          forcedPWForm?.get('tempPassword')?.hasError('invalidTwoAlphaTwoNum'))"
                          [errorText]="tempPasswordErrorMsg"
                          [hintErrorId]="'tempPasswordError'">
                      </c11n-hint-error>
                    </div>
                </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div class="padding-bottom-16">
                    <div class="pw-input-wrapper">
                    <label c11n-label [for]="'newPassword'" id="newPasswordLabel" class="forced-password-c11n-label"></label>
                      <c11n-input [hasError]="forcedPWForm?.get('newPassword')?.touched && forcedPWForm.get('newPassword')?.errors">
                        <input
                          c11nInput
                          [attr.type]="inputTypeNpw"
                          inputmode="text"
                          id="newPasswordInput"
                          name="newPassword"
                          aria-describedby="newPasswordError"
                          formControlName="newPassword"
                          placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.newPasswordLabel}}
                          (blur)="allFieldValidator()"
                          (input)="allFieldValidator()"
                          (keyup.enter)="submit()"
                        />
                      </c11n-input>
                      <div class="c11n-text-sm pw-show-hide ">
                        <button
                          c11n-link
                          class="showHideBtn showHideBtnHoverAnimation"
                          size="small"
                          type="button"
                          aria-controls="newPasswordInputBox"
                          (click)="handleShowHideNpwBtn()"
                        >
                        {{ hideNpw ? 'Show' : 'Hide' }}
                        </button>
                      </div>
                    </div>
                    <div class="margin-left-16">
                      <c11n-hint-error
                        [hasError]="forcedPWForm?.get('newPassword')?.touched && (
                        forcedPWForm?.get('newPassword')?.hasError('required') ||
                        forcedPWForm?.get('newPassword')?.hasError('minlength') ||
                        forcedPWForm?.get('newPassword')?.hasError('maxlength') ||
                        forcedPWForm?.get('newPassword')?.hasError('invalidTwoAlphaTwoNum') ||
                        forcedPWForm?.get('newPassword')?.hasError('sameUsername') ||
                        forcedPWForm?.get('newPassword')?.hasError('identicalToUsernameOrPasswordStr') ||
                        forcedPWForm?.get('newPassword')?.hasError('identicalControl'))"
                        [errorText]="newPasswordErrorMsg"
                        [hintErrorId]="'newPasswordError'">
                      </c11n-hint-error>
                    </div>
                  </div>
                </td>
                <td id="newPasswordText">
                  <div class="password-inst-styles">{{newPasswordInstruction}}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div class="pw-input-wrapper">
                      <label c11n-label [for]="'reenterNewPassword'" id="reenterNewPasswordLabel"
                           class="forced-password-c11n-label">
                      </label>
                      <c11n-input [hasError]="forcedPWForm?.get('reenterNewPassword')?.touched && forcedPWForm.get('reenterNewPassword')?.errors">
                        <input
                          c11nInput
                          inputmode="text"
                          [attr.type]="inputTypeRnpw"
                          id="reenterNewPasswordInput"
                          name="reenterNewPassword"
                          aria-describedby="reenterNewPasswordError"
                          formControlName="reenterNewPassword"
                          placeholder={{content.Data.registrationPage.content.registerAccess.registerAccess.registrationForm.registrationForm.reenterNewPasswordLabel}}
                          (input)="allFieldValidator()"
                          (blur)="allFieldValidator()"
                          (keyup.enter)="submit()"
                          autofocus
                        />
                      </c11n-input>
                      <div class="c11n-text-sm pw-show-hide">
                        <button
                          c11n-link
                          class="showHideBtn showHideBtnHoverAnimation"
                          size="small"
                          type="button"
                          aria-controls="reenterNewPasswordInputBox"
                          (click)="handleShowHideRnpwBtn()"
                        >
                        {{ hideRnpw ? 'Show' : 'Hide' }}
                        </button>
                      </div>
                    </div>
                    <div class="margin-left-16">
                      <c11n-hint-error
                      [hasError]="forcedPWForm?.get('reenterNewPassword')?.touched && (
                      forcedPWForm?.get('reenterNewPassword')?.hasError('required') ||
                      forcedPWForm?.get('reenterNewPassword')?.hasError('notMatch'))"
                      [errorText]="reenterNewPasswordErrorMsg"
                      [hintErrorId]="'reenterNewPasswordError'">
                    </c11n-hint-error>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div class="d-flex forced-password-button-div">
              <div>
                <!-- Cancel button  -->
                <c11n-button id="cancelBtn" buttonType="button" labelText={{content.Data.cancelLayer.content.cancelBtn}}
                  variant="secondary" (clickEvent)="cancelModalDialog.openModalDialog($event)">
                </c11n-button>

                <!-- Modal dialog for the pop-up on cancel  -->
                <div id="cancelDialogBoxIdQnA">
                  <c11n-modal-dialog modalid="cancelModalDialog" #cancelModalDialog
                                   [headingText]="content.Data.cancelLayer.content.cancelLayerQuestion"
                                   [primaryButtonLabel]="content.Data.cancelLayer.content.confirmCanceNo"
                                   [secondaryButtonLabel]="content.Data.cancelLayer.content.confirmCanceYes"
                                   variant="warning"
                                   (primaryClick)="cancelModalDialog.closeDialogModal($event)"
                                   (secondaryClick)="confirmCancel()" [maxWidth]="660"
                  >
                    <div c11n-modal-dialog-body>
                      <ng-container *ngTemplateOutlet="cancelDialog"></ng-container>
                    </div>
                  </c11n-modal-dialog>
                </div>
                <ng-template #cancelDialog>
                  <p class="c11n-cancel-subtext">{{content.Data.cancelLayer.content.cancelLayerSubtext}}</p>
                </ng-template>
              </div>
              <!-- Continue button  -->
              <c11n-button class="margin-left-60 responsive-margin-right" id="submitBtn" buttonType="submit" variant="primary"
                labelText={{content.Data.registrationPage.content.submitBtn}} (clickEvent)="submit()">
              </c11n-button>
            </div>
        </form>
</div>
