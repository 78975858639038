import {
  Directive,
  HostListener,
  ElementRef,
  Renderer2,
  Input,
} from '@angular/core'
import { FormControl } from '@angular/forms'

@Directive({
  selector: '[trim]',
})
export class TrimDirective {
  @Input('trim') formControl: FormControl

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  private leadingWhiteSpace = /^\s/g
  private singleWhiteSpace = /\s\s+/g
  private trailingWhiteSpace = /\s$/g

  @HostListener('input') onInput() {
    const trimmedValue = this.elementRef.nativeElement.value
      .replace(this.singleWhiteSpace, ' ')
      .replace(this.leadingWhiteSpace, '')
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      trimmedValue,
    )
    if (this.formControl && this.formControl !== undefined) {
      this.formControl.reset()
    } else {
      this.formControl = new FormControl()
    }
    this.formControl.patchValue(trimmedValue)
    this.formControl.markAsTouched()
    this.formControl.updateValueAndValidity({ onlySelf: true, emitEvent: true })
  }

  @HostListener('blur') onBlur() {
    const trimmedValue = this.elementRef.nativeElement.value.replace(
      this.trailingWhiteSpace,
      '',
    )
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      trimmedValue,
    )
    if (this.formControl && this.formControl !== undefined) {
      this.formControl.reset()
    } else {
      this.formControl = new FormControl()
    }
    this.formControl.patchValue(trimmedValue)
    this.formControl.markAsTouched()
    this.formControl.updateValueAndValidity({ onlySelf: true, emitEvent: true })
  }
}
