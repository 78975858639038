<spinner [active]="loading"></spinner>
<div *ngIf="displayOob">
    <div class="view-container">
        <p class="margin-top-20 margin-bottom-24 width-750-px">
            <span id="infoText1" class="info-text">{{content.Data.oobSecurityPage.content.infoText1}}{{oobPhone}}</span>
            <span id="infoText2" class="info-text">{{content.Data.oobSecurityPage.content.infoText2}}</span>
        </p>
        <form  [formGroup]="securityCodeForm" id="securityCode" name="securityCode">
            <table class="responsive-left-padding">
                <tbody>
                    <tr class="margin-top-24">
                        <div class="security-code-input-box">
                            <label    
                                c11n-label [for]="'oobCode'" id="oobCodeLabel" class="security-code-c11n-label">{{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.securityCodePlaceholder}}
                            </label>
                            <c11n-input [hasError]="securityCodeForm?.get('oobCode')?.touched && securityCodeForm.get('oobCode')?.errors">
                                <input 
                                    c11nInput
                                    inputmode="text"
                                    id="oobCodeInput" 
                                    name="oobCode"
                                    aria-describedby="oobCodeError"
                                    formControlName="oobCode"
                                    placeholder={{content.Data.oobSecurityPage.content.oobEnrollmentPopup.oobEnrollmentPopup.securityCodePlaceholder}}
                                    (keyup.enter)="authUserSecurityCode()"
                                    tabindex=0
                                    autofocus
                                />
                            </c11n-input>    
                            <c11n-hint-error
                                [hasError]="securityCodeForm?.get('oobCode')?.touched && securityCodeForm?.get('oobCode')?.hasError('required')"
                                [errorText]="content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.requiredField"
                                [hintErrorId]="'oobCodeError'">
                            </c11n-hint-error>
                            <c11n-hint-error
                                [hasError]="securityCodeForm?.get('oobCode')?.touched && securityCodeForm?.get('oobCode')?.hasError('maxlength')"
                                [errorText]="content.Data.oobSecurityPage.content.fieldValidationMsg.fieldValidationMsg.oobCodeInvalidFormat"
                                [hintErrorId]="'oobCodeError'">
                            </c11n-hint-error>
                            <c11n-hint-error
                                [hasError]="securityCodeForm?.get('oobCode')?.touched && securityCodeForm?.get('oobCode')?.hasError('pattern')"
                                [errorText]="content.Data.oobSecurityPage.content.fieldValidationMsg.fieldValidationMsg.oobCodeInvalidFormat"
                                [hintErrorId]="'oobCodeError'">
                            </c11n-hint-error>
                        </div>
                    </tr>

                    <tr>
                        <td>
                        <div>    
                            <c11n-icon class="icon time-icon" name="time" size="small" scale="normal" id="timeIcon"></c11n-icon>
                            <a class="link-without-href security-code-send-new-code" role="link" tabindex="0" target="_blank" id="sendNewCode" (click)="resendOTP()" (keyup.enter)="resendOTP()" 
                                (sendCode)="resendOTP()" [disableLink]="disableLink">
                                    {{content.Data.oobSecurityPage.content.reSendSecurityCode}} 
                            </a>
                        </div>
                        </td>
                    </tr>

                    <tr *ngIf="!hideRememberMe && !selfService">
                        <th class="nowrap-white-space">
                            <label for="rememberThisComputer" id="rememberPcLabel" class="challenge-radio-title">{{content.Data.challengePage.content.rememberLabel}}</label>
                        </th>
                    </tr>

                    <tr *ngIf="!hideRememberMe && !selfService">
                        <td>
                            <div class="radioFlex">
                                <c11n-radio-group [groupId]="'rememberThisComputer'" [hideLegend]="true">
                                    <div class="security-code-radio-element-wrapper margin-bottom-24">
                                        <c11n-radio [labelText]="content.Data.oobSecurityPage.content.rememberThisPC.rememberThisPC.allowBound">
                                            <input c11nRadioInput id="rememberThisPc" type="radio" [value]="true" formControlName="deviceRadio" />
                                        </c11n-radio>
                                    </div>
                                    <div class="security-code-radio-element-wrapper margin-bottom-24">
                                        <c11n-radio [labelText]="content.Data.oobSecurityPage.content.rememberThisPC.rememberThisPC.preventBound">
                                            <input c11nRadioInput id="doNotRememberThisPc" type="radio" [value]="false" [checked]="true" formControlName="deviceRadio" />
                                        </c11n-radio>
                                    </div>
                                </c11n-radio-group>
                            </div>
                        </td>
                    </tr>

                    <div *ngIf="selfService" class="margin-top-24"></div>

                    <tr class="radio-group-width">
                        <p class="info-message margin-top-8 mobile-width" id="infoMessage">
                            {{content.Data.oobSecurityPage.content.infoMessage}}
                            <a class="link-without-href underline-border c11n-securitycodes-anchor" role="link" tabindex="0" target="_blank" id="additionalInfo" (click)="securityCodesInfoContentDialog.openModalDialog()" (keydown.enter)="securityCodesInfoContentDialog.openModalDialog()">
                                <span>
                                    {{content.Data.oobSecurityPage.content.howSecurityCodesWork}}
                                </span>
                            </a>
                        </p>
                        <c11n-modal-dialog
                            [headingText]="content.Data.oobSecurityPage.content.oobCodeTipsContent.oobCodeTipsContent.headerText"
                            [maxWidth]="660"
                            [primaryButtonLabel]="content.Data.cancelLayer.content.closeBtn"
                            (primaryClick)="securityCodesInfoContentDialog.closeDialogModal($event)"
                            (closeEvent)="securityCodesInfoContentDialog.closeDialogModal($event)"
                            #securityCodesInfoContentDialog
                            >
                            <div c11n-modal-dialog-body>
                                <ng-container *ngTemplateOutlet="ooptTipsBodyContentTemplate"></ng-container>
                            </div>
                        </c11n-modal-dialog>
                    </tr>
                </tbody>
            </table>
        </form>
        <div class="d-flex flex margin-bottom-36 margin-top-64">
            <c11n-button 
              id="cancelBtn" 
              buttonType="button"
              labelText={{content.Data.oobSecurityPage.content.cancelBtn}}
              variant="secondary"
              (clickEvent)="cancel()"
            >
            </c11n-button>
            <c11n-button class="margin-left-40 continue-btn" 
              id="continueBtn"
              name="continueBtn"
              buttonType="button"
              labelText={{content.Data.oobSecurityPage.content.continueBtn}}
              variant="primary"
              (clickEvent)="authUserSecurityCode()"
              >
            </c11n-button>
        </div>
    </div>
</div>
<ng-template #ooptTipsBodyContentTemplate>
    <oob-tips-dialog></oob-tips-dialog>
</ng-template>