import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { LogonResponse } from '@app/models/LogonResponse'
import { LogonPayload } from '@app/models/LogonPayload'
import { ChallengeResponse } from '@app/models/ChallengeResponse'
import { ChallengePayload } from '@app/models/ChallengePayload'
import { StatusResponse } from '@app/models/StatusResponse'
import { CancelResponse } from '@app/models/CancelResponse'
import { LogOffResponse } from '@app/models/LogOffResponse'
import { OtpChallengePayload } from '@app/models/OtpChallengePayload'
import { OtpEnrollmentPayload } from '@app/models/OtpEnrollmentPayload'
import { map } from 'rxjs/operators'
import { SessionService } from '../sessionService'
import { CountryCode } from '@app/models/CountryCode'
import { Location } from '@angular/common'

@Injectable()
export class Logon {
  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private location: Location,
  ) {}

  get(url: string) {
    return this.httpClient
      .get<any>(url, { observe: 'response' as 'body' })
      .pipe(
        map((data) => {
          if (data.headers.get('Correlation-Id')) {
            this.sessionService.correlationId =
              data.headers.get('Correlation-Id')
          }
          return data.body
        }),
      )
  }

  authUser(url: string, body: LogonPayload): Observable<LogonResponse> {
    return this.httpClient.post<LogonResponse>(url, body)
  }

  cancel(url: string): Observable<CancelResponse> {
    return this.httpClient.post<CancelResponse>(url, {})
  }

  logOff(url: string): Observable<LogOffResponse> {
    return this.httpClient.get<LogOffResponse>(url, {})
  }

  signout(url: string): Observable<any> {
    return this.httpClient.get<any>(this.location.prepareExternalUrl(url), {})
  }

  authChallenge(
    url: string,
    body: ChallengePayload,
  ): Observable<ChallengeResponse> {
    return this.httpClient.post<ChallengeResponse>(url, body)
  }

  otpChallenge(
    url: string,
    body: OtpChallengePayload,
  ): Observable<StatusResponse> {
    return this.httpClient.post<StatusResponse>(url, body)
  }

  otpEnrollment(
    url: string,
    body: OtpEnrollmentPayload,
  ): Observable<StatusResponse> {
    return this.httpClient.post<StatusResponse>(url, body)
  }

  getListOfInternationalPhoneCodes(url: string): Observable<CountryCode[]> {
    return this.httpClient.get<CountryCode[]>(url)
  }
}
