import { Injectable } from '@angular/core'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { Observable } from 'rxjs'
import { TransmitTicketResponse } from '@app/models/TransmitTicketResponse'
import { TransmitTicketPayload } from '@app/models/TransmitTicketPayload'
import { HttpClient } from '@angular/common/http'
import { environment as env } from '@env'

const sdk = XmSdk()
declare const com: any

@Injectable({
  providedIn: 'root',
})
export class TransmitTicketService {
  constructor(private httpClient: HttpClient) {}

  legacyLogon = this.isPrettyUrl()

  getTransmitTicket(
    url: string,
    body: TransmitTicketPayload,
  ): Observable<TransmitTicketResponse> {
    return this.httpClient.post<TransmitTicketResponse>(
      this.legacyLogon ? env.LEGACYLOGON + url : url,
      body,
    )
  }

  isPrettyUrl(): boolean {
    return window.location.origin.includes('institutional.vanguard.com')
  }
}
