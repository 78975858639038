import { Component, OnInit } from '@angular/core'
import { Logon } from '@app/shared/services/logonService'
import { environment } from '@env'
import { RegionService } from '@app/shared/services/regionService'
import { LogOffResponse } from '@app/models/LogOffResponse'
import { ViewService } from '../shared/services/viewService'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { AdobeLaunchConfigurationService } from '@app/shared/services/adobe-launch-configuration/adobeLaunchConfiguration.service'
import { LoggerService } from '@app/shared/services/loggerService'

@Component({
  selector: 'log-off-view',
  templateUrl: './logOff.component.html',
  styleUrls: ['./logOff.component.scss'],
})
export class LogOffComponent implements OnInit {
  logonUrl: string
  loading = true
  checkLocked: string
  legacyLogon: boolean

  constructor(
    private logonService: Logon,
    private regionService: RegionService,
    private viewService: ViewService,
    private router: Router,
    private route: ActivatedRoute,
    private errorMsgService: ErrorMsgService,
    private adobeLaunchConfigurationService: AdobeLaunchConfigurationService,
    private readonly logger: LoggerService,
  ) {}

  ngOnInit(): void {
    this.legacyLogon = this.isPrettyUrl()
    this.logonUrl =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_REST_BASE +
      environment.LOGON_REST_PATH +
      environment.LOGOFF_REST_END_POINT
    this.checkLocked = this.route.snapshot.queryParamMap.get(environment.LOCKED)
    this.logonService.logOff(this.logonUrl).subscribe(
      (data: LogOffResponse) => {
        this.loading = false
        if (this.checkLocked === environment.TRUE) {
          this.errorMsgService.invalidMessage = 'locked'
        }

        this.adobeLaunchConfigurationService.trackUserLoggedOut()
        this.webAuthSignout()
      },
      (error) => {
        this.logger.info(
          'Error occured while logging off user credentials from logOff component for Poid : Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        this.loading = false
        if (this.checkLocked === environment.TRUE) {
          this.errorMsgService.invalidMessage = 'locked'
        }
        this.adobeLaunchConfigurationService.trackUserLoggedOut()
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
        this.router.navigateByUrl(environment.HOME_URL)
      },
    )
  }

  async webAuthSignout() {
    await this.logonService
      .signout(
        this.legacyLogon
          ? environment.LEGACYLOGON + environment.SIGNOUT
          : environment.SIGNOUT,
      )
      .subscribe({
        next: (outputResponse) => {
          if (outputResponse.cookieExist) {
            window.location.replace(outputResponse.redirectUrl)
          } else {
            this.viewService.setView(environment.USERNAME_AND_PASSWORD)
            this.router.navigateByUrl(environment.HOME_URL)
          }
        },
        error: (error) => {
          this.logger.error(
            'Error occurred while Singout end point call, Error Status ' +
              error.status +
              ', error: ' +
              error.message,
          )
          this.viewService.setView(environment.USERNAME_AND_PASSWORD)
          this.router.navigateByUrl(environment.HOME_URL)
        },
      })
  }

  isPrettyUrl(): boolean {
    return window.location.origin.includes('institutional.vanguard.com')
  }
}
