import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { environment } from '@env'
import { ContentService } from '@app/shared/services/contentService'
import { SessionService } from '@app/shared/services/sessionService'
import { Logon } from '@app/shared/services/logonService'
import { ViewService } from '@app/shared/services/viewService'
import { RegionService } from '@app/shared/services/regionService'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { CancelResponse } from '@app/models/CancelResponse'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { DialogComponent, VuiDialogConfig } from '@vanguard/vui/dialog'
import { warningTriangleLg } from '@vanguard/vui/icons'
import { VuiIconLg } from '@vanguard/vui/icon/src/lg.model'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'

const sdk = XmSdk()

@Component({
  selector: 'cancel-layer',
  templateUrl: './cancel-layer.component.html',
  styleUrls: ['./cancel-layer.component.scss'],
  providers: [DialogComponent],
})
export class CancelLayerComponent implements OnInit {
  @ViewChild('dialogCancelLayer', { static: false })
  private readonly dialogCancelLayerTemplate: TemplateRef<any>
  public readonly warn: VuiIconLg = warningTriangleLg

  vuiDialogConfig: VuiDialogConfig = {
    dialogType: 'warning',
  }
  content: any
  cancelRestEndPoint: string
  private dialogOpen = false

  constructor(
    private readonly contentService: ContentService,
    private readonly viewContainer: ViewContainerRef,
    public vuiDialog: DialogComponent,
    private readonly session: SessionService,
    private readonly logonService: Logon,
    private readonly viewService: ViewService,
    private readonly regionService: RegionService,
    private readonly errorMsgService: ErrorMsgService,
  ) {}

  ngOnInit() {
    this.content = this.contentService.caasContent
    this.cancelRestEndPoint =
      environment[this.regionService.envType][this.regionService.userType]
        .LOGON_WEBSERVICE_BASE + environment.CANCEL_REST_POSTFIX
  }

  openCancelLayer() {
    this.vuiDialog.openDialog(
      this.viewContainer,
      this.dialogCancelLayerTemplate,
      this.vuiDialogConfig,
    )
    this.dialogOpen = true
  }

  closeDialog() {
    this.vuiDialog.closeDialog()
    this.dialogOpen = false
  }

  confirmCancel() {
    this.session.clear()
    if (this.dialogOpen) {
      this.closeDialog()
    }
    this.errorMsgService.clearAll()

    //Need to cancel the current journey, get a new ticket, and start a new journey if the user wants to sign up again
    sdk.cancelCurrentRunningControlFlow()

    this.logonService.cancel(this.cancelRestEndPoint).subscribe({
      next: (data: CancelResponse) => {
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
      error: (error) => {
        console.log(
          'Error occured while canceling from reset security Q&A component: Status: ' +
            error.status +
            ', error: ' +
            error.message,
        )
        // To do - unhappy path for registration
        this.viewService.setView(environment.USERNAME_AND_PASSWORD)
      },
    })
  }
}
