import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewChecked,
} from '@angular/core'
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup,
  AbstractControl,
} from '@angular/forms'
import { environment } from '@env'

@Component({
  selector: 'custom-input',
  templateUrl: './customInput.component.html',
  styleUrls: ['./customInput.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent),
      multi: true,
    },
  ],
})
export class CustomInputComponent
  implements OnInit, ControlValueAccessor, AfterViewChecked
{
  @Input() inputContent: string[]
  @Input() id: string
  @Input() label: string
  @Input() type: string
  @Input() enableTrim: boolean
  @Input() maxWidth: string
  @Input() widthPercent: string
  @Input() form: FormGroup
  @Input() disableLink: boolean
  @Input() otpPopUp: string
  @Input() charLimit: number
  @Input() ariaDescribedBy: string
  @Output() sendCode = new EventEmitter<boolean>()
  @ViewChild('countryCodeInputElement') countryCodeInputElement: ElementRef
  public _countryPhoneCode: string
  public _countryCodeSpan: HTMLSpanElement
  public _parent: HTMLElement
  content: any

  private readonly minLength = 4

  @Input()
  get countryPhoneCode(): number {
    return Number.parseInt(
      this._countryPhoneCode.substring(1, this._countryPhoneCode.length),
      10,
    )
  }
  set countryPhoneCode(value: number) {
    this._countryPhoneCode = '+' + value
    if (value) {
      if (this._countryCodeSpan) {
        this._countryCodeSpan.textContent = this._countryPhoneCode
      } else {
        this.appendSymbol()
      }
    }
  }

  updateForm: (obj) => void
  onTouch: () => void
  inputType: string
  disabled: boolean
  inputValue: string
  formControl: AbstractControl

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.formControl = this.form.get(this.id)
    this.disabled = false
    if (this.type === environment.PASSWORD) {
      this.inputType = environment.PASSWORD
    } else if (this.type === environment.PHONE_NUMBER) {
      this.inputType = environment.TELEPHONE
    } else if (this.type === environment.EMAIL) {
      this.inputType = environment.EMAIL
    } else {
      this.inputType = environment.TEXT
    }
  }

  ngAfterViewChecked() {
    /* istanbul ignore else*/
    if (
      document.getElementById(this.id + 'Input') &&
      document
        .getElementById(this.id + 'Input')
        .getAttribute('aria-invalid') === 'true'
    ) {
      document
        .getElementById(this.id + 'Input')
        .setAttribute(
          'aria-describedby',
          document.querySelector('svg').id +
            ' ' +
            this.ariaDescribedBy +
            ' warningBannerError',
        )
    }
  }

  setStyle() {
    const styles = {
      'max-width': this.maxWidth ? this.maxWidth : '306px',
      width: this.widthPercent ? this.widthPercent : '100%',
    }
    return styles
  }

  setStyleErrorMsg() {
    const styles = {
      width: this.widthPercent ? this.widthPercent : '100%',
      'padding-top': '3px',
    }
    return styles
  }

  parentStyle() {
    const styles = {
      width: this.inputContent ? '0%' : '100%',
    }
    return styles
  }

  onInput(value) {
    this.updateForm(value)
  }

  onBlur() {
    this.onTouch()
  }

  writeValue(obj: any): void {
    this.inputValue = obj ? obj : ''
  }

  registerOnChange(fn: any): void {
    this.updateForm = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  emitSendCode() {
    this.sendCode.emit()
  }

  appendSymbol(): void {
    if (this.countryCodeInputElement != null) {
      this._parent = this.renderer.parentNode(
        this.countryCodeInputElement.nativeElement,
      )
      this._countryCodeSpan = this.renderer.createElement('span')
      this._countryCodeSpan.textContent = this._countryPhoneCode

      this.renderer.addClass(
        this._countryCodeSpan,
        'vui-telephone-country-code',
      )
      this._countryCodeSpan.setAttribute('id', 'countryCodeSpan')
      this.renderer.insertBefore(
        this._parent,
        this._countryCodeSpan,
        this.countryCodeInputElement.nativeElement,
      )
      this.renderer.addClass(this._parent, 'vui-prefixed-symbol')
    }
  }
}
