import { Component, ViewEncapsulation, Input } from '@angular/core'

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent {
  @Input() active: boolean
}
