import { Injectable } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { ContentService } from '../contentService'
import { environment } from '@env'

@Injectable()
export class ValidatorService {
  formControl: AbstractControl
  type: string
  content: any
  msg: string
  countrySelected: boolean

  constructor(private contentService: ContentService) {}

  getValidationMsg(
    formControl: AbstractControl,
    controlName: string,
    countryPhoneCode: number,
  ): string {
    this.formControl = formControl
    this.setType(controlName.toLowerCase(), countryPhoneCode)
    this.content = this.contentService.caasContent
    this.performAllChecks()
    return this.msg
  }

  getValidationMsgOnDirty(
    formControl: AbstractControl,
    controlName: string,
    countryPhoneCode: number,
  ): string {
    this.formControl = formControl
    this.setType(controlName.toLowerCase(), countryPhoneCode)
    this.content = this.contentService.caasContent
    this.dirtyChecks()
    return this.msg
  }

  setType(controlName: string, countryPhoneCode: number) {
    if (controlName.includes(environment.TEMPPASSWORD)) {
      this.type = environment.TEMPPASSWORD
    } else if (
      controlName.includes(environment.PASSWORD) ||
      controlName.includes(environment.NEW_PASSWORD) ||
      controlName.includes(environment.REENTERPASSWORD)
    ) {
      this.type = environment.PASSWORD
    } else if (controlName.includes(environment.SEC)) {
      this.type = environment.SECURITY_QNA
    } else if (controlName.includes(environment.NEW_USERNAME)) {
      this.type = environment.NEW_USERNAME
    } else if (controlName.includes(environment.USERNAME)) {
      this.type = environment.USERNAME
    } else if (controlName.includes(environment.OOB_CODE)) {
      this.type = environment.OOB_CODE
    } else if (controlName.includes(environment.DOMESTIC_PHONE)) {
      this.type = environment.DOMESTIC_PHONE
    } else if (
      controlName.includes(environment.PHONE) &&
      countryPhoneCode === 1
    ) {
      this.type = environment.DOMESTIC_PHONE
    } else if (
      controlName.includes(environment.PHONE) &&
      countryPhoneCode !== 1
    ) {
      this.type = environment.PHONE
    } else if (controlName.includes(environment.EMAIL)) {
      this.type = environment.EMAIL
    } else if (controlName.includes(environment.FIRST_NAME)) {
      this.type = environment.FIRST_NAME
    } else if (controlName.includes(environment.LAST_NAME)) {
      this.type = environment.LAST_NAME
    } else if (controlName.includes(environment.PLAN_NUMBER)) {
      this.type = environment.PLAN_NUMBER
    }
    this.countrySelected = !isNaN(countryPhoneCode)
  }

  dirtyChecks() {
    if (this.type === environment.USERNAME) {
      this.usernameType()
    } else if (this.type === environment.NEW_USERNAME) {
      this.newUsernameType()
    } else if (this.type === environment.PASSWORD) {
      this.passwordType()
    } else if (this.type === environment.TEMPPASSWORD) {
      this.passwordType()
    } else if (this.type === environment.SECURITY_QNA) {
      this.securityQnAType()
    } else if (this.type === environment.OOB_CODE) {
      this.oobCodeType()
    } else if (this.type === environment.DOMESTIC_PHONE) {
      this.domesticPhoneNumberType()
    } else if (this.type === environment.PHONE) {
      this.phoneNumberType()
    } else if (this.type === environment.EMAIL) {
      this.emailType()
    } else if (this.type === environment.FIRST_NAME) {
      this.firstNameType()
    } else if (this.type === environment.LAST_NAME) {
      this.lastNameType()
    } else if (this.type === environment.PLAN_NUMBER) {
      this.planNumberType()
    }
  }

  performAllChecks() {
    this.dirtyChecks()
    if (!this.msg) {
      if (this.formControl.errors['notMatch'] && this.type === 'securityQnA') {
        this.msg =
          this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.secAnswerMatchError
      } else if (this.formControl.errors['notMatch']) {
        this.msg =
          this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newReenterNotSame
      } else if (
        this.formControl.errors['minlength'] &&
        this.type === 'username'
      ) {
        this.msg =
          this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernameInvalidFormat
      } else if (
        this.formControl.errors['numOfDigitsMin'] &&
        this.type === 'phone' &&
        this.countrySelected
      ) {
        this.msg =
          this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.phoneNumberValidation
      } else if (
        this.formControl.errors['numOfDigitsMin'] &&
        this.type === 'domestic'
      ) {
        this.msg =
          this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.domesticPhoneNumberValidation
      } else if (
        this.formControl.errors['minlength'] ||
        this.formControl.errors['invalidTwoAlphaTwoNum']
      ) {
        this.msg =
          this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.pwdInvalidFormat
      }
    }
  }

  private usernameType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg
          .fieldValidationMsg.usernameRequired === undefined
          ? this.content.Data.registrationPage.content.fieldValidationMsg
              .fieldValidationMsg.requiredField
          : this.content.Data.registrationPage.content.fieldValidationMsg
              .fieldValidationMsg.usernameRequired
    } else if (this.formControl.errors['pattern']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernameIncorrectValue
    } else if (this.formControl.errors['maxlength']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernameInvalidFormat
    } else if (this.formControl.errors['identicalControl']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernamePwdSame
    } else if (this.formControl.errors['sameUsername']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newUserSameTemp
    } else {
      this.msg = null
    }
  }

  private newUsernameType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newUsernameRequired
    } else if (this.formControl.errors['pattern']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newUsernameRequired
    } else if (this.formControl.errors['minlength']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newUsernameRequired
    } else if (this.formControl.errors['maxlength']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newUsernameRequired
    } else if (this.formControl.errors['identicalControl']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernamePwdSame
    } else if (this.formControl.errors['sameUsername']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newUserSameTemp
    } else {
      this.msg = null
    }
  }

  private passwordType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg
          .fieldValidationMsg.passwordRequired === undefined
          ? this.content.Data.registrationPage.content.fieldValidationMsg
              .fieldValidationMsg.requiredField
          : this.content.Data.registrationPage.content.fieldValidationMsg
              .fieldValidationMsg.passwordRequired
    } else if (this.formControl.errors['maxlength']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.pwdInvalidFormat
    } else if (this.formControl.errors['usernamePwdMatch']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernamePwdSame
    } else if (this.formControl.errors['identicalControl']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newOldSame
    } else if (this.formControl.errors['sameUsername']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.usernamePwdSame
    } else if (this.formControl.errors['identicalToUsernameOrPasswordStr']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newPwdUsernamePwd
    } else if (this.formControl.errors['newUserSameTemp']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.newUserSameTemp
    } else if (
      this.type === environment.TEMPPASSWORD &&
      (this.formControl.errors['minlength'] ||
        this.formControl.errors['invalidTwoAlphaTwoNum'])
    ) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.tempPwdInvalidFormat
    } else {
      this.msg = null
    }
  }

  private securityQnAType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.requiredField
    } else if (this.formControl.errors['pattern']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.secAnswerPatternError
    } else if (this.formControl.errors['maxlength']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.secAnswerLengthError
    } else if (this.formControl.errors['sameUsername']) {
      this.msg =
        this.content.Data.registrationPage.content.errorMsg.errorMsg.errormsg1
    } else if (this.formControl.errors['sameAnswer']) {
      this.msg =
        this.content.Data.registrationPage.content.errorMsg.errorMsg.errormsg2
    } else {
      this.msg = null
    }
  }

  private oobCodeType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg
          .fieldValidationMsg.codeRequired === undefined
          ? this.content.Data.registrationPage.content.fieldValidationMsg
              .fieldValidationMsg.requiredField
          : this.content.Data.registrationPage.content.fieldValidationMsg
              .fieldValidationMsg.codeRequired
    } else if (this.formControl.errors['maxlength']) {
      this.msg =
        this.content.Data.oobSecurityPage.content.fieldValidationMsg.fieldValidationMsg.oobCodeInvalidFormat
    } else {
      this.msg = null
    }
  }

  private phoneNumberType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.emptyPhoneNumberValidation
    } else if (
      this.formControl.errors['numOfDigitsMax'] &&
      this.countrySelected
    ) {
      this.msg =
        this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.phoneNumberValidation
    } else if (this.formControl.errors['pattern'] && this.countrySelected) {
      this.msg =
        this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.phoneNumberValidation
    } else if (this.formControl.errors['vui-tel'] && this.countrySelected) {
      this.msg =
        this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.phoneNumberValidation
    } else {
      this.msg = null
    }
  }
  private domesticPhoneNumberType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.emptyPhoneNumberValidation
    } else if (this.formControl.errors['numOfDigitsMax']) {
      this.msg =
        this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.domesticPhoneNumberValidation
    } else if (this.formControl.errors['pattern']) {
      this.msg =
        this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.domesticPhoneNumberValidation
    } else if (this.formControl.errors['vui-tel']) {
      this.msg =
        this.content.Data.registrationPage.content.forcedOobEnrollmentLayer.forcedOobEnrollmentLayer.oobEnrollSec2.oobEnrollSec2.domesticPhoneNumberValidation
    } else {
      this.msg = null
    }
  }
  private emailType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.selfServicePage.content.resetCredentialsErrorMsg
          .resetCredentialsErrorMsg.emailRequiredError === undefined
          ? this.content.Data.registrationPage.content.fieldValidationMsg
              .fieldValidationMsg.requiredField
          : this.content.Data.selfServicePage.content.resetCredentialsErrorMsg
              .resetCredentialsErrorMsg.emailRequiredError
    } else if (this.formControl.errors['email']) {
      this.msg =
        this.content.Data.selfServicePage.content.resetCredentialsErrorMsg.resetCredentialsErrorMsg.emailInvalidFormat
    } else {
      this.msg = null
    }
  }

  private firstNameType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.selfServicePage.content.resetCredentialsErrorMsg.resetCredentialsErrorMsg.firstNameRequiredError
    } else if (this.formControl.errors['pattern']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.firstNameIncorrectValue
    } else {
      this.msg = null
    }
  }

  private lastNameType() {
    if (this.formControl.errors['required']) {
      this.msg =
        this.content.Data.selfServicePage.content.resetCredentialsErrorMsg.resetCredentialsErrorMsg.lastNameRequiredError
    } else if (this.formControl.errors['pattern']) {
      this.msg =
        this.content.Data.registrationPage.content.fieldValidationMsg.fieldValidationMsg.lastNameIncorrectValue
    } else {
      this.msg = null
    }
  }

  private planNumberType() {
    this.msg = null
    if (this.formControl.errors) {
      this.msg =
        this.content.Data.selfServicePage.content.resetCredentialsErrorMsg.resetCredentialsErrorMsg.planNumberInvalidFormat
    }
  }
}
