import { Injectable } from '@angular/core'
import {
  CommonUiHandlerService,
  TransmitUiHandler,
} from '../transmitCommonService/transmitCommonUiHandler'
import { TransmitSelfServiceQAAuthSession } from '@app/shared/services/transmitSelfServiceQA/transmitSelfServiceQAAuthSession.service'
import { Router } from '@angular/router'
import { XmSdk } from '@vanguard/transmit/src/xm/js/xmsdk.js'
import { SessionService } from '@app/shared/services/sessionService'
import { WarningBannerService } from '@app/shared/services/warningBannerService'
import { TransmitValidateUserStatus } from '@app/models/TransmitConstants'
import { TransmitSdkService } from '@app/shared/services/transmitCommonService/transmitSdk.service'
import { ViewService } from '@app/shared/services/viewService'
import { environment } from '@env'
import { ErrorMsgService } from '@app/shared/services/errorMsgService'
import { SelfServiceQAComponent } from '@app/selfserviceQA/selfServiceQA.component'

const sdk = XmSdk()
declare const com: any

@Injectable({
  providedIn: 'root',
})
export class TransmitSelfServiceQAUiHandler {
  jsonQuestion: string

  constructor(
    private readonly commonUiHandler: CommonUiHandlerService,
    private readonly router: Router,
    private readonly session: SessionService,
    private readonly bannerService: WarningBannerService,
    private readonly transmitSdkService: TransmitSdkService,
    private readonly viewService: ViewService,
    private errorMsgService: ErrorMsgService,
  ) {}

  setUpQaUiHandler(
    qaComponent: SelfServiceQAComponent,
    transmitQaService,
  ): void {
    this.commonUiHandler.getCommonUiHandler()

    TransmitUiHandler.prototype.createPlaceholderAuthSession = (
      placeholderName: string,
      placeholderType: string,
      title: string,
      username: string,
      authenticatorConfiguredData: string,
      serverPayload: string,
    ) => {
      qaComponent.loading = false
      document.getElementById('displayQA').style.display = 'inline'
      document.getElementById('global-header').style.display = 'inline'
      document.getElementById('global-footer').style.display = 'inline'
      document.getElementById('header-text').style.display = 'inline'
      return new TransmitSelfServiceQAAuthSession(
        qaComponent,
        transmitQaService,
        placeholderName,
        placeholderType,
        title,
        username,
        authenticatorConfiguredData,
        serverPayload,
        this.session,
        this.bannerService,
        this.viewService,
        this.errorMsgService,
        this.transmitSdkService,
        this.jsonQuestion,
      )
    }

    TransmitUiHandler.prototype.selectAuthenticator = (
      options: any[],
      actionContext: any | null,
      clientContext: object | null,
    ) => {
      const qaAuth = options[0].getAuthenticator()
      const result =
        com.ts.mobile.sdk.AuthenticatorSelectionResult.createSelectionRequest(
          qaAuth,
        )
      return Promise.resolve(result)
    }

    TransmitUiHandler.prototype.processJsonData = (
      jsonData: any,
      actionContext: any,
      clientContext: any,
    ) => {
      return new Promise((resolve, reject) => {
        if (jsonData) {
          qaComponent.deviceId = jsonData.deviceId
          qaComponent.question = jsonData.questionText
          this.jsonQuestion = jsonData.questionText

          if (
            jsonData.status === TransmitValidateUserStatus.UNVERIFIED ||
            jsonData.status === undefined
          ) {
            // unverified = reset questions
            resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(false))
            //redirect to logon page and set banner messsage to call administrator
            console.log(
              'USER status IS' + jsonData.status + '... REDIRECT TO LOGON PAGE',
            )
            this.viewService.setView(environment.USERNAME_AND_PASSWORD)
            this.errorMsgService.invalidMessage = 'callIn'
          } else if (jsonData.status === TransmitValidateUserStatus.LOCKOUT) {
            //redirect to logon page need to hide the security code page so it never displays
            resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(false))
            //redirect to logon page and set banner messsage to call administrator
            console.log('USER IS LOCKED...REDIRECT TO LOGON PAGE')
            this.viewService.setView(environment.USERNAME_AND_PASSWORD)
            this.errorMsgService.invalidMessage = 'callIn'
            document.getElementById('global-header').style.display = 'inline'
            document.getElementById('global-footer').style.display = 'inline'
            document.getElementById('header-text').style.display = 'inline'
          } else if (jsonData.status === TransmitValidateUserStatus.DELETED) {
            //deleted from database
            //redirect to logon page need to hide the security code page so it never displays
            resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(false))
            //redirect to logon page and set banner messsage to call administrator
            console.log('USER IS DELETED...REDIRECT TO LOGON PAGE')
            this.viewService.setView(environment.USERNAME_AND_PASSWORD)
            this.errorMsgService.invalidMessage = 'callIn'
          } else {
            resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true))
            qaComponent.loading = false
          }
          // resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true))
          //qaComponent.loading = false
        } else {
          resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(false))
        }
      })
    }

    TransmitUiHandler.prototype.handlePolicyRejection = async (
      title,
      text,
      buttonText,
      failureData,
      actionContext,
      clientContext,
    ) => {
      if (
        failureData &&
        failureData.reason &&
        failureData.reason.data &&
        failureData.reason.data.QALOCKED.includes('User is LOCKED') === true
      ) {
        console.log('user is locked.')
        const okResponseNumber = -1
        return Promise.resolve(
          com.ts.mobile.sdk.ConfirmationInput.create(okResponseNumber),
        )
      } else {
        console.log(failureData)
        const okResponseNumber = -1
        return Promise.resolve(
          com.ts.mobile.sdk.ConfirmationInput.create(okResponseNumber),
        )
      }
    }

    TransmitUiHandler.prototype.createFormSession = (
      formId,
      payload: { any },
    ) => {
      // eslint-disable-next-line no-shadow
      function collectBindInfo(formId, payload) {
        this.startSession = function (clientContext, actionContext) {
          this.clientContext = clientContext
        }

        this.endSession = function () {}

        this.promiseFormInput = () => {
          return new Promise((resolve, reject) => {
            const payload1 = {
              type: 'dynamic_form',
              actions: {
                bound: 'false',
              },
            }
            resolve(
              com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
                Object.assign(payload1),
              ),
            )
          })
        }
        // eslint-disable-next-line no-shadow
        this.onContinue = function (payload) {}
        // eslint-disable-next-line no-shadow
        this.onError = function (payload) {}
      }
      return new collectBindInfo(formId, payload)
    }

    const qaUiHandler = new TransmitUiHandler()
    this.commonUiHandler.sdkSetUiHandler(qaUiHandler)
  }
}
