import { NgModule } from '@angular/core'
import {
  TabPanelDirective,
  TabsComponent,
} from '@vg-constellation/angular-15/tabs'
import {
  RadioInputComponent,
  RadioComponent,
  RadioGroupComponent,
} from '@vg-constellation/angular-15/radio'
import { BannerComponent } from '@vg-constellation/angular-15/banner'
import { ButtonComponent } from '@vg-constellation/angular-15/button'
import { LabelComponent } from '@vg-constellation/angular-15/label'
import { HintErrorComponent } from '@vg-constellation/angular-15/hint-error'
import {
  InputInputComponent,
  InputComponent,
} from '@vg-constellation/angular-15/input'
import {
  CheckboxInputComponent,
  CheckboxComponent,
} from '@vg-constellation/angular-15/checkbox'
import { LinkComponent } from '@vg-constellation/angular-15/link'
import { IconComponent } from '@vg-constellation/angular-15/icon'
import {
  SelectInputComponent,
  SelectComponent,
} from '@vg-constellation/angular-15/select'

const c11nComponent = [
  CheckboxInputComponent,
  CheckboxComponent,
  TabPanelDirective,
  TabsComponent,
  IconComponent,
  BannerComponent,
  ButtonComponent,
  LabelComponent,
  HintErrorComponent,
  InputInputComponent,
  InputComponent,
  RadioInputComponent,
  RadioComponent,
  RadioGroupComponent,
  LinkComponent,
  SelectInputComponent,
  SelectComponent,
]

@NgModule({
  declarations: [],
  imports: [c11nComponent],
  exports: [c11nComponent],
})
export class VgConstellationModule {}
