<!-- RESET PASSWORD, REGISTER FOR ACCESS, SECURITY CODE, FORGOT USERNAME EMAIL -->
<div *ngIf="type != 'domesticPhoneNumber' && type != 'phoneNumber' && !(type == 'oobCode' && otpPopUp == 'true') && !enableTrim" class="col-sm-3 col-md-3 col-lg-7 col-xl-9 inline-block width-fit-content">
  <form [formGroup]="form" class="table-display width-fit-content">
    <vui-form-group class="custom-input-box"
      [vui-nested-label]="true"
      [vui-validation-icons]="true">
      <label *ngIf="!enableTrim && type != 'phoneNumber' && !otpPopUp && type != 'domesticPhoneNumber'" id="{{id}}Label"
             class="custom-label vui-input-label" for="{{id}}Input">{{label}}</label>
      <input *ngIf="!enableTrim && type != 'phoneNumber' && !otpPopUp && type != 'domesticPhoneNumber'"
             id="{{id}}Input"
             name="{{id}}Input" 
             vuiInput
             type="{{inputType}}" [validateForm]="form" [typeOfInput]="type" [ctrlName]="id" [(ngModel)]="inputValue"
             formControlName="{{id}}" [disabled]="disabled"
             (input)="onInput($event.target.value)" (blur)="onBlur()"
      />
    </vui-form-group>
  </form>
</div>

<!-- SECURITY Q&A, FORGOT USERNAME NAME FIELDS -->
<div *ngIf="enableTrim" class="col-sm-3 col-md-3 col-lg-7 col-xl-9">
  <form [formGroup]="form">
    <vui-form-group
      [vui-nested-label]="true"
      [vui-validation-icons]="true"
      class="user-enter-input-fields">
      <label *ngIf="enableTrim && type != 'phoneNumber' && !otpPopUp && type != 'domesticPhoneNumber'" id="{{id}}Label"
             class="custom-label vui-input-label" for="{{id}}Input">{{label}}</label>

      <input *ngIf="enableTrim && type != 'phoneNumber' && !otpPopUp && type != 'domesticPhoneNumber'"
             id="{{id}}Input"
             name="{{id}}Input"
             vuiInput
             type="{{inputType}}" [validateForm]="form" [typeOfInput]="type" [ctrlName]="id"  [(ngModel)]="inputValue"
             formControlName="{{id}}" [disabled]="disabled" trim
             (input)="onInput($event.target.value)" (blur)="onBlur()" autocapitalize="none" aria-describedby="invalidCodeMsg"
      />
    </vui-form-group>
  </form>
</div>

<div *ngIf="type == 'oobCode' && otpPopUp != 'true'" id="{{id}}Text" class="position-absolute validation-code-text col-sm-12 col-md-12 col-lg-10 col-xl-10 width-fit-content">
      <span *ngFor="let item of inputContent; let i = index" [ngSwitch]="i">
          <span id="validationCodeTxt{{i}}" class="baseline font-family-UBR font-size-16" *ngSwitchCase="0">
              {{item}}</span>
          <a [class.disabled]="disableLink" id="validationCodeTxt{{i}}" role="link" tabindex="0"
              class="baseline font-family-UBM link-without-href font-size-16 margin-left-8 underline-border" *ngSwitchCase="1"
              (click)="emitSendCode()" (keyup.enter)="emitSendCode()">{{item}}</a>
      </span>
</div>


<div *ngIf="type != 'oobCode' && type != 'domesticPhoneNumber' && inputContent" id="{{id}}Text" valign="top" class="col-md-7 col-lg-7 col-xl-9 margin-top-0" >
  <div id="{{id}}TextContent" class="text-align-left  margin-bottom-0-important baseline font-family-UBR font-size-14 margin-right-8 margin-mobile-text mobile-margin-top-24" style="width: 100%;">
      <div *ngFor="let item of inputContent" class="margin-right-5" [ngClass]="inputContent[0].length>100 ? 'top-50' : 'top-40'">
        {{item}}
      </div>
  </div>
</div>

<div *ngIf="type != 'oobCode' && type != 'phoneNumber' && type != 'domesticPhoneNumber'" [hidden]="true" id="{{id}}Error" class="custom-error" [ngStyle]="setStyleErrorMsg()"></div>

<tr class="width-100 display-flex"  *ngIf="type === 'phoneNumber' || type === 'domesticPhoneNumber' || type == 'oobCode'" [ngClass]="{'responsive-display': otpPopUp}">

    <td *ngIf="!otpPopUp" class="baseline" [ngStyle]="parentStyle()">

      <!-- FORCED OOB ENROLL -->
        <form *ngIf="type === 'phoneNumber'" [formGroup]="form">
          <vui-form-group
            [vui-nested-label]="true"
            class="padding-bottom-5"
            [ngStyle]="setStyle()">
            <label for="{{id}}Input" class="vui-input-label" id="{{id}}Label" class="vui-input-label">{{label}}</label>
            <input #countryCodeInputElement vuiInput vuiTel type="tel" [ctrlName]="id" id="{{id}}Input" name="{{id}}Input" required formControlName="{{id}}"
                   [validateForm]="form" [phoneFormatter]="formControl" [(ngModel)]="inputValue" name="telephone"
                   (input)="onInput($event.target.value)" (blur)="onBlur()"
                   [disabled]="disabled" [maxlength]=charLimit [countryPhoneCode]="this.countryPhoneCode"
                   class="phoneNumber-input-dom"
                   onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"/>
          </vui-form-group>
        </form>

      <!-- DOMESTIC OOB ENROLL -->
      <form *ngIf="type === 'domesticPhoneNumber'" [formGroup]="form">
        <vui-form-group
          [vui-nested-label]="true"
          [vui-validation-icons]="true"
          class="padding-bottom-5 mobile-width-100"
          [ngStyle]="setStyle()">
            <label *ngIf="!enableTrim && type === 'domesticPhoneNumber'" id="{{id}}Label" for="{{id}}Input"
            class="phoneNumber-label vui-input-label">{{label}}</label>
            <input *ngIf="!enableTrim && type === 'domesticPhoneNumber'" vuiInput vuiTel type="tel" [validateForm]="form"
                   [countryPhoneCode]="this.countryPhoneCode" [phoneFormatter]="formControl"
                   [ctrlName]="id" id="{{id}}Input" name="{{id}}Input" [(ngModel)]="inputValue" [ngModelOptions]="{standalone: true}"
                   (input)="onInput($event.target.value)" (blur)="onBlur()" [disabled]="disabled"
                   maxlength="10" cba
                   onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                   class="phoneNumber-input-dom"/>
        </vui-form-group>
      </form>
      <div class="ci-position-relative">
        <div [hidden]="true" id="{{id}}Error" class="custom-error ci-position-absolute ci-mobile-top-negative" [ngStyle]="setStyle()"></div>
      </div>
    </td>
  <!-- FORCED OOB ENROLL OTP POPUP -->
    <td *ngIf="type == 'oobCode' && otpPopUp == 'true'" class="otp-custom-input">
      <form [formGroup]="form">
        <vui-form-group
          [vui-nested-label]="true"
          [vui-validation-icons]="true" class="otp-input-fields">
          <label *ngIf="type == 'oobCode' && otpPopUp == 'true'" id="{{id}}Label"
                 class="custom-label vui-input-label" for="{{id}}Input">{{label}}</label>
          <input *ngIf="type == 'oobCode' && otpPopUp == 'true'"
                 id="{{id}}Input"
                 name="{{id}}Input"
                 vuiInput
                 type="{{inputType}}" [validateForm]="form" [typeOfInput]="type" [ctrlName]="id" [(ngModel)]="inputValue"
                 formControlName="{{id}}" [disabled]="disabled"
                 (input)="onInput($event.target.value)" (blur)="onBlur()"/>
        </vui-form-group>
      </form>
      <div [hidden]="true" id="{{id}}Error" class="custom-error width-130-px"></div>
      <div  id="{{id}}Text" class="validation-code-text margin-top-42 font-size-16 margin-left-0">
            <span *ngFor="let item of inputContent; let i = index" [ngSwitch]="i">
                <span id="validationCodeTxt{{i}}" class="baseline font-family-UBR font-size-16" *ngSwitchCase="0"> {{item}}</span>
                <a [class.disabled]="disableLink" id="validationCodeTxt{{i}}" role="link" tabindex="0" class="baseline font-family-UBM link-without-href font-size-16 margin-left-8 underline-border" *ngSwitchCase="1"
                   (click)="emitSendCode()" (keyup.enter)="emitSendCode()">{{item}}</a>
            </span>
      </div>
    </td>
</tr>

