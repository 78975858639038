<span id="termsContent"
      class="font-size-16">
  <a id="termsLink" role="link" target="_blank" (click)="termsAndConditionModalDialog.openModalDialog()" (keydown.enter)="termsAndConditionModalDialog.openModalDialog()"
     class="c11n-link-text" tabindex=0>
        {{content.Data.registrationPage.content.acceptTermsAndCondition.acceptTermsAndCondition.termsConditionLink}}
    </a>
</span>

<div>
  <c11n-modal-dialog
    modalid="termsAndConditionModalDialog" #termsAndConditionModalDialog
    [headingText]="tcContent.headerText"
    [maxWidth]="660"
    [primaryButtonLabel]="content.Data.cancelLayer.content.closeBtn"
    (primaryClick)="termsAndConditionModalDialog.closeDialogModal($event)"
    (closeEvent)="termsAndConditionModalDialog.closeDialogModal($event)"
    [variant]="'basic'">
    <div c11n-modal-dialog-body>
      <ng-container *ngTemplateOutlet="termsAndConditionDialog"></ng-container>
    </div>
  </c11n-modal-dialog>
</div>

<ng-template #termsAndConditionDialog>
  <!-- <h1 id="termsHeader" class="vui-dialog-title bold-font">{{tcContent.headerText}}</h1> -->
  <div id="termsAndConditions" class="text-align-left">
    <div class="vui-dialog-body-content">
      <p class="dialog-body c11n-label-font-regular">{{tcContent.introParagraphsContent1}}</p><br>
      <p class="dialog-body c11n-label-font-regular">{{tcContent.introParagraphsContent2}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.useOfSiteHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.useOfSiteContent1}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.useOfSiteContent2}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.useOfSiteContent3}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.useOfSiteContent4}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.useOfSiteContent5}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.useOfSiteContent6}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.userContentHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.userContentText1}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.userContentText2}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.userContentText3}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.yourAccountHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.yourAccountContent1}}</p><br>
      <p>
        <span class="c11n-label-font-regular">{{tcContent.yourAccountContent2a}}</span>&nbsp;
        <span class="c11n-label-font-bold">{{tcContent.yourAccountContent2b}}</span>
      </p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.dataInfoAndcontentHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.dataInfoAndContentText}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.copyRightNoticeAndClaimHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.copyRightNoticeAndClaimContent1}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.copyRightNoticeAndClaimContent2}}</p><br>
      <p>
        <span class="c11n-label-font-regular">{{tcContent.copyRightNoticeAndClaimContent3a}}</span>&nbsp;
        <a class="border-bottom" href="mailto:{{tcContent.copyRightNoticeAndClaimContent3b}}">{{tcContent.copyRightNoticeAndClaimContent3b}}</a>
        <span class="c11n-label-font-regular">{{tcContent.copyRightNoticeAndClaimContent3c}}</span>
      </p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.thirdPartyHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.thirdPartyContent}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.timelinessOfContentHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.timelinessOfContent}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.warrantyDisclaimersHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.warrantyDisclaimersContent}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.liabilityLimitationHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.liabilityLimitationContent1}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.liabilityLimitationContent2}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.liabilityLimitationContent3}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.severabilityHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.severabilityContent}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.applicableLawAndVenueHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.applicableLawAndVenueContent}}</p><br>

      <h2 class="c11n-label-font-bold">{{tcContent.tradeMarksAndPatentsHeader}}</h2>
      <p class="c11n-label-font-regular">{{tcContent.tradeMarksAndPatentsContent1}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.tradeMarksAndPatentsContent2}}</p><br>
      <p class="c11n-label-font-regular">{{tcContent.tradeMarksAndPatentsContent3}}</p>

    </div>
  </div>
</ng-template>