import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Location } from '@angular/common'
import { Observable } from 'rxjs'
import { TogglesResponse } from '@app/models/TogglesResponse'
import { environment as env } from '@env'

@Injectable()
export class GatekeeperService {
  constructor(private httpClient: HttpClient, private location: Location) {}

  legacyLogon = this.isPrettyUrl()

  getToggle(featureId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(
      this.location.prepareExternalUrl(
        this.legacyLogon ? env.LEGACYLOGON + env.GATEKEEPER : env.GATEKEEPER,
      ),
      { params: { featureId: featureId } },
    )
  }

  getToggles(featureIdsArray: string[]): Observable<TogglesResponse[]> {
    const featureIds: string = featureIdsArray.join(',')
    return this.httpClient.get<TogglesResponse[]>(
      this.location.prepareExternalUrl(
        this.legacyLogon
          ? env.LEGACYLOGON + env.GATEKEEPER_MULTI
          : env.GATEKEEPER_MULTI,
      ),
      { params: { featureIds: featureIds } },
    )
  }

  isPrettyUrl(): boolean {
    return window.location.origin.includes('institutional.vanguard.com')
  }
}
